import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../models/app-config';
import {UrlFromProperties} from '../models/url-properties';
import {UserInfo} from '..';
import {Observable, Subject} from "rxjs";
import {DocumentBO} from "../models/document.model";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  static appConfig: AppConfig;
  static urlProperties: UrlFromProperties;
  static checkAllPage: boolean;
  static userInfo: UserInfo;

  constructor(private http: HttpClient) {
  }

  private subjectName = new Subject<any>(); //need to create a subject

  sendUpdateDocument(documents: DocumentBO[]) {
    this.subjectName.next(documents); //next() will feed the value in Subject
  }

  getUpdate(): Observable<DocumentBO[]> { //the receiver component calls this function
    return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }


}

