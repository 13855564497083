import {Injectable} from '@angular/core';
import {AbstractBusinessService} from "./abstract-business.service";
import {CommonService} from "./common.service";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {UserBO} from "../models/userbo.model";
import { UserDTO } from '../models/user-dto.model';
import {RoleDTO} from "../models/role-dto.model";

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractBusinessService<UserDTO> {

  entityIdParamName: string;

  resourceUrl = CommonService.appConfig.backendUrl + 'user';

  constructor(public httpClient:HttpClient) {
    super(httpClient)
  }

  getUserBO():  Observable<HttpResponse<UserBO>> {
    return this.httpClient.get<UserBO>(`${this.resourceUrl}/getUserBO`,
            {observe: 'response'});
  }
  getAllAgentDO215():  Observable<HttpResponse<UserDTO[]>> {
    return this.httpClient.get<UserDTO[]>(`${this.resourceUrl}/getAllAgentDO215`,
      {observe: 'response'});
  }

  getAll():  Observable<HttpResponse<UserDTO[]>> {
    return this.httpClient.get<UserDTO[]>(`${this.resourceUrl}/getAll`,
      {observe: 'response'});
  }

  getAllRoles():  Observable<HttpResponse<RoleDTO[]>> {
    return this.httpClient.get<RoleDTO[]>(`${this.resourceUrl}/all-client-roles`,
      {observe: 'response'});
  }


  getUserByUsername(username: string):  Observable<HttpResponse<UserDTO>> {
    return this.httpClient.get<UserDTO>(`${this.resourceUrl}/username/`+username,
      {observe: 'response'});
  }

  updateUserDTO(userID: string, userDTO: UserDTO): Observable<HttpResponse<UserDTO>> {
    return this.http.put<UserDTO>(`${this.resourceUrl}` + '/edit/' + userID, userDTO, {observe: 'response'});
    //return this.http.put<UserDTO>(`${this.resourceUrl}` + '/edit/' + userID, {observe: 'response', params: userDTO});
  }

  saveUserDTO(userDTO: UserDTO): Observable<HttpResponse<UserDTO>> {
    return this.httpClient.post<UserDTO>(`${this.resourceUrl}/create`, userDTO,
      {observe: 'response'});
  }

}
