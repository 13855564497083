import { Injectable } from '@angular/core';
import {AbstractBusinessService} from "./abstract-business.service";
import {CommonService} from "./common.service";
import {HttpClient, HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

import {Commentaire} from "../models/commentaire.model";
import {
  GetLastComAuditRequest,
  GetLastComConstRequest,
  GetLastComSiteRequest
} from "../../modules/constructeurs/model/get.last.commentaire.request";
import {GetCommentairesConstrType} from "../../modules/administration/model/get.commentaire.constr.type.request";

@Injectable({
  providedIn: 'root'
})
export class CommentaireService extends AbstractBusinessService<Commentaire> {

  entityIdParamName: string;

  resourceUrl = CommonService.appConfig.backendUrl + 'commentaire';

  constructor(public httpClient:HttpClient) {
    super(httpClient)
  }

  //
  // search for the comments assigned to the demande
  getCommentairesForDemande(demandeid: number): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.get<Commentaire[]>(`${this.resourceUrl}/getCommentairesForDemande/`+demandeid,
     {observe: 'response'});
  }
  //
  // search for the comments assigned to the constructeur
  getCommentairesForConstructeur(constructeurid: number): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.get<Commentaire[]>(`${this.resourceUrl}/getCommentairesForConstructeur/`+constructeurid,
     {observe: 'response'});
  }

  getForConstructeurByType(request: GetCommentairesConstrType): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.post<Commentaire[]>(`${this.resourceUrl}/getForConstructeurByType`, request,
      {observe: 'response'});
  }


  getForServiceTechniqueByType(request: GetCommentairesConstrType): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.post<Commentaire[]>(`${this.resourceUrl}/getForSTByType`, request,
      {observe: 'response'});
  }



  //
  // search for the comments assigned to the service technique
  getCommentairesForServiceTechnique(servicetechniqueid: number): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.get<Commentaire[]>(`${this.resourceUrl}/getCommentairesForServiceTechnique/`+servicetechniqueid,
     {observe: 'response'});
  }

  getCommentairesForServiceTechniqueAll(servicetechniqueid: number): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.get<Commentaire[]>(`${this.resourceUrl}/getForSTAll/`+servicetechniqueid,
      {observe: 'response'});
  }

  getCommentairesForConstructeurAll(constructeurid: number): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.get<Commentaire[]>(`${this.resourceUrl}/getForConstructeurAll/`+constructeurid,
      {observe: 'response'});
  }

  // Sauver le commentaire
  sauverCommentaire(commentaireBO: Commentaire): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.post<Commentaire[]>(`${this.resourceUrl}/saveCommentaire`, commentaireBO,
      {observe: 'response'});
  }
  // Supprimer le commentaire
  removeCommentaire(commentaireBO: Commentaire): Observable<HttpResponse<Commentaire[]>> {
    return this.httpClient.post<Commentaire[]>(`${this.resourceUrl}/removeCommentaire`, commentaireBO,
      {observe: 'response'});
  }
  // Sauver le commentaire de refus lors de la signature du certificat
  // Si le commentaire sauvé répond à un refus de signer, il faut clôturer la notification,
  // remettre le dossier "en traitement"
  // créer une notification "Dossier à analyser"
  // TODO - à revoir lorsque l'on traitera la notification "à signer" - on ne passera peut être pas par ici
  sauverCommentaireRefus(commentaireBO: Commentaire): Observable<HttpResponse<Commentaire[]>> {
   return this.httpClient.post<Commentaire[]>(`${this.resourceUrl}/saveCommentaireRefus`, commentaireBO,
     {observe: 'response'});
  }

  getLastForSiteProduction(request: GetLastComSiteRequest): Observable<HttpResponse<Commentaire>> {
    return this.httpClient.post<Commentaire>(`${this.resourceUrl}/getLastForSiteProduction`, request,
      {observe: 'response'});
  }

  getLastForConstructeur(request: GetLastComConstRequest): Observable<HttpResponse<Commentaire>> {
    return this.httpClient.post<Commentaire>(`${this.resourceUrl}/getLastForConstructeur`, request,
      {observe: 'response'});
  }

  getLastForAudit(request: GetLastComAuditRequest): Observable<HttpResponse<Commentaire>> {
    return this.httpClient.post<Commentaire>(`${this.resourceUrl}/getLastForAudit`, request,
      {observe: 'response'});
  }

}
