import {Injectable} from '@angular/core';
import {forkJoin, Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

import {Alert} from './alert.model';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class AlertService {
  private subject = new Subject<Alert>();
  private defaultId = 'alert';

  constructor(private messageService: MessageService, private translateService: TranslateService) {
  }

  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // convenience methods
  success(title = '', message: string, options?: any): void {
    this.displayPrimeNgError('success', title, message);
    // this.alert(new Alert({...options, severity: Severity.SUCCESS, title, message}));
  }

  error(title = '', message: string, options?: any): void {
    this.displayPrimeNgError('error', title, message);
    // this.alert(new Alert({...options, severity: Severity.ERROR, title, message}));
  }

  info(title = '', message: string, options?: any): void {
    this.displayPrimeNgError('info', title, message);
    // this.alert(new Alert({...options, severity: Severity.INFO, title, message}));
  }

  warn(title = '', message: string, options?: any): void {
    this.displayPrimeNgError('warn', title, message);
    // this.alert(new Alert({...options, severity: Severity.WARNING, title, message}));
  }

  displayPrimeNgError(severity: string, title: string, message: string): void {
    forkJoin([
      this.translateService.get(title),
      this.translateService.get(message)
    ]).subscribe(resp => {
      const summary = resp[0];
      const detail = resp[1];
      const life = 10000;
      this.messageService.add({severity, summary, detail, life});
    });
  }

  // main alert method
  alert(alert: Alert): void {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId): void {
    this.subject.next(new Alert({id}));
  }

}
