<header>
  <mat-toolbar class="container">
    <div class="logo">
      <a href="">
        <div class="global-logo">
          <img src="./assets/images/logo/WTASLogoPortal_2022.png">
        </div>
        <div class="app-logo">
          <div>
            <span class="title">{{'app.title' | translate}}</span>
            <span class="version">{{version}}</span>
          </div>
        </div>
      </a>

    </div>
    <div class="header-actions">
<!--      <button pButton pRipple type="button" label="Wallonie.be" icon="pi pi-external-link"-->
<!--              class="p-button-rounded p-button-secondary p-button-link light-button"><a href="www.google.be"></a></button>-->

      <div class="user-menu" *ngIf="authenticated">
        <a mat-icon-button [matMenuTriggerFor]="menu">
          <span>{{shortName}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </a>
        <mat-menu #menu="matMenu" class="user-menu-overlay">
          <button mat-menu-item disabled class="user-fullname">
            <span>{{fullName}}</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{'common.logout'|translate}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
</header>
