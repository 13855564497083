import {DocumentBO} from "../../../core/models/document.model";
import {FacesMessageBO} from "../../../core/models/facesmessage.model";
import {PaiementBO} from "../../demande/model/paiement.model";

export class Notification {
  id?: number;
  type?: string;
  demandeID?: number;
  constructeurID?: number;
  serviceTechniqueID?: number;
  paiementID?: number;
  notificationDate?: Date;
  statut?: number;
  assigneId?: number;
  assigneStrId?: string;
  assigneNom?: string;
  testID?: number;
  anomalie?: string;
  infoCompl?: string;
  typeDemande?: string;
  nomDemandeur?: string;
  numeroDossier?: string;
  vehCatHomologation?: string;
  reference?: string;

  freeText?: string; // used for the textarea in the dossierIncompletDlg, demandeTestDlg, raisonRefusSignatureDlg
  sentEmails?: string[]; // used by the multiselect in the dossierIncompletDlg: selectedEmails
  sujet?: string;    // used for the input text in the demandeTestDlg
  selectedServiceTechniqueId?: number;  // id du service technique sélectionné pour effectuer le test, used in the demandeTestDlg
  prive?: boolean;  // used for the flag prive in the raisonRefusSignatureDlg

  decision?: string;  // contient "OK" ou "NOK" dans le cas d'un certificat à valider, ou un certificat à signer
  documents?: DocumentBO[]; // contients la liste des documents de la demande, utile pour connaitre les docToMerge settés lors du traitement de la notificaiton "Certificat à valider"
                            // ou pour connaitre la liste des documents composant le certificat à signer

  etaesListCertificat?: DocumentBO[]; // contient la liste des documents à merger pour envoyer le CERT_*.pdf à ETAES
  etaesListRapport?: DocumentBO[];    // contient la liste des documents à merger pour envoyer le TR_*.pdf à ETAES
  etaesListFiche?: DocumentBO[];      // contient la liste des documents à envoyer sous le nom IF*_*.pdf à ETAES
  etaesListAutre?: DocumentBO[];      // contient la liste des documents à envoyer sous le nom OTHER*_*.pdf à ETAES

  facesMessageBO?: FacesMessageBO;
  traiteePar?: string;

  /**
   * juste pour l'ecran gestion notif paiement
   */

  paiement?: PaiementBO;

}

