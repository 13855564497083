import {Pipe, PipeTransform} from '@angular/core';

/*
 * Format a comunication number for display
 * For the format is: XXXX/XXXXX/XXX
 * Usage:
 *   value | communication
*/
@Pipe({name: 'communication'})
export class CommunicationPipe implements PipeTransform {
  readonly onlyNumbersRegex = /^\d+$/;

  transform(value: string): string {
    if (this.isValidCommunication(value)) {
      value = `${value.substring(0, 4)}/${value.substring(4, 9)}/${value.substring(9, 12)}`;
    }
    return value;
  }

  isValidCommunication(value: string): boolean {
    return value != null && value.length === 12
      && value.match(this.onlyNumbersRegex).index > -1;
  }


}
