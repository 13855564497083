import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {PermissionGuard} from '../models/permission-guard';
import {PermissionService} from '../auth/permission.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {CommonService} from '..';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

  constructor(public router: Router,
              private permissionService: PermissionService,
              private oAuthService: OAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkLogin(state.url);
  }

  /**
   * Checks if a user is logged in before activating the secured page.
   * @ param url
   */
  checkLogin(url: string) {
    if (this.oAuthService.hasValidAccessToken() ||
      CommonService.appConfig.disableSecurity) {
      return true;
    } else {
      this.oAuthService.logOut(true);
      this.router.navigate(['home'], { queryParams: { returnUrl: url }});
      return false;
    }
  }

  /**
   * Checks if the logged in user have enough privilege to load the page. Group can be specified in the app-routing.module routes.
   * Note that currently keycloak is not sending the list of roles that's why we are using groups.
   * @param route The route
   */
  canLoad(route: Route): boolean {
    // console.log('canLoad: ' + JSON.stringify(route));
    if (!this.oAuthService.hasValidAccessToken()) {
      this.oAuthService.logOut();
      return false;
    }

    const data = route.data.permission as PermissionGuard;
    if (data.permissionScope) {
      const hasDefined = this.permissionService.hasPermission(data.permissionScope);
      if (hasDefined) {
        return true;
      }

      if (data.redirectTo && data.redirectTo !== undefined) {
        this.router.navigate([data.redirectTo]);
      }

      return false;

    } else {
      console.log('unrole');

      if (Array.isArray(data.only) && Array.isArray(data.except)) {
        throw new Error('Can\'t use both \'only\' and \'Except\' in route data.');
      }

      // if (Array.isArray(data.only)) {
      //   const hasDefined = KeycloakService.hasGroups(data.only);
      //   if (hasDefined) {
      //     return true;
      //   }
      //
      //   if (data.redirectTo && data.redirectTo !== undefined) {
      //     this.router.navigate([data.redirectTo]);
      //   }
      //
      //   return false;
      // }
      //
      // if (Array.isArray(data.except)) {
      //   const hasDefined = KeycloakService.hasGroups(data.except);
      //   if (!hasDefined) {
      //     return true;
      //   }
      //
      //   if (data.redirectTo && data.redirectTo !== undefined) {
      //     this.router.navigate([data.redirectTo]);
      //   }
      //
      //   return false;
      // }

      return true;

    }
  }

}
