import { Injectable } from '@angular/core';
import {AbstractBusinessService, CommonService} from "../../../core";
import {HttpClient, HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

import {Demande} from "../model/demande.model";
import {DemandeSimplifiee} from "../model/demandesimplifiee.model";
import {Demandeur} from "../model/demandeur.model";
import {Notification} from "../../notification/model/notification.model";
import {TestBO} from "../model/test.model";
import {DirectiveBO} from "../model/directive.model";
import {AmendementBO} from "../model/amendement.model";
import {AmendementComplBO} from "../model/amendementcompl.model";
import {PartieBO} from "../model/partie.model";
import {NoteDeCreditBO} from "../model/notedecredit.model";
import {PaiementBO} from "../model/paiement.model";
import {ConstructeurClasseNameBO} from "../model/constructeurclassename.model";

import {SearchDemandeurRequest} from "../wsclient/searchdemandeur.request";
import {SearchDemandeRequest} from "../wsclient/searchdemande.request";
import {LoadDirectivesRequest} from "../wsclient/loaddirectives.request";
import {GetVehiculeCategorieForActeCadreRequest} from "../wsclient/getvehiculecategorieforactecadre.request";
import {GetAmendementPartieComplementsForGroupRequest} from "../wsclient/getamendementpartiecomplementsforgroup.request";
import {GetAmendementPartieComplementsForGroupResponse} from "../wsclient/getamendementpartiecomplementsforgroup.response";
import {GetActesCadresForTypeDemandeAndDemandeurRequest} from "../wsclient/getactescadresfortypedemandeanddemandeur.request";
import {CtrlExistenceNumeroReceptionOriginalRequest} from "../../numerotation/wsclient/ctrlexistencenumeroreceptionoriginal.request";
import {GetHistoriqueRefusSignatureResponse} from "../wsclient/gethistoriquerefussignature.response";

import {TableReferenceBO} from "../../../core/models/table_reference.model";
import {UserDTO} from "../../../core/models/user-dto.model";
import {ServiceTechniqueBO} from "../../../core/models/servicetechnique.model";
import {ConstructeurBO} from "../../../core/models/constructeurs.model";
import {GroupLegislation} from "../model/tyXXXType.model";
import {TypePrestationBO} from "../model/typeprestation.model";
import {PrestationBO} from "../model/prestation.model";
import {FactureIAPBO} from "../model/factureiap.model";
import {COPBO} from "../../../core/models/copBO.model";
import { UserBO } from 'src/app/core/models/userbo.model';
import {AuditBO} from "../../../core/models/auditBO.model";
import {GestionWarningStatut} from "../model/gestion_warning_statut.model";

import {InitialVehicleInformation} from "../model/initial_vehicle_information.model";
import {CompteurBO} from "../../administration/model/compteur.model";
import {WtasEtaesStatutBO} from "../model/etaesstatus.model";

@Injectable({
  providedIn: 'root'
})
export class DemandeService extends AbstractBusinessService<Demande> {

  entityIdParamName: string;
  loadDirectivesRequest: LoadDirectivesRequest;

  resourceUrl = CommonService.appConfig.backendUrl + 'demande';
  resourceUrlAdmin = CommonService.appConfig.backendUrl + 'constructeur';
  resourceUrlTableReference = CommonService.appConfig.backendUrl + 'tablereference';
  resourceUrlReglement = CommonService.appConfig.backendUrl + "reglement";
  resourceUrlUtilisateur = CommonService.appConfig.backendUrl + "user";
  resourceUrlFacture = CommonService.appConfig.backendUrl + "facture";

  constructor(public httpClient:HttpClient) {
    super(httpClient)
  }

  // si c'est une nouvelle demande (pas une chargée de la DB)
  // il faut initialiser la demande avec les informations :
  // - concernant le mandataire (la personne connectée)
  // - si c'est un constructeur et qu'il n'a pas de mandat, le seul demandeur possible est son entreprise
  initDemande(demande : Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/initDemande`, demande,
      {observe: 'response'});
      console.log("INIT DEMANDE", demande);
  }

  // recherche les potientiels demandeurs sur base des critères de recherche et des mandats ou droits
  searchDemandeur(searchDemandeurRequest : SearchDemandeurRequest) : Observable<HttpResponse<Demandeur[]>> {
    return this.httpClient.post<Demandeur[]>(`${this.resourceUrlAdmin}/searchDemandeur`, searchDemandeurRequest,
      {observe: 'response'});
  }

  // Le demandeur est connu.
  // Si le type du demandeur est un Tiers,
  // - soit l'utilisateur avait sélectionné un existant dont il a pu modifier les données
  // - soit l'utilisateur avait explicitement indiqué qu'il allait créer un nouveau
  // dans les 2 cas, il faut sauver les données du Tiers avant de passer à l'écran suivant.
  demandeurSelected(demande: Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/demandeurSelected`, demande,
      {observe: 'response'});
  }

  getDemandeByNumeroReception(ctrlExistenceNumeroReceptionOriginalRequest: CtrlExistenceNumeroReceptionOriginalRequest): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/getDemandeByNumeroReception`, ctrlExistenceNumeroReceptionOriginalRequest,
      {observe: 'response'});
  }

  // charge la liste des législations possibles pour un type de demande en particulier
  loadLegislation(typeDemandeCode: string): Observable<HttpResponse<TableReferenceBO[]>> {
    console.log("into demande.service loadLegislation typeDemandeCode=["+typeDemandeCode+"]");
    return this.httpClient.get<TableReferenceBO[]>(`${this.resourceUrlReglement}/getLegislationsForTypeDemande/`+ typeDemandeCode,
      {observe: 'response'});
  }
  // charge la liste des objets types possibles pour un type de demande en particulier
  loadObjetType(typeDemandeCode: string): Observable<HttpResponse<TableReferenceBO[]>> {
    console.log("into demande.service loadObjetType typeDemandeCode=["+typeDemandeCode+"]");
    return this.httpClient.get<TableReferenceBO[]>(`${this.resourceUrlReglement}/getObjetTypesForTypeDemande/`+ typeDemandeCode,
      {observe: 'response'});
  }
  // charge les actes cadres disponibles pour la legislation et objet type sélectionnés
  loadActeCadre(legislation: string, objetType: string): Observable<HttpResponse<TableReferenceBO[]>> {
    console.log("into demande.service loadActeCadre legislation=["+legislation+"], objetType=["+objetType+"]");
    this.loadDirectivesRequest = new LoadDirectivesRequest();
    this.loadDirectivesRequest.legislation = legislation;
    this.loadDirectivesRequest.categorieObjet = objetType;
    return this.httpClient.post<TableReferenceBO[]>(`${this.resourceUrlReglement}/loadActeCadre`, this.loadDirectivesRequest,
      {observe: 'response'});
  }
  // Recherche la liste des actes cadre dépendant du formulaire et du constructeur demandeur
  getActesCadresForTypeDemandeAndDemandeur(typeDemande: string, demandeur:Demandeur): Observable<HttpResponse<DirectiveBO[]>> {
    var getActesCadresForTypeDemandeAndDemandeurRequest:GetActesCadresForTypeDemandeAndDemandeurRequest = new GetActesCadresForTypeDemandeAndDemandeurRequest();
    getActesCadresForTypeDemandeAndDemandeurRequest.typeDemande = typeDemande;
    getActesCadresForTypeDemandeAndDemandeurRequest.demandeurBO = demandeur;
    return this.httpClient.post<DirectiveBO[]>(`${this.resourceUrlReglement}/getActesCadresForTypeDemandeAndDemandeur`, getActesCadresForTypeDemandeAndDemandeurRequest,
      {observe: 'response'});
  }
  // Recherche la liste des actes cadre pour la legislation et objet type sélectionnés mais dépendant du formulaire et du constructeur demandeur
  loadActeCadreForLegislationTypeDemandeDemandeur(legislation: string, objetType: string, typeDemande: string, demandeur:Demandeur): Observable<HttpResponse<DirectiveBO[]>> {
    var getActesCadresForTypeDemandeAndDemandeurRequest:GetActesCadresForTypeDemandeAndDemandeurRequest = new GetActesCadresForTypeDemandeAndDemandeurRequest();
    getActesCadresForTypeDemandeAndDemandeurRequest.typeDemande = typeDemande;
    getActesCadresForTypeDemandeAndDemandeurRequest.demandeurBO = demandeur;
    getActesCadresForTypeDemandeAndDemandeurRequest.legislation = legislation;
    getActesCadresForTypeDemandeAndDemandeurRequest.objetType = objetType;
    return this.httpClient.post<DirectiveBO[]>(`${this.resourceUrlReglement}/getActesCadresForTypeDemandeAndDemandeur`, getActesCadresForTypeDemandeAndDemandeurRequest,
      {observe: 'response'});
  }
  getDirectiveById(directiveId: number): Observable<HttpResponse<DirectiveBO>> {
    return this.httpClient.get<DirectiveBO>(`${this.resourceUrlReglement}/getDirectiveById`+ '/' + directiveId,
      {observe: 'response'});
  }
  getActeBases(acteCadreId: number, avecPerime: boolean): Observable<HttpResponse<DirectiveBO[]>> {
    console.log("into demande.service getActeBases acteCadreId=["+acteCadreId+"], avecPerime=["+avecPerime+"]");
    return this.httpClient.get<DirectiveBO[]>(`${this.resourceUrlReglement}/getActesBases`+ '/' + acteCadreId + '/' + avecPerime,
      {observe: 'response'});
  }
  getPartielles(acteCadreId: number, avecPMR: boolean): Observable<HttpResponse<DirectiveBO[]>> {
    return this.httpClient.get<DirectiveBO[]>(`${this.resourceUrlReglement}/getPartielles`+ '/' + acteCadreId + '/' + avecPMR,
      {observe: 'response'});
  }
  getAmendements(acteCadreId: number, acteBaseId: number): Observable<HttpResponse<AmendementBO[]>> {
    if (acteBaseId == null) {
      return this.httpClient.get<AmendementBO[]>(`${this.resourceUrlReglement}/getAmendementsForActeCadre`+ '/' + acteCadreId,
                   {observe: 'response'});
    }
    return this.httpClient.get<AmendementBO[]>(`${this.resourceUrlReglement}/getAmendements`+ '/' + acteCadreId + '/' + acteBaseId,
      {observe: 'response'});
  }
  getDirectivePartiesByAmendementId(amendementid: number): Observable<HttpResponse<PartieBO[]>> {
    return this.httpClient.get<PartieBO[]>(`${this.resourceUrlReglement}/getDirectivePartiesByAmendementId`+ '/' + amendementid,
      {observe: 'response'});
  }
  getAmendementComplForAmendement(amendementid: number): Observable<HttpResponse<AmendementComplBO[]>> {
    return this.httpClient.get<AmendementComplBO[]>(`${this.resourceUrlReglement}/getAmendementComplForAmendement`+ '/' + amendementid,
      {observe: 'response'});
  }
  getAmendementById(id: number): Observable<HttpResponse<AmendementBO>> {
    return this.httpClient.get<AmendementBO>(`${this.resourceUrlReglement}/getAmendementById`+ '/' + id,
      {observe: 'response'});
  }
  getAmendementComplById(id: number): Observable<HttpResponse<AmendementComplBO>> {
    return this.httpClient.get<AmendementComplBO>(`${this.resourceUrlReglement}/getAmendementComplById`+ '/' + id,
      {observe: 'response'});
  }
  getDirectivePartieById(partieid: number): Observable<HttpResponse<PartieBO>> {
    return this.httpClient.get<PartieBO>(`${this.resourceUrlReglement}/getDirectivePartieById`+ '/' + partieid,
      {observe: 'response'});
  }
  getVehiculeCategorieForActeCadre(legislation: string, categorieObjet: string, acteCadre:number, constructeurId: number): Observable<HttpResponse<TableReferenceBO[]>> {
    var getVehiculeCategorieForActeCadreRequest: GetVehiculeCategorieForActeCadreRequest = new GetVehiculeCategorieForActeCadreRequest();
    getVehiculeCategorieForActeCadreRequest.legislation = legislation;
    getVehiculeCategorieForActeCadreRequest.categorieObjet = categorieObjet;
    getVehiculeCategorieForActeCadreRequest.acteCadreId = acteCadre;
    getVehiculeCategorieForActeCadreRequest.constructeurId = constructeurId;
    return this.httpClient.post<TableReferenceBO[]>(`${this.resourceUrlReglement}/getVehiculeCategorieForActeCadre`, getVehiculeCategorieForActeCadreRequest,
      {observe: 'response'});
  }
  getVehiculeSubCategorie(vehiculeCategorie: string): Observable<HttpResponse<TableReferenceBO[]>> {
    return this.httpClient.get<TableReferenceBO[]>(`${this.resourceUrlReglement}/getVehiculeSubCategorie/`+vehiculeCategorie,
      {observe: 'response'});
  }

  // Sauve la demande
  save(demande: Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/save`, demande,
      {observe: 'response'});
      console.log("SAVE DEMANDE", demande);
  }
  // Soumettre la demande
  soumettre(demande: Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/soumettre`, demande,
      {observe: 'response'});
  }
  // Annuler la création d'une nouvelle demande
  annulerNouvelleDemande(demande: Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/annulerNouvelleDemande`, demande,
      {observe: 'response'});
  }
  // Duplicate la demande
  duplicate(demande: Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/duplicate`, demande,
      {observe: 'response'});
  }
  quitterDemande(demande: Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.get<Demande>(`${this.resourceUrl}/quitterDemande/`+demande.id,
      {observe: 'response'});
  }

  // L'utilisateur a cliqué sur "Anomalie corrigée"
  anomalieCorrigee(notificationBO: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationAnomalieCorrigee`, notificationBO,
      {observe: 'response'});
  }

  // L'utilisateur a cliqué sur "Retour au statut en traitement" présent dans l'onglet Historique
  retourStatutAAnalyser(demandeBO: Demande): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/retourStatutAAnalyser`, demandeBO,
      {observe: 'response'});
  }

  // Appel le backend pour obtenir la liste des agents ayant un des roles fournis en argument
  getListAgents(roles: string[]): Observable<HttpResponse<UserDTO[]>> {
    console.log("into demande.service getListAgents roles="+roles);
    return this.httpClient.get<UserDTO[]>(`${this.resourceUrlUtilisateur}/getAllByRole/`+roles,
      {observe: 'response'});
  }

  // La demande est assignée à un agent en réponse à la notification "nouvelle demande à assigner"
  assignerDemande(notificationBO: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationAssignerDemande`, notificationBO,
      {observe: 'response'});
  }

  // le dossier est complet administrativement (en réponse à la notification "valider le dossier administratif")
  dossierCompletAdministratif(notificationBO: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationDossierCompletAdministratif`, notificationBO,
      {observe: 'response'});
  }
  // L'utilisateur confirme que le dossier est incomplet administrativement et fourni le
  // message et les adresses email où envoyer la demande d'information complémentaire
  dossierIncompletAdministratif(notificationBO: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationDossierIncompletAdministratif`, notificationBO,
      {observe: 'response'});
  }
  // L'utilisateur confirme que les informations complémentaires ont été ajoutées
  confirmerAjoutInfo(notificationBO: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationConfirmerAjoutInfo`, notificationBO,
      {observe: 'response'});
  }
  // La demande est assignée à un agent en réponse à la notification "demande à assigner pour analyse"
  assignerDemandePourAnalyse(notificationBO: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationAssignerDemandePourAnalyse`, notificationBO,
      {observe: 'response'});
  }
  // load la liste des services techniques disponibles
  getServicesTechniques(demandeBO: Demande): Observable<HttpResponse<TableReferenceBO[]>> {
    return this.httpClient.post<TableReferenceBO[]>(`${this.resourceUrl}/getServicesTechniques`, demandeBO,
      {observe: 'response'});
  }
  // L'utilisateur, lors de l'analyse d'un dossier, demande un test ou essai à un service technique
  demandeTestEssai(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/demandeTestEssai`, notification,
        {observe: 'response'});
  }
  loadTest(id: number): Observable<HttpResponse<TestBO>> {
    return this.httpClient.get<TestBO>(`${this.resourceUrl}/loadTest/` + id,
      { observe: 'response' });
  }

  // L'utilisateur, lors de l'analyse d'un TY014-15, demande un audit auprès d'un service technique ou d'un agent
  demandeDebuterAudit(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/demandeDebuterAudit`, notification,
        {observe: 'response'});
  }
  // Dans l'onglet Test d'une demande, l'utilisateur signale qu'il débute un test / ou essai
  debuterTest(test: TestBO): Observable<HttpResponse<TestBO>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/debuterTest`, test,
       {observe: 'response'});
  }
  // Dans l'onglet Test d'une demande, l'utilisateur confirme qu'il a déposé le rapport de test
  confirmerAjoutRapportTest(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/confirmerAjoutRapportTest`, notification,
       {observe: 'response'});
  }
  // L'utilisateur, lors de l'exécution d'un audit pour l'analyse d'un TY014-15, confirme le dépot du rapport d'audit
  confirmerAjoutRapportAuditAnalyse(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/confirmerAjoutRapportAuditAnalyse`, notification,
      {observe: 'response'});
  }
  // L'utilisateur confirme que le dossier est valider après l'analyse technique
  dossierValider(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationAnalyserUneDemande`, notification,
       {observe: 'response'});
  }
  // L'utilisateur déclare si le certificat est valide ou non (traitement de la notification "certificat à valider"). La décision se trouve dans le champ "decision" de la notification.
  certificatAValider(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationCertificatAValider`, notification,
        {observe: 'response'});
  }
  // L'utilisateur déclare ou non que le dossier peut aller à la signature (traitement de la notification "confirmer que le dossier dont le chassis est en black list peut être envoyé à la signature").
  // La décision se trouve dans le champ "decision" de la notification.
  certificatAValiderBlackListVin(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationCertificatAValiderBlackListVin`, notification,
        {observe: 'response'});
  }
  // L'utilisateur confirme qu'il a ajouté le projet de certificat (traitement de la notification "Ajouter un projet de certificat")
  confirmerAjoutCertificat(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationAjouterProjetCertificat`, notification,
        {observe: 'response'});
  }
  // Traitement de la notification "Signer le certificat". Decision contient "OK" ou "NOK".
  // La décision, le commentaire du refus ou la liste des documents à signer se trouvent dans la notification.
  signerCertificat(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationSignerCertificat`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Imprimer et envoyer le certificat"
  confirmerEnvoiCertificatPapier(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationConfirmerEnvoiCertificatPapier`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Cloturer la demande"
  closeDemande(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationCloseDemande`, notification,
      {observe: 'response'});
  }
  // Traitement des notifications "IAP à compléter" et "Facture à compléter"
  confirmerFactureIAP(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient .post<Notification>(`${this.resourceUrl}/notificationFactureIAPACompleter`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Montant à rembourser suite à l'établissement d'une note de crédit"
  confirmerEnvoiNoteDeCredit(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationRembourserNoteDeCredit`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Envoyer à ETAES".
  // L'utilisateur a formé les 4 fichiers destinés à ETEAS et envoie le tout à la signature
  envoyerEtaes(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationEnvoyerEtaes`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Déposer manuellement les fcihiers ETAES sur le palteforme européenne"
  confirmerEnvoiManuelEtaes(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationConfirmerEnvoiManuelEtaes`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Dossier iFast à contrôler". La décision "OK", "NOK" est dans la décision de la notification
  validerIFast(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationDossierIFastAControler`, notification,
      {observe: 'response'});
  }
  // Traitement des notifications "Dossier trop longtemps dans le même statut"
  confirmerTropLongtempsMemeStatut(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationTropLongtempsMemeStatut`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Certificat disponible"
  confirmerCertificatDisponible(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationCertificatDisponible`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Projet de certificat à Valider (iFast)". Decision contient "OK" ou "NOK"
  validerProjetCertificat(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationValiderProjetCertificat`, notification,
      {observe: 'response'});
  }
  // Traitement de la notification "Mail attaché à la demande"
  confirmerLectureMail(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationConfirmerLectureMail`, notification,
      {observe: 'response'});
  }

	/**
	 * Recherche les adresses emails disponibles
	 *
	 * Si le demandeur est un constructeur, afficher toutes les adresses emails des collaborateurs
	 * sinon afficher l'adresse email du demandeur.
	 * (CGT 24/10/2018) Ajouter les adresses du mandataire afin de pouvoir envoyer la demande
	 * d'information au demandeur ou à son mandataire
	 */
  getAvailableEmails(demande: Demande): Observable<HttpResponse<TableReferenceBO[]>> {
    return this.httpClient.post<TableReferenceBO[]>(`${this.resourceUrl}/getAvailableEmails`, demande,
      {observe: 'response'});
  }

  /**
   * Recherche la liste des dossiers de l'utilisateur connecté "Mes Dossiers"
   */
  getListeDemandesSimplifiees(searchDemandeRequest: SearchDemandeRequest): Observable<HttpResponse<DemandeSimplifiee[]>> {
    return this.httpClient.post<DemandeSimplifiee[]>(`${this.resourceUrl}/getListeDemandesSimplifiees`, searchDemandeRequest,
      {observe: 'response'});
  }
  /**
   * L'utilisateur a sélectionné un dossier dans une liste ("Mes dossiers" ou "Recherche d'un dossier")
   * La réponse est une DemandeBO dont le facesmessage contiendra soit
   * - SUCCESS et la demande
   * - WARN et le nom de la personne qui lock la demande
   * - ERROR et le code d'erreur
   */
  selectDossier(demandeSimplifiee: DemandeSimplifiee): Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/selectDossier`, demandeSimplifiee,
      {observe: 'response'});
  }

  /**
   * Verifie s'il est possible de faire une demande groupée pour la législation, le type d'objet,
   * et l'acte cadre sélectionné
   */
  isDemandeGroupeeAutorisee(legislation: string, objetType: string, acteCadreId: number): Observable<HttpResponse<boolean>> {
    return this.httpClient.get<boolean>(`${this.resourceUrlReglement}/isDemandeGroupeeAutorisee`+"/"+legislation+"/"+objetType+"/"+acteCadreId,
      {observe: 'response'});
  }
  /**
   * Recherche tous les amendements, directives parties, et complements pour la liste des groupLegislation fournis
   */
  getAmendementPartieComplementsForGroup(acteCadreId: number, groupLegislation: GroupLegislation[]): Observable<HttpResponse<GetAmendementPartieComplementsForGroupResponse>> {
    var getAmendementPartieComplementsForGroupRequest:GetAmendementPartieComplementsForGroupRequest = new GetAmendementPartieComplementsForGroupRequest();
    getAmendementPartieComplementsForGroupRequest.acteCadreId = acteCadreId;
    getAmendementPartieComplementsForGroupRequest.groupLegislation = groupLegislation;
    return this.httpClient.post<GetAmendementPartieComplementsForGroupResponse>(`${this.resourceUrlReglement}/getAmendementPartieComplementsForGroup`, getAmendementPartieComplementsForGroupRequest,
      {observe: 'response'});
  }

  getTypePrestations(): Observable<HttpResponse<TypePrestationBO[]>> {
    return this.httpClient.get<TypePrestationBO[]>(`${this.resourceUrlFacture}/getTypePrestations`,
      {observe: 'response'});
  }
  sauverPrestation(prestationBO: PrestationBO): Observable<HttpResponse<FactureIAPBO>> {
    return this.httpClient.post<FactureIAPBO>(`${this.resourceUrlFacture}/sauverPrestation`, prestationBO,
      {observe: 'response'});
  }
  getHistoriqueRefusSignature(demandeId: number): Observable<HttpResponse<GetHistoriqueRefusSignatureResponse>> {
    return this.httpClient.get<GetHistoriqueRefusSignatureResponse>(`${this.resourceUrl}/getHistoriqueRefusSignature/` + demandeId,
      {observe: 'response'});
  }
  getFactureIAP(factureiapid:number): Observable<HttpResponse<FactureIAPBO>> {
    return this.httpClient.get<FactureIAPBO>(`${this.resourceUrlFacture}/getFactureIAP/` + factureiapid,
      {observe: 'response'});
  }
  // recherche le scope à appliquer pour cette demande. Soit celui du demandeur si celui-ci est un constructeur, soit celui du service technique si le demandeur est un tiers et que
  // l'utilisateur connecté est un collaborateur de service technique
  getScopeToApply(demande: Demande): Observable<HttpResponse<COPBO[]>> {
    return this.httpClient.post<COPBO[]>(`${this.resourceUrl}/getScopeToApply`, demande,
      {observe: 'response'});
  }
  // Sauver une note de crédit
  sauverNoteDeCredit(noteDeCreditBO: NoteDeCreditBO): Observable<HttpResponse<FactureIAPBO>> {
    return this.httpClient.post<FactureIAPBO>(`${this.resourceUrlFacture}/sauverNoteDeCredit`, noteDeCreditBO,
      {observe: 'response'});
  }
  // Retourne la liste des paiements ou notes de crédit attachés à une facture / IAP
  getPaiementsByFactureIAPId(factureIAPId: number): Observable<HttpResponse<PaiementBO[]>> {
    return this.httpClient.get<PaiementBO[]>(`${this.resourceUrlFacture}/getPaiementsByFactureIAPId/` + factureIAPId,
      {observe: 'response'});
  }

	// Réinitialise la liste de la rubrique 48 sur base de l'euro norme choisie
  getNrBaseRegulActLastAmendEngines(tablename: string, exhaustEmissionLevelEuro: string): Observable<HttpResponse<TableReferenceBO[]>> {
    return this.httpClient.get<TableReferenceBO[]>(`${this.resourceUrlReglement}/getNrBaseRegulActLastAmendEngines/` + tablename + "/" + exhaustEmissionLevelEuro,
      {observe: 'response'});
  }

  lockDemande(demandeId: Number): Observable<HttpResponse<Boolean>> {
    return this.httpClient.post<Boolean>(`${this.resourceUrl}/lockDemande`, demandeId,
      {observe: 'response'});
  }

  unlockDemande(demandeId: Number): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/unlockDemande`, demandeId,
      {observe: 'response'});
  }

  getLockingUser(demandeId: Number): Observable<HttpResponse<UserBO>> {
    return this.httpClient.get<UserBO>(`${this.resourceUrl}/getLockingUser/`+ demandeId,
      {observe: 'response'});
  }

  loadAudit(auditId: number): Observable<HttpResponse<AuditBO>> {
    return this.httpClient.get<AuditBO>(`${this.resourceUrl}/loadAudit/`+ auditId,
      {observe: 'response'});
  }

  getListeGestionWarningStatut(): Observable<HttpResponse<GestionWarningStatut[]>> {
    return this.httpClient.get<GestionWarningStatut[]>(`${this.resourceUrl}/getListeGestionWarningStatut/`,
      {observe:'response'});
  }

  updateGestionWarningStatut(gestionWarningStatut: GestionWarningStatut) : Observable<HttpResponse<GestionWarningStatut>> {
    return this.httpClient.post<GestionWarningStatut>(`${this.resourceUrl}/editGestionWarningStatut`, gestionWarningStatut,
      {observe:'response'});
  }

  // Recherche la liste des dossiers dans lequel on retrouve le VIN fourni en paramètre
  vinDansAutresDossiers(vinNumber: string) : Observable<HttpResponse<DemandeSimplifiee[]>> {
    var searchDemandeRequest: SearchDemandeRequest = new SearchDemandeRequest();
    searchDemandeRequest.numeroChassis = vinNumber;
    return this.getListeDemandesSimplifiees(searchDemandeRequest);
  }

  // Recherche à la DIV les spécifications techniques d'un véhicule (IVI) sur base du numéro de chassis
  getVehicleFromDiv(demande: Demande) : Observable<HttpResponse<Demande>> {
    return this.httpClient.post<Demande>(`${this.resourceUrl}/getVehicleFromDiv`, demande,
      {observe:'response'});
  }

  // La demande est reassignée à un autre agent
  reassignerDemande(notificationBO: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationReassignerDemande`, notificationBO,
      {observe: 'response'});
  }

  // Recherche la valeur d'un compteur (sans l'incrémenter)
  getActualCounter(counterName: String): Observable<HttpResponse<number>> {
    return this.httpClient.get<number>(`${this.resourceUrl}/getActualCounter/`+ counterName,
      {observe: 'response'});
  }


  getCompteur(counterName: String): Observable<HttpResponse<CompteurBO>> {
    return this.httpClient.get<CompteurBO>(`${this.resourceUrl}/getCounter/`+ counterName,
      {observe: 'response'});
  }

  // Recherche les classe names (et type name) du constructeur
  getConstructeurClasseNames(constructeurId: number): Observable<HttpResponse<ConstructeurClasseNameBO[]>> {
    return this.httpClient.get<ConstructeurClasseNameBO[]>(`${this.resourceUrlAdmin}/getConstructeurClasseNames/`+ constructeurId,
      {observe: 'response'});
  }

  getEtaesStatus(): Observable<HttpResponse<WtasEtaesStatutBO[]>> {
    return this.httpClient.get<WtasEtaesStatutBO[]>(`${this.resourceUrl}/getEtaesStatus`,
      { observe: 'response' });
  }

  sendToEtaes(etaesid: number): Observable<HttpResponse<void>> {
    return this.httpClient.get<void>(`${this.resourceUrl}/sendToEtaes/` + etaesid,
      { observe: 'response' });
  }

  updateDemandeEtaesInfo(demandeSimplifie: DemandeSimplifiee): Observable<HttpResponse<void>>{
    return this.httpClient.post<void>(`${this.resourceUrl}/updateDemandeEtaesInfo`, demandeSimplifie,
      {observe:'response'});
  }

}

