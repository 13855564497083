import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ui-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;
  @Input() labelSize = 4;
  @Input() valueSize = 8;
  @Input() secondValueSize = 8;
  @Input() secondValue: string;
  // @Input() height = '27px';
  @Input() isLink = false;
  @Input() newPage = false;
  @Input() lineBreak = false;
  @Output() linkClick: EventEmitter<void> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    if (this.secondValue) {
      this.valueSize = 4;
      this.secondValueSize = 4;
    }
  }

  onLinkClick(): void {
    this.linkClick.emit();
  }

}
