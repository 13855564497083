<p-tabView styleClass="tabview-custom" (onChange)="resetSelectedNews()">
  <p-tabPanel >
    <ng-template pTemplate="header">
      <span>Nouvelles News</span>
    </ng-template>
    <p-table #dt1 [value]="newsNotRead" responsiveLayout="stack" dataKey="id"
             [rows]="10"
             [rowsPerPageOptions]="[10, 25, 50]"
             [paginator]="true"
             selectionMode="single" [resizableColumns]="true"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowUnselect($event)"
             [globalFilterFields]="['subject','motcles']"
             [tableStyle]="{'min-width': '100rem'}">
      <ng-template pTemplate="caption">
        <div class="flex">
          <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
          <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText #textInput type="text" (input)="dt1.filterGlobal(textInput.value, 'contains')" placeholder="Recherche" />
            </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [ngStyle]="{'width':'90px'}" pSortableColumn="creationDate">{{'common.dateCreation' | translate}}
            <p-sortIcon field="creationDate"></p-sortIcon>
          </th>
          <th pSortableColumn="subject">{{'common.news.sujet' | translate}}
            <p-sortIcon field="subject"></p-sortIcon>
          </th>
        </tr>
<!--        <tr>-->
<!--          <th></th>-->
<!--          <th><p-columnFilter placeholder="Recherche" type="text" field="subject" matchMode="contains" [showMenu]="false"></p-columnFilter></th>-->
<!--        </tr>-->
      </ng-template>
      <ng-template pTemplate="body" let-onenews>
        <tr [pSelectableRow]="onenews">
          <td>{{onenews.creationDate | date: 'short'}}</td>
          <td>{{onenews.subject}}</td>
        </tr>
      </ng-template>
    </p-table>
    <p-panel header="Details de la news" *ngIf="selectedNews != null" >
      <p-editor  [(ngModel)]="selectedNews.detail" [readonly]="true">
        <ng-template pTemplate="header">
        </ng-template>
      </p-editor>

      <div class="pt-2">
        <button *ngIf="selectedNews.read == false" pButton type="button" label="{{'common.news.button.lu' | translate}}" icon="pi pi-check"
                (click)="readNews()"></button>
        <button *ngIf="selectedNews.read == true" pButton type="button" label="{{'common.news.button.nonlu' | translate}}" icon="pi pi-envelope"
                (click)="unReadNews()"></button>
      </div>
    </p-panel>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <span>News Lu</span>
    </ng-template>
    <p-table #dt2 [value]="newsRead" responsiveLayout="stack" dataKey="id"
             [rows]="10"
             [rowsPerPageOptions]="[10, 25, 50]"
             [paginator]="true"
             selectionMode="single" [resizableColumns]="true"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowUnselect($event)"
             [globalFilterFields]="['subject','motcles']"
             [tableStyle]="{'min-width': '100rem'}">

      <ng-template pTemplate="caption">
        <div class="flex">
          <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt2)"></button>
          <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText #textInput type="text" (input)="dt2.filterGlobal(textInput.value, 'contains')" placeholder="Recherche" />
            </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th [ngStyle]="{'width':'90px'}" pSortableColumn="creationDate">Date
            <p-sortIcon field="creationDate"></p-sortIcon>
          </th>
          <th pSortableColumn="subject">Sujet
            <p-sortIcon field="subject"></p-sortIcon>
          </th>
        </tr>
<!--        <tr>-->
<!--          <th></th>-->
<!--          <th><p-columnFilter  placeholder="Recherche" type="text" field="subject" matchMode="contains" [showMenu]="false"></p-columnFilter></th>-->
<!--        </tr>-->
      </ng-template>
      <ng-template pTemplate="body" let-onenews>
        <tr [pSelectableRow]="onenews">
          <td>{{onenews.creationDate | date: 'short'}}</td>
          <td>{{onenews.subject}}</td>
        </tr>
      </ng-template>
    </p-table>
    <p-panel header="Details de la news" *ngIf="selectedNews != null">
      <p-editor [(ngModel)]="selectedNews.detail" [readonly]="true">
        <ng-template pTemplate="header">
        </ng-template>
      </p-editor>

      <div class="pt-2">
        <button *ngIf="selectedNews.read == false" pButton type="button" label="{{'common.news.button.lu' | translate}}" icon="pi pi-check"
                (click)="readNews()"></button>
        <button *ngIf="selectedNews.read == true" pButton type="button" label="{{'common.news.button.nonlu' | translate}}" icon="pi pi-envelope"
                (click)="unReadNews()"></button>
      </div>
    </p-panel>
  </p-tabPanel>

</p-tabView>
