import { Injectable } from '@angular/core';
import {AbstractBusinessService} from "./abstract-business.service";
import {CommonService} from "./common.service";
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";

import {DocumentBO} from "../models/document.model";
import {PrintAuditCompetencesRequest} from "../../modules/constructeurs/model/print.audit.comp.request";
import { ServiceTechniqueBO } from '../models/servicetechnique.model';
import {DocumentCTSTBO} from "../models/documentctst.model";

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends AbstractBusinessService<DocumentBO> {

  entityIdParamName: string;

  resourceUrl = CommonService.appConfig.backendUrl + 'document';

  constructor(public httpClient:HttpClient) {
    super(httpClient)
  }

  markDocumentToDelete(documentBO: DocumentBO): Observable<HttpResponse<DocumentBO[]>> {
    return this.httpClient.get<DocumentBO[]>(`${this.resourceUrl}/markDocumentToDelete/`+documentBO.id,
     {observe: 'response'});
  }

  download(gedId: string | undefined): Observable<Blob> {
    if(gedId.includes(";")){ //car le ; ne passe pas dans l'url
      gedId = gedId.replace(";","*");
    }
    return this.httpClient.get(`${this.resourceUrl}/displayDocument/${gedId}`, {
      responseType: 'blob'
    });
  }

  downloadArchive(filename: string | undefined): Observable<Blob> {
    return this.httpClient.get(`${this.resourceUrl}/downloadArchive/${filename}`, {
      responseType: 'blob'
    });
  }


  saveDocument(documentBO: DocumentBO): Observable<HttpResponse<DocumentBO>> {
    return this.httpClient.post<DocumentBO>(`${this.resourceUrl}/saveDocument`, documentBO,
      {observe: 'response'});
  }

  printAuditCompetences(request: PrintAuditCompetencesRequest | undefined): Observable<Blob> {
    return this.httpClient.post(`${this.resourceUrl}/printAuditCompetences`, request,
      {responseType: 'blob'
      });
  }

  uploadDocument(file: FormData): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.resourceUrl}/uploadDocument`, file, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.httpClient.request(req);
  }

  // uploadDocument(file: FormData): Observable<Object> {
  //   return this.httpClient.post(this.resourceUrl + '/uploadDocument', file);
  // }

  // newUploadDocument(file: FormData): Observable<Object> {
  //   console.log("new upload");
  //   return this.httpClient.post(this.resourceUrl + '/uploadDocument', file);
  // }

  getDocumentsForDemande(demandeId: number): Observable<HttpResponse<DocumentBO[]>> {
    return this.httpClient.get<DocumentBO[]>(`${this.resourceUrl}/getDocumentForDemande/`+ demandeId,
      {observe: 'response'});
  }
  getDocumentsForTest(testId: number): Observable<HttpResponse<DocumentBO[]>> {
    return this.httpClient.get<DocumentBO[]>(`${this.resourceUrl}/getDocumentForTest/`+ testId,
      {observe: 'response'});
  }
  getDocumentsForAudit(auditId: number): Observable<HttpResponse<DocumentBO[]>> {
    return this.httpClient.get<DocumentBO[]>(`${this.resourceUrl}/getDocumentForAudit/`+ auditId,
      {observe: 'response'});
  }

  getFacturesForConstructeur(id: number): Observable<HttpResponse<DocumentBO[]>> {
    return this.httpClient.post<DocumentBO[]>(`${this.resourceUrl}/getFacturesForConstructeur`, id,
      {observe: 'response'});
  }

  getFacturesForServiceTechnique(serviceTechniqueBO: ServiceTechniqueBO): Observable<HttpResponse<DocumentBO[]>> {
    return this.httpClient.post<DocumentBO[]>(`${this.resourceUrl}/getFacturesForServiceTechnique`, serviceTechniqueBO,
      {observe: 'response'});
  }

  getDocumentForAudit(auditId: number): Observable<HttpResponse<DocumentBO[]>> {
    return this.httpClient.get<DocumentBO[]>(`${this.resourceUrl}/getDocumentForAudit/`+ auditId,
      {observe: 'response'});
  }

  getDocumentsCTST(): Observable<HttpResponse<DocumentCTSTBO[]>>{
    return this.httpClient.get<DocumentBO[]>(`${this.resourceUrl}/getDocumentsCTST/`,
      {observe: 'response'});
  }
  deleteDocumentCTST(id: number): Observable<HttpResponse<void>>{
    return this.httpClient.get<void>(`${this.resourceUrl}/deleteDocumentCTST/`+id,
      {observe: 'response'});
  }

  saveDocumentCTST(documentBO: DocumentCTSTBO): Observable<HttpResponse<DocumentCTSTBO>> {
    return this.httpClient.post<DocumentCTSTBO>(`${this.resourceUrl}/saveDocumentCTST`, documentBO,
      {observe: 'response'});
  }

}
