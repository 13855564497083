import {Component, OnDestroy, OnInit} from '@angular/core';
import {from, Observable, of, Subject, timer} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {CommonService} from '../../../core';
import {Contact} from '../../model/contact.model';
import {ApiModel} from '../../model/api.model';
import {CheckAllService} from '../../services/check-all/check-all.service';

@Component({
  selector: 'app-check-all',
  templateUrl: './check-all.component.html',
  styleUrls: ['./check-all.component.scss']
})
export class CheckAllComponent implements OnInit, OnDestroy {

  contactSPW: Contact;
  contactPrestataire: Contact;
  services: Observable<ApiModel[]>;
  envServices: Observable<ApiModel[]>;
  resp: any;
  // appVersion: any;
  trigger$ = timer(1, 20000);
  trigger2$ = timer(1, 20000);
  downCountEnv = 0;
  downCountRest = 0;
  private stopPolling = new Subject();

  constructor(public checkAllService: CheckAllService,
              private translate: TranslateService) {
    translate.use('fr');
    translate.setDefaultLang('fr');
  }

  ngOnInit(): void {
    this.services = this.trigger$.pipe(switchMap(() => {
      return this.checkAllService.getRestApiHealth()
        .pipe(switchMap(val => {
          return from(this.checkAllService.getServices())
            .pipe(switchMap(resp => {
              let value = resp;
              value = value.map((api: ApiModel) => this.mapHealthRestApi(api, val.body));
              return of(value);
            }));
        }));
    }), takeUntil(this.stopPolling));

    this.envServices = this.trigger2$.pipe(
      switchMap(() => this.checkAllService.getEnvHealth()),
      takeUntil(this.stopPolling));


    this.checkAllService.getContacts()
      .then(value => {
        this.contactSPW = value.SPW;
        this.contactPrestataire = value.PRESTATAIRE;
      });
  }

  mapHealthRestApi(model: ApiModel, apiResult: any[]): ApiModel {
    const result = apiResult.find(value => value.Name === model.url);
    if (result) {
      model.up = result.ChecksCritical === 0;
      model.instance = result.InstanceCount;
      return model;
    } else {
      return model;
    }
  }


  // getDataSourceFromCheckList(list: ApiModel[], env?: boolean): MatTableDataSource<ApiModel> {
  //   if (env) {
  //     this.downCountEnv = 0;
  //   } else {
  //     this.downCountRest = 0;
  //   }
  //   if (list) {
  //     list.forEach(value => {
  //       if (!value.up && env) {
  //         this.downCountEnv = this.downCountEnv + 1;
  //       }
  //       if (!value.up && !env) {
  //         this.downCountRest = this.downCountRest + 1;
  //       }
  //
  //     });
  //   }
  //   return new MatTableDataSource(list);
  // }

  get frontVersion(): string {
    return CommonService.appConfig.version;
  }

  ngOnDestroy(): void {
    this.stopPolling.next();
  }

}
