import {Component, Input, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {CommonService} from '../../../core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() user: string;
  @Input() authenticated: boolean;

  languageList = [
    {code: 'FR', label: 'French'},
    {code: 'NL', label: 'Dutch'},
    {code: 'EN', label: 'English'},
  ];

  constructor(private oAuthService: OAuthService) {
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.oAuthService.logOut();
  }

  get fullName(): string {
    return CommonService.userInfo.firstName + ' ' + CommonService.userInfo.lastName;
  }

  get shortName(): string {
    return CommonService.userInfo.firstName[0] + CommonService.userInfo.lastName[0];
  }

  get version(): string {
    return 'Version ' + CommonService.appConfig.version;
  }

}
