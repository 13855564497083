import {Component, Input, OnInit} from '@angular/core';
import {Demande} from "../../../modules/demande/model/demande.model";
import {Commentaire} from "../../../core/models/commentaire.model";
import {CommentaireService} from "../../../core/services/commentaire.service";
import {TableReferenceService} from "../../../core/services/table-reference.service";
import {ConstructeurBO} from "../../../core/models/constructeurs.model";
import {ServiceTechniqueBO} from "../../../core/models/servicetechnique.model";
import {Notification} from "../../../modules/notification/model/notification.model";
import {EnumTypeCommentaire} from "../../../core/enums/enum-type-commentaire";
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {GetCommentairesConstrType} from "../../../modules/administration/model/get.commentaire.constr.type.request";
import {Router} from "@angular/router";
import {NotificationService} from "../../../modules/notification/services/notification.service";
import {UserBO} from "../../../core/models/userbo.model";

@Component({
  selector: 'app-list-commentaire-form',
  templateUrl: './list-commentaire-form.component.html',
  styleUrls: ['./list-commentaire-form.component.scss'],
  providers: [ConfirmationService, TranslateService]
})
export class ListCommentaireFormComponent implements OnInit {

  @Input() demande: Demande;
  @Input() constructeur: ConstructeurBO;
  @Input() servicetechnique: ServiceTechniqueBO;
  @Input() notificationBO: Notification;
  @Input() user: UserBO;

  commentaires: Commentaire[];
  displayDetailCommentaire: boolean = false;
  commentaireBO: Commentaire;
  selectedIndex: number;

  // confirm dialog
  buttonYes: string;
  buttonNo: string;
  areyousure: string;

  typeCommentaire: string;
  demandeBO: Demande;

  cols: any[];

  showExport: boolean = false;

  constructor(private tableReferenceService: TableReferenceService, private commentaireService: CommentaireService,
          private confirmationService: ConfirmationService, private translateService: TranslateService,
              private notificationService: NotificationService, private router: Router) { }

  ngOnInit(): void {

    this.showExport = false;

    this.cols = [
      {field: 'dateCommentaire'},
      {field: 'auteur'},
      {field: 'type'},
      {field: 'surCertificat'},
      {field: 'prive'},
      {field: 'commentaire'},
      {field: 'numeroDossier'}
    ];

    // Si demande n'est pas null, les commentaires sont ceux liés à la demande
    if (this.demande != null && this.demande.id != null) {
      this.showExport = false;
      this.commentaireService.getCommentairesForDemande(this.demande.id).subscribe(response => {
        this.commentaires = response.body;
      });
    }
    else {
      // Si constructeur n'est pas null, les commentaires sont ceux liés au constructeur
      if (this.constructeur != null && this.constructeur.id != null) {
        this.showExport = true;
        this.commentaireService.getCommentairesForConstructeur(this.constructeur.id).subscribe(response => {
          this.commentaires = response.body;
        });
      }
      // Si servicetechnique n'est pas null, les commentaires sont ceux liés au servicetechnique
      if (this.servicetechnique != null && this.servicetechnique.id != null) {
        this.showExport = true;
        this.commentaireService.getCommentairesForServiceTechnique(this.servicetechnique.id).subscribe(response => {
          this.commentaires = response.body;
        });
      }
    }
    // load in the cache the reference table typeCommentaire
    if (this.tableReferenceService.apiCache.get("typeCommentaire") == null) {
      this.tableReferenceService.getReferenceTable("typeCommentaire").subscribe(response => {
           this.tableReferenceService.apiCache.set("typeCommentaire", response.body);
      });
    }
    // amelioration wtas load liste type commentaire for constructeur
    if (this.tableReferenceService.apiCache.get("typeCommentaireConstr") == null) {
      this.tableReferenceService.getReferenceTable("typeCommentaireConstr").subscribe(response => {
        this.tableReferenceService.apiCache.set("typeCommentaireConstr", response.body);
      });
    }
    // load in the cache the reference table commentaireStandard
    if (this.tableReferenceService.apiCache.get("commentaireStandard") == null) {
      this.tableReferenceService.getReferenceTable("commentaireStandard").subscribe(response => {
           this.tableReferenceService.apiCache.set("commentaireStandard", response.body);
      });
    }

    this.translateService.get("UI000.areyousure").subscribe(value =>
      this.areyousure = value);
    this.translateService.get("UI000.bouton.yes").subscribe(value =>
      this.buttonYes = value);
    this.translateService.get("UI000.bouton.no").subscribe(value =>
      this.buttonNo = value);


    this.displayDetailCommentaire = false;
    this.selectedIndex = -1;
  }
  getLabelForNumber(tablename: string, code: number): string {
    return this.tableReferenceService.apiCache.getLabelForNumber(tablename, code);
  }
  getLabelForString(tablename: string, code: string): string {
    return this.tableReferenceService.apiCache.getLabelForString(tablename, code);
  }
  getReferenceTable(tablename: string) {
    return this.tableReferenceService.apiCache.get(tablename);
  }


  // L'utilisateur a cliqué sur le bouton "Ajouter" pour créer un nouveau commentaire
  addCommentaire(): void {
    this.displayDetailCommentaire = true;
    this.selectedIndex = -1;
    this.commentaireBO = new Commentaire();
    if (this.demande != null) {
      this.commentaireBO.demandeId = this.demande.id;
      // saver le constr/serviceTechnique
      if(this.demande.demandeurBO){
        if(this.demande.demandeurBO.constructeurOuTiers == "CO"){
          this.commentaireBO.constructeurId = this.demande.demandeurBO.id;

        }
        if(this.demande.demandeurBO.constructeurOuTiers == "ST"){
          this.commentaireBO.servicetechniqueId = this.demande.demandeurBO.id;
        }
      }
      this.commentaireBO.type = EnumTypeCommentaire.DEMANDE;
    }
    if (this.constructeur != null) {
      this.commentaireBO.constructeurId = this.constructeur.id;
      this.commentaireBO.type = EnumTypeCommentaire.CONSTRUCTEUR;
    }
    if (this.servicetechnique != null) {
      this.commentaireBO.servicetechniqueId = this.servicetechnique.id;
      this.commentaireBO.type = EnumTypeCommentaire.SERVICETECHNIQUE;
    }
  }
  // l'utilisateur veut sauver son commentaire
  sauverCommentaire(): void {
    this.commentaireService.sauverCommentaire(this.commentaireBO).subscribe(response => {
      this.commentaires = response.body;
      this.selectedIndex = -1;
      this.displayDetailCommentaire = false;
      this.commentaireBO = new Commentaire();
    });
  }
  // L'utilisateur veut sauver son commentaire (dans le cas du traitement de la notification de signature et du refus qui en a suivi)
  // TODO - à revoir lorsque l'on traitera la notification "à signer" - on ne passera peut être pas par ici
  sauverCommentaireRefus(): void {
    this.commentaireService.sauverCommentaireRefus(this.commentaireBO).subscribe(response => {
      this.commentaires = response.body;
    });
  }
  // L'utilisateur décide d'annuler ses modifications
  cancelCommentaire(): void {
    this.displayDetailCommentaire = false;
    this.commentaireBO = new Commentaire();
  }
  // L'utilisateur a cliqué sur un commentaire de la liste
  selectCommentaire(comment: Commentaire): void {
    this.commentaireBO = {...comment};
    this.displayDetailCommentaire = true;
  }

  // L'utilisateur a décidé de supprimer un commentaire de la liste
  deleteCommentaire(event: Event, comment: Commentaire, index: number): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.areyousure,
      acceptLabel: this.buttonYes,
      rejectLabel: this.buttonNo,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.commentaires.splice(index, 1);
        this.commentaireService.removeCommentaire(comment).subscribe(response => {
        });
      },
      reject: () => {
        // reject action - nothing to do
      }
    });
  }

  /**
   * Amelioration WTAS3
   */
  filterCommentaireConstrByType(){
    if (this.constructeur != null && this.constructeur.id != null && this.typeCommentaire != null && this.typeCommentaire.length > 0) {
      let getForConstrRequest : GetCommentairesConstrType = new GetCommentairesConstrType();
      getForConstrRequest.constructeurId = this.constructeur.id;
      getForConstrRequest.typeCommentaire = this.typeCommentaire;
      this.commentaireService.getForConstructeurByType(getForConstrRequest).subscribe(response => {
        this.commentaires = response.body;
      });
    }
  }

  filterCommentaireServTechByType(){
    if (this.servicetechnique != null && this.servicetechnique.id != null && this.typeCommentaire != null && this.typeCommentaire.length > 0) {
      let getForConstrRequest : GetCommentairesConstrType = new GetCommentairesConstrType();
      getForConstrRequest.constructeurId = this.servicetechnique.id;
      getForConstrRequest.typeCommentaire = this.typeCommentaire;
      this.commentaireService.getForServiceTechniqueByType(getForConstrRequest).subscribe(response => {
        this.commentaires = response.body;
      });
    }
  }

  filterCommentaireServTechAll(){
    this.showExport = false;
    if (this.servicetechnique != null && this.servicetechnique.id != null) {
      this.commentaireService.getCommentairesForServiceTechniqueAll(this.servicetechnique.id).subscribe(response => {
        this.commentaires = response.body;
      });
    }
  }

  filterCommentaireConstrAll(){
    this.showExport = false;
    if (this.constructeur != null && this.constructeur.id != null) {
      this.commentaireService.getCommentairesForConstructeurAll(this.constructeur.id).subscribe(response => {
        this.commentaires = response.body;
      });
    }
  }



  effacerFiltreCommentaireConstr(){
    this.typeCommentaire = '';
    if (this.constructeur != null && this.constructeur.id != null) {
      this.showExport = true;
      this.commentaireService.getCommentairesForConstructeur(this.constructeur.id).subscribe(response => {
        this.commentaires = response.body;
      });
    }
  }

  effacerFiltreCommentaireServTech(){
    this.typeCommentaire = '';
    if (this.servicetechnique != null && this.servicetechnique.id != null) {
      this.showExport = true;
      this.commentaireService.getCommentairesForServiceTechnique(this.servicetechnique.id).subscribe(response => {
        this.commentaires = response.body;
      });
    }
  }

  redirectToDemande(comment: Commentaire, index: number): void {
    this.notificationService.loadDemande(comment.demandeId).subscribe(response => {
      this.demandeBO = response.body;
      this.router.navigate(['/demande/detail-demande', {myDemand: JSON.stringify(this.demandeBO)}]);
    })
  }
}
