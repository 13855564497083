import { AfterViewInit, Directive } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import Inputmask from 'inputmask';

@Directive({
  selector: '[dateMask]'
})
export class DateMaskDirective implements AfterViewInit {
  constructor(private primeCalendar: Calendar) {
    this.primeCalendar.dateFormat="dd/mm/yy";
  }

  ngAfterViewInit() {
    new Inputmask( this.getDateMask() ).mask( this.getHTMLInput() );
  }

  getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }

  // <p-calendar [(ngModel)]="dateValue" [showTime]="true" dateMask></p-calendar>
  getDateMask(): string {
    if(this.primeCalendar.timeOnly) {
      return '99:99';
    } else if(this.primeCalendar.showTime) {
      return '99/99/9999 99:99';
    } else {
      return '99/99/9999';
    }
  }
}
