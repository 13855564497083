import {FacesMessageBO} from "..//models/facesmessage.model";

export class DocumentBO {

	checked?: boolean;	// use only on screen to select one or more documents
	id?: number;			// primary key
	depotDate?: Date;		// date de dépôt du fichier
	typeDocument?: string;	// voir EnumTypeDocument
	gedId?: string;		// référence de la GED
	templateId?: number;	// lien vers le template ayant servi à la génération du document
	demandeId?: number;		// référence vers la demande dont le document dépend
	testId?: number;		// référence vers le test (pour les rapports de test, projet de certificat, etc)
	factureId?: number; 	// référence vers la facture dont le document dépend
	auditId?: number;		// référence vers l'audit dont le document dépend
	prive?: boolean;		// true si le document est privé
	userName?: string;	// nom du dépositaire
	fileType?: string;	// type de fichier (pdf, excel, word, ...)
	size?: number;		// taille du fichier
	signer?: boolean;		// Document signé
	aSigner?: boolean;	// Document à signer
	todelete?: boolean;   // marquer un document comme etant supprime ou invalider
	rowIndex?: number;
	orderPdf?: number;		// order to create pdf signed multidoc
	originalfilename?: string; // nome de fichier original
	docToMerge?: number;		// ordre - document sélectionné pour être assembler avec le certificat (purement informatif pour le signataire)

	numeroReception?: string;
	constructeurTierId?: number;
	mandataireId?: number;
	serviceTechniqueId?: number;

	documentContext?: string;
	documentFilenameOrigine?: string; // rempli si l'upload a été effectué

  facesMessageBO?: FacesMessageBO;

  reportNumber?: string;  // Numéro du rapport de test ou d'essai

}
