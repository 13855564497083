<div class="menu-bar-wrapper">
  <mat-toolbar class="container">

    <!--*********** MENU Notification *******************--->
    <button mat-button class="menu-button" (click)="gotoNotifications()">
      {{'common.menu.notification'| translate}}
    </button>


    <!--********** MENU Demande *************************-->
    <button *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAdministratif == true || user.agentTechnique == true || user.responsableTechnique == true || user.signataire == true
          || user.constructeur == true || user.auditeurInterne == true || user.agentAutreRegion || user.serviceTechnique == true || user.administrateurSysteme == true || user.mandataire == true || user.responsableFinancier == true || user.responsableAdministratif == true"
             mat-button [matMenuTriggerFor]="demande" class="menu-button">
      {{'common.menu.demande'| translate}}
      <mat-menu #demande="matMenu" yPosition="below" class="sub-menu-overlay">
        <a *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAdministratif == true || user.agentTechnique == true || user.responsableTechnique == true || user.signataire == true
          || user.constructeur == true || user.serviceTechnique == true || user.administrateurSysteme == true || user.mandataire == true || user.responsableFinancier == true || user.responsableAdministratif == true"
           (click)="gotoMesDossiers()" mat-menu-item>{{'common.menu.mesdossiers' | translate}}</a>
        <a *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAdministratif == true || user.agentTechnique == true || user.responsableTechnique == true || user.signataire == true
          || user.constructeur == true || user.auditeurInterne == true || user.agentAutreRegion || user.serviceTechnique == true || user.administrateurSysteme == true || user.mandataire == true || user.responsableFinancier == true || user.responsableAdministratif == true"
          (click)="gotoRechercherDemande()" mat-menu-item>{{'common.menu.rechercherdemande' | translate}}</a>
        <a *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAdministratif == true || user.agentTechnique == true || user.responsableTechnique == true || user.signataire == true
          || user.constructeur == true || user.serviceTechnique == true || user.administrateurSysteme == true || user.mandataire == true || user.responsableFinancier == true || user.responsableAdministratif == true  "
           (click)="gotoNouvelleDemande()" mat-menu-item>{{'common.menu.nouvelledemande' | translate}}</a>
      </mat-menu>
    </button>
    <!--*********** MENU Constructeur *******************--->
    <button *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAdministratif == true || user.agentTechnique == true || user.responsableTechnique == true || user.signataire == true
          ||  user.responsableAdministratif == true || user.auditeurInterne == true || user.responsableFinancier == true" mat-button [matMenuTriggerFor]="constr" class="menu-button">
      {{'common.menu.constructeur'| translate}}
      <mat-menu #constr="matMenu" yPosition="below" class="sub-menu-overlay">
        <a *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAdministratif == true || user.agentTechnique == true || user.responsableTechnique == true
          ||  user.responsableAdministratif == true || user.auditeurInterne == true || user.signataire == true || user.responsableFinancier == true"
           (click)="gotoSearchConstructeur()" mat-menu-item>{{'common.menu.constructeur.search' | translate}}</a>
        <a *ngIf="user.superUser == true || user.agentConstructeur == true" (click)="gotoNewConstructeur()" mat-menu-item>{{'common.menu.constructeur.new' | translate}}</a>
        <a *ngIf="user.superUser == true || user.agentConstructeur == true" (click)="gotoNewServiceTechnique()" mat-menu-item>{{'common.menu.service.technique.new' | translate}}</a>
        <button *ngIf="user.superUser == true || user.agentConstructeur == true || user.auditeurInterne == true" mat-menu-item [matMenuTriggerFor]="subMenuConstructSpecific">{{'common.menu.constructeur.specific' | translate}}</button>
      </mat-menu>
      <mat-menu #subMenuConstructSpecific="matMenu" class="sub-menu-overlay" >
        <a (click)="gotoListClassenames()" mat-menu-item>{{'common.menu.constructeur.classename' | translate}}</a>
        <a (click)="gotoListMaterialnames()" mat-menu-item>{{'common.menu.constructeur.materialname' | translate}}</a>
      </mat-menu>
  </button>

    <!--*********** MENU Administration *******************--->
    <button *ngIf="user.superUser == true || user.administrateurSysteme == true || user.agentConstructeur == true || user.responsableAdministratif == true || user.responsableTechnique == true || user.responsableFinancier == true || user.auditeurInterne == true" mat-button [matMenuTriggerFor]="administration" class="menu-button">
      {{'common.menu.administration'| translate}}
      <mat-menu #administration="matMenu" yPosition="below" class="sub-menu-overlay">
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoListesValeurs()" mat-menu-item>{{'common.menu.listesvaleurs' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoModelesdocuments()" mat-menu-item>{{'common.menu.modelesdocuments' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoParametresSysteme()" mat-menu-item>{{'common.menu.parametressysteme' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoGestionNumeroReception()" mat-menu-item>{{'common.menu.administration.gestionnumeroreception' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoDirective()" mat-menu-item>{{'common.menu.directive' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true || user.responsableFinancier == true"
          (click)="gotoTraceFinancier()" mat-menu-item>{{'common.menu.tracefinancier' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true || user.responsableFinancier == true"
          (click)="gotoTypePrestation()" mat-menu-item>{{'common.menu.typeprestation' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoTypeFormulaire()" mat-menu-item>{{'common.menu.typeformulaire' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoTypeNotification()" mat-menu-item>{{'common.menu.typenotification' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.responsableFinancier == true"
          (click)="gotoGestionPaiements()" mat-menu-item>{{'common.menu.gestionpaiements' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoGestionCompteur()" mat-menu-item>{{'common.menu.administration.gestioncompteur' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true"
          (click)="gotoBlackListVIN()" mat-menu-item>{{'common.menu.administration.blacklistvin' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.auditeurInterne == true || user.agentConstructeur == true"
          (click)="gotoUtilisateurs()" mat-menu-item>{{'common.menu.administration.gestionutilisateur' | translate}}</a>
        <a *ngIf="user.superUser == true || user.agentConstructeur == true || user.administrateurSysteme == true || user.responsableAdministratif == true || user.responsableTechnique == true || user.auditeurInterne == true"
          (click)="gotoNews()" mat-menu-item>{{'common.menu.administration.listgestionnews' | translate}}</a>
        <a *ngIf="user.superUser == true || user.agentConstructeur == true || user.administrateurSysteme == true || user.responsableAdministratif == true || user.responsableTechnique == true || user.auditeurInterne == true"
          (click)="gotoAnnonces()" mat-menu-item>{{'common.menu.administration.listgestionannonces' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.responsableAdministratif == true || user.responsableTechnique == true || user.auditeurInterne == true"
          (click)="gotoGestionWarningStatut()" mat-menu-item>{{'common.menu.administration.listgestionwarningstatut' | translate}}</a>
        <a *ngIf="user.superUser == true || user.administrateurSysteme == true || user.agentAdministratif || user.agentTechnique"
           (click)="gotoEtaesStatus()" mat-menu-item>Gestion ETAES</a>
      </mat-menu>
  </button>
    <!--*********** MENU Tableau de bord *******************--->
    <button *ngIf="user.agentDO215 == true" mat-button [matMenuTriggerFor]="tableaubord" class="menu-button">
      {{'common.menu.tableaudebord'| translate}}
    <mat-menu #tableaubord="matMenu" yPosition="below" class="sub-menu-overlay">
      <a (click)="gotoTableaudebord()" mat-menu-item>{{'common.menu.tableaudebord.tableaudebord' | translate}}</a>
      <a (click)="gotoRapports()" mat-menu-item>{{'common.menu.tableaudebord.rapport' | translate}}</a>
    </mat-menu>
  </button>

    <!--*********** MENU Mon Profil *******************--->
<!--    AH006 8 15-->
    <button *ngIf="user.serviceTechnique == true || user.constructeur == true || user.mandataire == true"mat-button class="menu-button" (click)="gotoMonprofil()">
      {{'common.menu.monprofil'| translate}}
    </button>

    <!--*********** MENU Numéro de réception *******************--->
    <button *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAutreRegion == true || user.auditeurInterne || user.agentAdministratif == true || user.agentTechnique == true || user.responsableAdministratif == true || user.responsableTechnique == true"
            mat-button [matMenuTriggerFor]="reception" class="menu-button">
      {{'common.menu.numeroreception'| translate}}
    <mat-menu #reception="matMenu" yPosition="below" class="sub-menu-overlay">
      <a *ngIf="user.superUser == true || user.agentTechnique == true || user.agentAutreRegion == true || user.responsableTechnique == true " (click)="gotoNumeroReceptionNouveau()" mat-menu-item>{{'common.menu.numeroreception.nouveau' | translate}}</a>
      <a *ngIf="user.superUser == true || user.agentConstructeur == true || user.agentAutreRegion == true || user.auditeurInterne || user.agentAdministratif == true || user.agentTechnique == true || user.responsableAdministratif == true || user.responsableTechnique == true"
         (click)="gotoNumeroReceptionAttribue()" mat-menu-item>{{'common.menu.numeroreception.attribue' | translate}}</a>
      <a *ngIf="user.superUser == true || user.agentTechnique == true || user.responsableTechnique == true || user.agentAutreRegion == true || user.auditeurInterne == true" (click)="gotoNumeroReceptionEnregistrerAncien()" mat-menu-item>{{'common.menu.numeroreception.enregistrerancien' | translate}}</a>
    </mat-menu>
  </button>


    <button *ngIf="user.agentDO215 == true || user.documentAcces == true" mat-button class="menu-button" (click)="gotoDocuments()">
      Documents
    </button>


    <button *ngIf="displayNewsAlert == true" mat-button class="menu-button" (click)="showNews()">
      <mat-icon color="warn">sms</mat-icon>{{'common.menu.news'| translate}}
    </button>
    <button *ngIf="displayNewsAlert == false" mat-button class="menu-button" (click)="showNews()">
      <mat-icon color="green">sms</mat-icon>{{'common.menu.news'| translate}}
    </button>

  <div *ngIf="expandable" class="expand-tools">
    <mat-icon *ngIf="!expanded" (click)="onExpand()">fullscreen</mat-icon>
    <mat-icon *ngIf="expanded" (click)="onExpandExit()">fullscreen_exit</mat-icon>
  </div>
  </mat-toolbar>
</div>

<p-dialog header="{{'UI802.ListNews' | translate}}" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
          [(visible)]="displayNewsDialog" [style]="{'min-width': '60vw' , 'min-height': '40vw'}" [contentStyle]="{'min-height': '280px'}">

  <app-list-news-form *ngIf="displayNewsDialog == true">

  </app-list-news-form>
</p-dialog>
