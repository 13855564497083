export class UserInfo {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  locale?: string;
  enabled?: boolean;

  constructor(data: any) {
    this.id = data.sub;
    this.username = data.preferred_username ? data.preferred_username : data.sub;
    this.firstName = data.given_name ? data.given_name : data.firstName;
    this.lastName = data.family_name ? data.family_name : data.lastName;
    this.email = data.email;
    this.locale = data.locale ? data.locale : data.fr;
  }
}
