import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';


/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *hasRole="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *hasRole="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */

@Directive({
  selector: '[hasRole]',
})
export class HasRoleDirective {
  private roles: string[];

  constructor(private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set hasRole(value: string | string[]) {
    this.roles = typeof value === 'string' ? [value] : value;
    this.updateView();
  }

  private updateView(): void {
    // TODO Implement hasRole
    // const hasAnyRole = KeycloakService.hasRoles(this.roles);
    const hasAnyRole = true;
    this.viewContainerRef.clear();
    if (hasAnyRole) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
