import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home/home.component';
import {ErrorComponent} from './shared';
import {CheckAllComponent} from './check-all/pages/check-all/check-all.component';
import {CheckComponent} from './check-all/pages/check/check.component';
import {AuthGuardService, PermissionGuard} from "./core";

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home', preload: true
    },
  },
  {
    path: 'notification',
    canLoad: [AuthGuardService],
    data: {
      title: 'Notification', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('../app/modules/notification/notification.module')
      .then(m => m.NotificationModule)
  },
  // {
  //   path: 'news',
  //   canLoad: [AuthGuardService],
  //   data: {
  //     title: 'News', preload: true,
  //     permission: {
  //       redirectTo: '403'
  //     } as PermissionGuard
  //   },
  //   loadChildren: () => import('../app/modules/notification/news.module')
  //     .then(m => m.NewsModule)
  // },
  {
    path: 'demande',
    canLoad: [AuthGuardService],
    data: {
      title: 'Demande', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/demande/demande.module')
      .then(m => m.DemandeModule)
  },
  {

    path: 'constructeur',
    canLoad: [AuthGuardService],
    data: {
      title: 'Constructeur', preload: true,

      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/constructeurs/constructeurs.module')
      .then(m => m.ConstructeursModule)

  },
  {
    path: 'administration',
    canLoad: [AuthGuardService],
    data: {
      title: 'Administration', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/administration/administration.module')
      .then(m => m.AdministrationModule)
  },
  {
    path: 'numerotation',
    canLoad: [AuthGuardService],
    data: {
      title: 'Numerotation', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/numerotation/numerotation.module')
      .then(m => m.NumerotationModule)
  },
  {
    path: 'tableaudebord',
    canLoad: [AuthGuardService],
    data: {
      title: 'Tableau de bord', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/tableaudebord/tableaudebord.module')
      .then(m => m.TableaudebordModule)
  },
  {
    path: 'rapports',
    canLoad: [AuthGuardService],
    data: {
      title: 'Rapports', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/tableaudebord/tableaudebord.module')
      .then(m => m.TableaudebordModule)
  },
  {
    path: 'monprofil',
    canLoad: [AuthGuardService],
    data: {
      title: 'Mon profil', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/monprofil/monprofil.module')
      .then(m => m.MonprofilModule)
  },
  {
    path: 'documents',
    canLoad: [AuthGuardService],
    data: {
      title: 'Documents', preload: true,
      permission: {
        redirectTo: '403'
      } as PermissionGuard
    },
    loadChildren: () => import('./modules/documents/documents.module')
      .then(m => m.DocumentsModule)
  },

  {
    path: '404',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'error.title',
      error404: true
    }
  },
  {
    path: '403',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'error.title',
      error403: true
    }
  },
  {
    path: 'check',
    component: CheckComponent
  },
  {
    path: 'check-all',
    component: CheckAllComponent,
  },
  // {path: '&iss', redirectTo: '/choix-entreprise', pathMatch: 'full'}, // catch the weird return from GestIA
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', pathMatch: 'full', redirectTo: '/404'} // catch any unfound routes and redirect to error 404 page

];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {enableTracing: true})],
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
