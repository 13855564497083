import {ApplicationRef, DoBootstrap, LOCALE_ID, NgModule} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import localeFr from '@angular/common/locales/fr';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {HomeModule} from './home/home.module';
import {
  AppConfig,
  AuthExpiredInterceptor,
  CommonService,
  LoaderInterceptor,
  PermissionService,
  SecuredHttpInterceptor,
  UserInfo
} from './core';
import {CheckAllModule} from './check-all/check-all.module';
import {CheckAllComponent} from './check-all/pages/check-all/check-all.component';
import {CheckComponent} from './check-all/pages/check/check.component';
import {AuthConfig, OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {MessageService} from 'primeng/api';

registerLocaleData(localeFr, 'fr');

export function createTranslateLoader(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend), './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ],
  imports: [
    BrowserModule,
    SharedModule,
    HomeModule,
    AppRoutingModule,
    CheckAllModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpBackend]
      }
    }),
  ],
  providers: [DatePipe, MessageService,
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
    {provide: LOCALE_ID, useValue: 'fr'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecuredHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    OAuthService

  ],
  entryComponents: [AppComponent]
})
export class AppModule implements DoBootstrap {
  http: HttpClient;

  constructor(private permissionService: PermissionService,
              private httpBackend: HttpBackend,
              private oAuthService: OAuthService
  ) {
    this.http = new HttpClient(httpBackend);
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    this.http.get('./assets/environment.json')
      .subscribe(data => {
        CommonService.appConfig = data as AppConfig;
        if (!(window.location.pathname.indexOf('check-all') !== -1)
          && !(window.location.pathname.indexOf('check') !== -1)) {
          if (CommonService.appConfig.disableSecurity) {
            this.http.get('./assets/sampleUserInfo.json')
              .subscribe(userInfo => {
                CommonService.userInfo = new UserInfo(userInfo);
                appRef.bootstrap(AppComponent);
              });
          } else if (((window.location.pathname.length < 2) || window.location.pathname.indexOf('home') !== -1) && !sessionStorage.getItem('redirectAfterLogin') && !this.oAuthService.hasValidAccessToken()) {
            this.http.get('./assets/anonymousUserInfo.json')
              .subscribe(userInfo => {
                CommonService.userInfo = new UserInfo(userInfo);
                this.openIdSetUp(appRef);
              });
          } else {
            this.openIdSetUp(appRef);
          }
        } else if ((window.location.href.indexOf('check-all') !== -1)) {
          CommonService.checkAllPage = true;
          appRef.bootstrap(CheckAllComponent);
        } else {
          appRef.bootstrap(CheckComponent);
        }
      }, error1 => console.log(error1));
  }

  /**
   * Setup OpenID authentication mechanism
   * @param appRef appRef
   */
  openIdSetUp(appRef: ApplicationRef): void {
    this.http.get('./assets/authConfig.json')
      .toPromise().then(data1 => {
      const authCodeFlowConfig = data1 as AuthConfig;
      // if (!sessionStorage.getItem('redirectAfterLogin')) {
      //   sessionStorage['redirectAfterLogin'] = window.location.origin;
      // }
      this.oAuthService.configure(authCodeFlowConfig);
      // authCodeFlowConfig.redirectUri = window.location.origin;
      // authCodeFlowConfig.postLogoutRedirectUri = window.location.origin;
      // sessionStorage['redirectUri'] = this.oAuthService.redirectUri;
      sessionStorage.setItem('redirectUri', this.oAuthService.redirectUri);
      /**
       * acr_values is used when authenticating with GestIA to choose the authentication provider (in environment.json):
       * - password -> GestIA
       * - fas -> FAS (eID or Itsme)
       */
      this.oAuthService.customQueryParams = {
        acr_values: CommonService.appConfig.acrValues
      };
      this.oAuthService.setupAutomaticSilentRefresh();
      this.oAuthService.loadDiscoveryDocumentAndTryLogin()
        .then(() => {
          if (!this.oAuthService.hasValidAccessToken()) {
            appRef.bootstrap(AppComponent);
          } else {
            this.oAuthService.loadUserProfile().then(
              val => {
                CommonService.userInfo = new UserInfo(val);
                appRef.bootstrap(AppComponent);
              });
          }
        }, error => console.log(error));
    });
  }
}
