import {Pipe, PipeTransform} from '@angular/core';

/**
 * Retourne la première lettre du string fourni en input
*/
@Pipe({name: 'getFirstLetterVehCatHomologation'})
export class GetFirstLetterVehCatHomologationPipe implements PipeTransform {

  transform(value: string): string {
    if (value == null)
      return "";
    return value.charAt(0);
  }
}
