import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../../core';

@Component({
  selector: 'app-page-ribbon',
  template: `
    <div class="ribbon" *ngIf="showRibbon">
      <a href="">{{ ribbonEnv }}</a>
    </div>
  `,
  styleUrls: ['page-ribbon.scss']
})
export class PageRibbonComponent implements OnInit {
  ribbonEnv: string;
  showRibbon: boolean;

  constructor() {
  }

  ngOnInit(): void {
    // this.showRibbon = CommonService.appConfig.env !== 'prod';
    this.showRibbon = false;
    this.ribbonEnv = CommonService.appConfig.env;
  }
}
