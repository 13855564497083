import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckAllComponent} from './pages/check-all/check-all.component';
import {SharedModule} from '../shared/shared.module';
import {CheckComponent} from './pages/check/check.component';
import {ListCheckElementsComponent} from './components/list-check-elements/list-check-elements.component';


@NgModule({
  declarations: [
    CheckAllComponent,
    CheckComponent,
    ListCheckElementsComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [CheckAllComponent, CheckComponent]
})
export class CheckAllModule {
}
