import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Demande} from "../../../modules/demande/model/demande.model";
import {TableReferenceService} from "../../../core/services/table-reference.service";
import {ConstructeurBO} from "../../../core/models/constructeurs.model";
import {ServiceTechniqueBO} from "../../../core/models/servicetechnique.model";
import {DocumentBO} from "../../../core/models/document.model";
import {Notification} from "../../../modules/notification/model/notification.model";
import {DocumentService} from "../../../core/services/document.service";
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import {AlertService} from 'src/app/ui-components';
import {FacesMessageBO} from "../../../core/models/facesmessage.model";
import {UserBO} from "../../../core/models/userbo.model";
import {UserService} from "../../../core/services/user.service";
import {HttpEventType} from "@angular/common/http";
import {Subscription} from "rxjs";
import {CommonService} from "../../../core";

@Component({
  selector: 'app-list-document-form',
  templateUrl: './list-document-form.component.html',
  styleUrls: ['./list-document-form.component.scss'],
  providers: [ConfirmationService, TranslateService]
})
export class ListDocumentFormComponent implements OnInit, OnDestroy  {

  @Input() demande: Demande;
  @Input() notificationBO: Notification;
  @Input() constructeur: ConstructeurBO;
  @Input() serviceTechnique: ServiceTechniqueBO;

  @Output() returnCertificatAValider = new EventEmitter<Notification>();
  @Output() returnConfirmerEnvoiCertificatPapier = new EventEmitter<Notification>();
  @Output() returnCertificatAValiderBlackListVin = new EventEmitter<Notification>();
  @Output() returnConfirmerAjoutCertificat = new EventEmitter<Notification>();
  @Output() returnConfirmerEnvoiManuelEtaes = new EventEmitter<Notification>();
  @Output() returnConfirmerCertificatDisponible = new EventEmitter<Notification>();
  @Output() returnValiderProjetCertificat = new EventEmitter<Notification>();
  @Output() returnConfirmerLectureMail = new EventEmitter<Notification>();
  @Output() returnDocumentListModified = new EventEmitter<DocumentBO[]>();

  documents: DocumentBO[];
  displayDetailDocument: boolean = false;
  selectedDocument: DocumentBO;
  uploadedFiles: File[] = [];
  file: File;
  filename: string;
  fileUploadMaxSize = 1000000000;
  hideButtonBar = true;

  // confirm dialog
  buttonYes: string;
  buttonNo: string;
  areyousure: string;

  user: UserBO = new UserBO();

  private subscriptionName: Subscription;

  constructor(private tableReferenceService: TableReferenceService, private documentService: DocumentService,
              private confirmationService: ConfirmationService, private translateService: TranslateService,
              private userService: UserService, private commonService: CommonService, private alertService: AlertService) {
    this.subscriptionName= this.commonService.getUpdate().subscribe
    (message => {
      this.documents = message;
    });
  }

  ngOnInit(): void {
    if (this.demande != null)
      this.documents = this.demande.documents;

    if (this.notificationBO == null)
      this.notificationBO = new Notification();

    if(this.constructeur != null){
      this.documents = this.constructeur.documents;
    }

    if(this.serviceTechnique != null){
      this.documents = this.serviceTechnique.documents;
    }

    this.userService.getUserBO().subscribe(response =>{
      this.user=response.body;
    });

    this.displayDetailDocument = false;

    // Charge la table de reference typeDocument dans le cache
    if (this.tableReferenceService.apiCache.get("typeDocument") == null) {
      this.tableReferenceService.getReferenceTable("typeDocument").subscribe(response => {
           this.tableReferenceService.apiCache.set("typeDocument", response.body);
      });
    }
    // Charge la table de reference typeDocument INPUT dans le cache
    if (this.tableReferenceService.apiCache.get("typeDocumentI") == null) {
      this.tableReferenceService.getReferenceTable("typeDocumentI").subscribe(response => {
           this.tableReferenceService.apiCache.set("typeDocumentI", response.body);
      });
    }

    this.translateService.get("UI000.areyousure").subscribe(value =>
      this.areyousure = value);
    this.translateService.get("UI000.bouton.yes").subscribe(value =>
      this.buttonYes = value);
    this.translateService.get("UI000.bouton.no").subscribe(value =>
      this.buttonNo = value);

  }
  getLabelForNumber(tablename: string, code: number): string {
    return this.tableReferenceService.apiCache.getLabelForNumber(tablename, code);
  }
  getLabelForString(tablename: string, code: string): string {
    return this.tableReferenceService.apiCache.getLabelForString(tablename, code);
  }
  getReferenceTable(tablename: string) {
    return this.tableReferenceService.apiCache.get(tablename);
  }

  // L'utilisateur a cliqué sur la loupe d'un document de la liste
  displayDocument(document: DocumentBO): void {
    let filename: string = null;
    if(document.originalfilename != null && document.originalfilename.length > 0){
      filename = document.originalfilename;
    }else{
      filename = document.id + "_" + document.typeDocument + "." + document.fileType;
    }
    this.documentService
      .download(document.gedId)
      .subscribe(blob => FileSaver.saveAs(blob, filename));
  }

  // l'utilisateur souhaite supprimer le document
  markDocumentToDelete(event: Event, document: DocumentBO, index: number): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.areyousure,
      acceptLabel: this.buttonYes,
      rejectLabel: this.buttonNo,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        console.log("into markDocumentToDelete index=["+index+"]");
        this.documents.splice(index, 1);
        this.documentService.markDocumentToDelete(document).subscribe(response => {
          // this.commentaires = response.body;
          this.documentService.getDocumentsForDemande(this.demande.id).subscribe(response => {
            this.documents = response.body;
          });
        });
      },
      reject: () => {
        // reject action - nothing to do
      }
    });
  }

  // Traitement de la notification "Certificat à valider". Decision contient "OK" ou "NOK"
  valideCertificat(decision: string): void {
    this.notificationBO.decision = decision;
    // on sauve vers le serveur la liste des documents car il est possible que l'agent ait spécifié
    // l'ordre dans lequel les documents doivent être mergés
    this.notificationBO.documents = this.documents;
    console.log(this.documents);
    this.returnCertificatAValider.emit(this.notificationBO);
  }
  // Traitement de la notification "Imprimer et envoyer le certificat"
  confirmerEnvoiCertificatPapier(): void {
    this.returnConfirmerEnvoiCertificatPapier.emit(this.notificationBO);
  }
  // Traitement de la notification "confirmer que le dossier dont le chassis est en black list peut être envoyé à la signature". Decision contient "OK" ou "NOK"
  valideCertificatBlackListVin(decision: string): void {
    console.log("into list-document-form valideCertificatBlackListVin");
    this.notificationBO.decision = decision;
    // on sauve vers le serveur la liste des documents car il est possible que l'agent ait spécifié
    // l'ordre dans lequel les documents doivent être mergés
    this.notificationBO.documents = this.documents;
    console.log(this.documents);
    this.returnCertificatAValiderBlackListVin.emit(this.notificationBO);
  }
  // Traitement de la notification "Projet de certificat  à ajouter"
  confirmerAjoutCertificat(): void {
    // on sauve vers le serveur la liste des documents car il est possible que l'agent ait spécifié
    // l'ordre dans lequel les documents doivent être mergés
    this.notificationBO.documents = this.documents;
    console.log(this.documents);
    this.returnConfirmerAjoutCertificat.emit(this.notificationBO);
  }
  // Traitement de la notification "Envoi manuel des fichiers ETAES sur la plateforme européenne"
  confirmerEnvoiManuelEtaes(): void {
    this.returnConfirmerEnvoiManuelEtaes.emit(this.notificationBO);
  }
  // Traitement de la notification "certificat disponible"
  certificatDisponibleClose(): void {
    this.returnConfirmerCertificatDisponible.emit(this.notificationBO);
  }
  // Traitement de la notification "Projet de certificat à valider (iFast)". Decision contient "OK" ou "NOK"
  valideProjetCertificat(decision: string): void {
    this.notificationBO.decision = decision;
    this.returnValiderProjetCertificat.emit(this.notificationBO);
  }
  // Traitement de la notification "Mail attaché à la demande"
  confirmerLectureMail(): void {
    this.returnConfirmerLectureMail.emit(this.notificationBO);
  }

  ajouterDocument(context: string): void {
    this.selectedDocument = new DocumentBO();
    this.selectedDocument.todelete = false;
    this.selectedDocument.documentContext = context;
    if (this.demande != null)
      this.selectedDocument.demandeId = this.demande.id;
    if(this.constructeur != null){
      this.selectedDocument.constructeurTierId = this.constructeur.id;
    }
    if(this.serviceTechnique != null){
      this.selectedDocument.serviceTechniqueId = this.serviceTechnique.id;
    }
    this.file = null;
    this.filename = null;
    this.uploadedFiles = [];
    this.displayDetailDocument = true;
  }

  cancelUpload(event: any){
    this.file = null;
    this.filename = null;
    this.uploadedFiles = [];
    this.displayDetailDocument = true;
    this.selectedDocument = new DocumentBO();
    this.displayDetailDocument = false;
  }

  uploadHandler(event: any) {
    //const chunkSize = 500000; // 500KB
    const chunkSize = 20000000; //20MB

    for (let file of event.files) {
      this.file = file;
      this.filename = file.name;
      console.log("FILE TO BE UPLOADED: ", file.name);
      this.uploadedFiles.push(file);
    }
    this.selectedDocument.depotDate = new Date();
    this.selectedDocument.originalfilename = this.filename;
    this.selectedDocument.fileType = this.filename.substring(this.filename.lastIndexOf(".") + 1);
    this.selectedDocument.size = event.files.size;

    let part = 1;
    for( let offset = 0; offset < this.file.size; offset += chunkSize ){
      const splittedFile = this.file.slice( offset, offset + chunkSize );
      const formData = new FormData();
      // check if file size > chunkSize
      if(chunkSize > this.file.size){
        formData.append('file', splittedFile);
       formData.append('referenceFichier', this.filename)
      }else{
        formData.append('file', splittedFile);
        formData.append('referenceFichier', part+'_part_'+this.filename);
      }

      this.documentService.uploadDocument(formData).subscribe(response =>{
      });
      part = part + 1;

    }

    //const formData = new FormData();
    //formData.append('file', this.file, this.file.name);
    // formData.append('referenceFichier', this.filename);

    this.selectedDocument.documentFilenameOrigine = this.filename;
    this.alertService.success(this.filename, "Uploaded.");

  }

  sauverDocument(): void {
    if(this.selectedDocument.typeDocument == null){
      this.alertService.error('UI803.docTypeDocument', 'common.champ.obligatoire');
      return;
    }
    // Save the document in database
    this.documentService.saveDocument(this.selectedDocument).subscribe(response => {
      var returnedDocumentBO: DocumentBO;
      returnedDocumentBO = response.body;
      if (returnedDocumentBO.facesMessageBO.facesMessageElemBOList[0].severity == "SUCCESS") {
        returnedDocumentBO.todelete = false; // au cas ou mais devrait etre deja sur false
        if (this.documents == null)
          this.documents = [];
        this.documents.push(returnedDocumentBO);
        if (this.demande != null)
          this.demande.documents = this.documents;
        if(this.constructeur != null){
          this.constructeur.documents = this.documents;
        }
        if(this.serviceTechnique != null){
          this.serviceTechnique.documents = this.documents;
        }
        if (this.demande != null){
          this.returnDocumentListModified.emit(this.demande.documents);
          this.documentService.getDocumentsForDemande(this.demande.id).subscribe(response => {
            this.documents = response.body;
          });
        }
      }
      else {
            this.displayFacesMessage(returnedDocumentBO.facesMessageBO);
      }
    }, error => {
      this.alertService.error('common.alert.erreur', '99999');
    });

    this.file = null;
    this.filename = null;
    this.uploadedFiles = [];
    this.displayDetailDocument = true;
    this.selectedDocument = new DocumentBO();
    this.displayDetailDocument = false;
  }
  cancelDocument(): void {
    this.file = null;
    this.filename = null;
    this.uploadedFiles = [];
    this.displayDetailDocument = true;
    this.selectedDocument = new DocumentBO();
    this.displayDetailDocument = false;
    // TODO: effacer les fichiers suite au cancelDocument idem dans cancelUpload
  }



  displayFacesMessage(facesMessageBO: FacesMessageBO) {
    if (facesMessageBO != null && facesMessageBO.facesMessageElemBOList != null && facesMessageBO.facesMessageElemBOList.length != 0) {
      for (var i=0; i < facesMessageBO.facesMessageElemBOList.length; i++) {
        if (facesMessageBO.facesMessageElemBOList[i].severity == "SUCCESS") {
          this.alertService.success(facesMessageBO.facesMessageElemBOList[i].field, facesMessageBO.facesMessageElemBOList[i].message);
        }
        if (facesMessageBO.facesMessageElemBOList[i].severity == "INFO") {
          this.alertService.info(facesMessageBO.facesMessageElemBOList[i].field, facesMessageBO.facesMessageElemBOList[i].message);
        }
        if (facesMessageBO.facesMessageElemBOList[i].severity == "WARN") {
          this.alertService.warn(facesMessageBO.facesMessageElemBOList[i].field, facesMessageBO.facesMessageElemBOList[i].message);
        }
        if (facesMessageBO.facesMessageElemBOList[i].severity == "ERROR") {
          this.alertService.error(facesMessageBO.facesMessageElemBOList[i].field, facesMessageBO.facesMessageElemBOList[i].message);
        }
      }
    }
  }

  ngOnDestroy(): void {
      this.subscriptionName.unsubscribe();
  }

}
