<p-panel header="{{'app.title' | translate}}">

  <div style="display: flex; justify-content: space-between">
    <span class="page-title">{{'app.title' | translate}}</span>
    <div style="display: flex;align-items: center;">
      <h4 style="color: #606261;margin-right: 10px;">Statut général :</h4>
      <span [class]="(downCountEnv + downCountRest)==0 ? 'pi pi-check' : 'pi pi-ban'"></span>
    </div>
  </div>

  <p-panel header="{{'common.version' | translate}}">
    <div class="row">
      <div class="col-md-6">
        <ui-label label="{{'check.versionFrontend' | translate}}" [value]="frontVersion"></ui-label>
      </div>
    </div>
  </p-panel>

  <p-panel header="{{'check.contacts' | translate}}">
    <div class="row">
      <div class="col-md-6">
        <ui-label label="{{'check.contactSpw' | translate}}" [value]="contactSPW?.name"></ui-label>
        <ui-label label="{{'common.tel' | translate}}" [value]="contactSPW?.tel"></ui-label>
        <ui-label label="{{'common.email' | translate}}" [value]="contactSPW?.email"></ui-label>
      </div>
      <div class="col-md-6">
        <ui-label label="{{'check.contactPrestataire' | translate}}" [value]="contactPrestataire?.name"></ui-label>
        <ui-label label="{{'common.tel' | translate}}" [value]="contactPrestataire?.tel"></ui-label>
        <ui-label label="{{'common.email' | translate}}" [value]="contactPrestataire?.email"></ui-label>
      </div>
    </div>
  </p-panel>

  <p-panel header="{{'check.composantApi' | translate}}">
    <app-list-check-elements [servicesList]="envServices | async"></app-list-check-elements>
  </p-panel>

  <p-panel header="{{'check.checkRestApis' | translate}}">
    <app-list-check-elements [servicesList]="services | async"></app-list-check-elements>
  </p-panel>

</p-panel>
