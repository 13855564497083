import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {UiComponentsModule} from '../ui-components/ui-components.module';
import {
  CommunicationPipe,
  ErrorComponent,
  FooterComponent,
  HasPermissionDirective,
  HasRoleDirective,
  HeaderComponent,
  PageRibbonComponent,
  RedevableIdentificationPipe,
  StripHtmlPipe,
  TemplateComponent,
  YesOrNoPipe,
  GetFirstLetterVehCatHomologationPipe,
  VehCatHomologationIsL4Pipe,
  DateMaskDirective
} from '.';
import {MenuBarComponent} from './layout/menu-bar/menu-bar.component';
import {ButtonModule} from 'primeng/button';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {FieldsetModule} from 'primeng/fieldset';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {MenuModule} from 'primeng/menu';
import {MultiSelectModule} from 'primeng/multiselect';
import {DividerModule} from 'primeng/divider';
import {TabViewModule} from 'primeng/tabview';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {AccordionModule} from 'primeng/accordion';
import {FileUploadModule} from 'primeng/fileupload';
import {EditorModule} from 'primeng/editor';
import {CalendarModule} from 'primeng/calendar';
import {ToolbarModule} from 'primeng/toolbar';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {RippleModule} from 'primeng/ripple';
import {NgxFilesizeModule} from 'ngx-filesize';
import {StepsModule} from 'primeng/steps';
import {ToastModule} from 'primeng/toast';
import {TreeTableModule} from 'primeng/treetable';
import {StyleClassModule} from 'primeng/styleclass';
import {ListCommentaireFormComponent} from "./components/list-commentaire-form/list-commentaire-form.component";
import {ListDocumentFormComponent} from "./components/list-document-form/list-document-form.component";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {CheckboxModule} from "primeng/checkbox";
import {InputMaskModule} from 'primeng/inputmask';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {ListNewsFormComponent} from "./components/list-news-form/list-news-form.component";

const primeNgModule = [
  StepsModule,
  ButtonModule,
  ToggleButtonModule,
  FieldsetModule,
  TableModule,
  PanelModule,
  MenuModule,
  MultiSelectModule,
  DividerModule,
  TabViewModule,
  CardModule,
  InputTextModule,
  InputTextareaModule,
  AccordionModule,
  FileUploadModule,
  EditorModule,
  CalendarModule,
  ToolbarModule,
  DialogModule,
  DropdownModule,
  RippleModule,
  ToastModule,
  TreeTableModule,
  CheckboxModule,
  InputMaskModule,
  RadioButtonModule,
  ConfirmDialogModule,
  InputNumberModule
];

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TemplateComponent,
    PageRibbonComponent,
    ErrorComponent,
    CommunicationPipe,
    RedevableIdentificationPipe,
    StripHtmlPipe,
    YesOrNoPipe,
    HasRoleDirective,
    HasPermissionDirective,
    MenuBarComponent,
    ListCommentaireFormComponent,
    ListNewsFormComponent,
    ListDocumentFormComponent,
    GetFirstLetterVehCatHomologationPipe,
    VehCatHomologationIsL4Pipe,
    DateMaskDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiComponentsModule,
    primeNgModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxFilesizeModule,
    StyleClassModule,
    ConfirmPopupModule,
    CheckboxModule
  ],
  exports: [TemplateComponent,
    PageRibbonComponent,
    ErrorComponent,
    UiComponentsModule,
    primeNgModule,
    FormsModule,
    ReactiveFormsModule,
    CommunicationPipe,
    RedevableIdentificationPipe,
    StripHtmlPipe,
    YesOrNoPipe,
    TranslateModule,
    HasRoleDirective,
    HasPermissionDirective,
    NgxFilesizeModule,
    ListCommentaireFormComponent,
    ListNewsFormComponent,
    ListDocumentFormComponent,
    GetFirstLetterVehCatHomologationPipe,
    VehCatHomologationIsL4Pipe,
    DateMaskDirective
  ]
})
export class SharedModule {

}

