import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionService} from '../../core/auth/permission.service';


/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the permissions passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *hasPermission="'PERMISSION_ADMINSU'">...</some-element>
 *
 * ```
 */

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective {
  private permission: any;

  constructor(private permissionService: PermissionService,
              private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set hasPermission(value: any) {
    this.permission = value;
    this.updateView();
    // Get notified each time authentication state changes.
  }

  private updateView(): void {
    this.viewContainerRef.clear();
    this.permissionService.hasScopePermission(this.permission.resource, this.permission.scope)
      .subscribe(result => {
        if (result) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      }, error1 => console.log(error1));
  }
}
