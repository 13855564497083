import {Injectable} from "@angular/core";
import {AbstractBusinessService} from "./abstract-business.service";
import {News} from "../models/news.model";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {CommonService} from "./common.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class NewsService extends AbstractBusinessService<News>{

  entityIdParamName: string;
  resourceUrl = CommonService.appConfig.backendUrl + 'news';

  constructor(public httpClient:HttpClient) {
    super(httpClient);
  }

  // juste pour verifier s'il y a des news non lu ou pas
  checkNews(): Observable<HttpResponse<boolean>>{
    return this.httpClient.get<boolean>(`${this.resourceUrl}/checkNews`,
      {observe: 'response'});
  }

  getAllNews(): Observable<HttpResponse<News[]>>{
    return this.httpClient.get<News[]>(`${this.resourceUrl}/getAllNews`,
      {observe: 'response'});
  }

  getPublishedNews(): Observable<HttpResponse<News[]>>{
    return this.httpClient.get<News[]>(`${this.resourceUrl}/getPublishedNews`,
      {observe: 'response'});
  }

  saveNews(news: News) : Observable<HttpResponse<News[]>> {
    return this.httpClient.post<News[]>(`${this.resourceUrl}/saveNews`, news,
      {observe: 'response'});
  }

  publishNews(newsId: number): Observable<HttpResponse<News[]>>{
    return this.httpClient.get<News[]>(`${this.resourceUrl}/publishNews/`+newsId,
      {observe: 'response'});
  }

  readNews(newsId: number): Observable<HttpResponse<News[]>>{
    return this.httpClient.get<News[]>(`${this.resourceUrl}/readNews/`+newsId,
      {observe: 'response'});
  }

  unReadNews(newsId: number): Observable<HttpResponse<News[]>>{
    return this.httpClient.get<News[]>(`${this.resourceUrl}/unReadNews/`+newsId,
      {observe: 'response'});
  }

}
