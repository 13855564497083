import {Pipe, PipeTransform} from '@angular/core';

/*
 * Format a Y/N , y/n , 1/0 String to O/N
*/
@Pipe({name: 'yesOrNo'})
export class YesOrNoPipe implements PipeTransform {
  readonly yesKey = 'O';
  readonly noKey = 'N';

  transform(value: string): string {
    if (value === 'Y' || value === 'y' || value === 'o' || value === '1') {
      return this.yesKey;
    }
    if (value === 'N' || value === 'n' || value === '0') {
      return this.noKey;
    } else {
      return value;
    }
  }
}
