/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.24.612 on 2022-03-22 10:23:16.
// Update : IVI 1.7 - CGT 03/08/2022
// Update : IVI 1.9 & 1.10 - CGT 28/01/2025

export class InitialVehicleInformation {
    header?: Header;
    body?: Body;
    signature?: SignatureType;
}

export class Header {
    versionNumberXsd?: string;
    ivireferenceId?: string;
}

export class Body {
    cocDataGroup?: CocDataGroup;
    technicalAdditionalDataGroup?: TechnicalAdditionalDataGroup;
    nationalDataGroup?: string;
    belgiumDataGroup?: BelgiumDataGroup;
}

export class SignatureType {
    signedInfo?: SignedInfoType;
    signatureValue?: SignatureValueType;
    keyInfo?: KeyInfoType;
    object?: ObjectType[];
    id?: string;
}

export class CocDataGroup {
    vehicleIdentificationNumber?: string;
    baseVin?: string;
    stageOfCompletionCode?: StageOfCompletionCode;
    provisionalApprovalIndicator?: ProvisionalApprovalIndicator;
    typeApprovalTypeCode?: TypeApprovalTypeCode;
    individualApprovalTypeCode?: IndividualApprovalTypeCode;
    productionYear?: number;
    productionSequentialNumber?: number;
    numberOfTheMemberState?: NumberOfTheMemberState;
    type?: string;
    variant?: string;
    version?: string;
    revisionDate?: XMLGregorianCalendar;
    meansOfIdentificationOfType?: string;
    manufacturerPlateLocation?: string;
    manufacturerPlateMethodOfAffix?: string;
    vehicleCategoryCode?: VehicleCategoryCode;
    additionalVehCat23WheelCode?: AdditionalVehCat23WheelCode;
    locOfTheStatutoryPlatesCode?: LocOfTheStatutoryPlatesCode;
    locOfTheStatutoryPlates23Wheel?: string;
    methodOfAttachmStatPlatesCode?: MethodOfAttachmStatPlatesCode;
    locationOfTheVinCode?: LocationOfTheVinCode;
    locationOfTheVinCode23Wheel?: string;
    numericAlphanumIdentifCode?: string;
    completedAlteredCode?: CompletedAlteredCode;
    descriptionOfCompletion?: string;
    typeApprovalNumber?: string;
    typeApprovalDateOfIssue?: XMLGregorianCalendar;
    rightLeftHandTrafficCode?: RightLeftHandTrafficCode;
    metricImperialSpeedometerCode?: MetricImperialSpeedometerCode;
    dateOfApplicationIndividualApp?: XMLGregorianCalendar;
    individualApprovalNumber?: string;
    individualApprovalVersionNr?: number;
    numberOfAxles?: number;
    numberOfWheels?: number;
    numberOfAxlesWithTwinWheels?: number;
    numberOfSteeredAxles?: number;
    numberOfPoweredAxles?: number;
    numberOfBrakedAxles?: number;
    reversibleDrivingPositionInd?: ReversibleDrivingPositionInd;
    wheelbase?: number;
    wheelbaseMinimum?: number;
    wheelbaseMaximum?: number;
    length?: number;
    lengthMinimum?: number;
    lengthMaximum?: number;
    maximumPermissibleLength?: number;
    width?: number;
    widthMinimum?: number;
    widthMaximum?: number;
    maximumPermissibleWidth?: number;
    height?: number;
    heightMinimum?: number;
    heightMaximum?: number;
    maximumPermissibleHeight?: number;
    maxPermPosCOGCompletedVeh?: string;
    lengthOfTheLoadingArea?: number;
    lengthOfTheLoadingAreaMinimum?: number;
    lengthOfTheLoadingAreaMaximum?: number;
    rearOverhang?: number;
    rearOverhangMinimum?: number;
    rearOverhangMaximum?: number;
    maximumPermissibleRearOverhang?: number;
    massOfTheVehicleInRunningOrder?: number;
    actualMassOfTheVehicle?: number;
    unladenMassVehRunningOrderMin?: number;
    unladenMassVehRunningOrderMax?: number;
    unladenMassOfTheVehicle?: number;
    massIncompleteVehRunningOrder?: number;
    minMassVehCompleted?: number;
    technPermMaxLadenMass?: number;
    technPermMaxMassCombination?: number;
    ballastMassTotal?: number;
    ballastMassMaterial?: string;
    ballastMassNumberOfComponents?: number;
    steeringCategoryCode?: SteeringCategoryCode;
    bodyIndicator?: BodyIndicator;
    primaryColourCode?: number;
    secondaryColourCode?: number;
    tankCapacityTankerVehicle?: number;
    numberOfDoors?: number;
    configurationOfDoors?: string;
    frameOrCabMake?: string;
    ecTypeApprovalNrFrameCab?: string;
    positionRollOverHoopCode?: PositionRollOverHoopCode;
    typeOfRollOverHoopCode?: TypeOfRollOverHoopCode;
    makeRollOverHoop?: string;
    ecTypeApprovalNrRollOverHoop?: string;
    nrOfSeatingPositionExclDriver?: number;
    nrOfSeatingPositions?: number;
    positionOfSeats?: string;
    seatForUseOnlyWhenTheVehStat?: number;
    nrOfPassSeatingPosLowerDeck?: number;
    nrOfPassSeatingPosUpperDeck?: number;
    nrOfWheelchairUserAccessPos?: number;
    numberOfStandingPlaces?: number;
    loadPlatformDimensionsLength?: number;
    loadPlatformDimensionsWidth?: number;
    loadPlatformDimensionsHeight?: number;
    loadPlatformTechPermLoad?: number;
    optionalLightSignallingDevices?: string;
    hydrLiftThreePointCouplingInd?: HydrLiftThreePointCouplingInd;
    typeApprTranspDangerGoodsInd?: TypeApprTranspDangerGoodsInd;
    remarks?: string;
    exceedingDimensionsIndicator?: ExceedingDimensionsIndicator;
    exemptions?: string;
    additionalInformation?: string;
    odometerReading?: number;
    odometerReadingUnitCode?: OdometerReadingUnitCode;
    odometerUnitCode?: OdometerUnitCode;
    intendedCountryOfRegistrCode?: IntendedCountryOfRegistrCode;
    versionCoc?: number;
    versionDateIVI?: XMLGregorianCalendar;
    vehicleFittedWithEcoInnovInd?: VehicleFittedWithEcoInnovInd;
    totalCO2EmisSavingDueEcoInnov?: number;
    totalCO2WLTPEmisSavDueEcoInnov?: number;
    fuelTypeCode?: FuelTypeCode;
    allowedEuropeanMarketIndicator?: AllowedEuropeanMarketIndicator;
    actualMassIncomplVehicle?: number;
    maximumSpeedNonPropulsion?: number;
    maximumHourlyPower?: number;
    maximumNetPowerElectricEngine?: number;
    dualFuelType?: DualFuelType;
    advancedBrakingSystemCode?: AdvancedBrakingSystemCode;
    wheelBaseSideCar?: number;
    wheelBaseGroundRatio?: number;
    seatingHeight?: number;
    maximum15MinutesPower?: number;
    maximumAssistanceFactor?: number;
    maximumAssistanceSpeed?: number;
    convertingPerformanceIndic?: ConvertingPerformanceIndic;
    twentyFourGHzRadarInd?: TwentyFourGHzRadarInd;
    offVehicleChargingVehInd?: OffVehicleChargingVehInd;
    indivisibleLoadInd?: IndivisibleLoadInd;
    additionalCouplingPointsCode?: AdditionalCouplingPointsCode;
    descriptServiceAndMaintenance?: string;
    generalDescriptionMachinery?: string;
    maximumPermissibleLengthMin?: number;
    maximumPermissibleLengthMax?: number;
    maximumPermissibleWidthMin?: number;
    maximumPermissibleWidthMax?: number;
    maxPermVertLoadCplPoint?: number;
    numberOfPowerTakeOffs?: number;
    numberOfSetsBallastMasses?: number;
    positionOfPassengersSeats?: string;
    seatingPositionConfigCode?: SeatingPositionConfigCode;
    techPermMaxLadenMassMax?: number;
    techPermMaxLadenMassMin?: number;
    threePointLiftingMechanismCode?: ThreePointLiftingMechanismCode;
    vehicleWithMachineryMountedInd?: VehicleWithMachineryMountedInd;
    vehicleDefinitionCode?: string;
    pureElectricVehIndicator?: PureElectricVehIndicator;
    hybridVehIndicator?: HybridVehIndicator;
    iso7638ConnectorInd?: Iso7638ConnectorInd;
    technPermMaxMassThreePointLift?: number;
    classOfHybridVehicleCode?: ClassOfHybridVehicleCode;
    cryptHashManufacturerRecord?: string;
    zeroEmisVehInd?: ZeroEmisVehInd;
    vocationalVehicleInd?: VocationalVehicleInd;
    averagePayload?: number;
    cryptHashCustomerFile?: string;
    aerodynamicVehCode?: AerodynamicVehCode; // IVI1.7
    addMassAlternativePropulsion?: number; // IVI1.7
    elongatedCabInd?: ElongatedCabInd; // IVI1.7
    automatedVehCode?: AutomatedVehCode; // IVI1.7
    fuelingArrangementCode?: string; // IVI1.7
    dateOfManufactureVeh?: XMLGregorianCalendar; // IVI1.7
    maximumContinuousRatedPowerVeh?: number; // IVI1.7
    electricalControlLineInd?: ElectricalControlLineInd; // IVI1.7
    cryptHashVehicleFile?: string;    // IVI1.8
    averageOccupancy?: number;            // IVI1.8
    vehicleSubgroupGroup?: string;    // IVI1.8
		efficiencyRatioM3Kilometer?: number;  // IVI1.9 & 1.10
		efficiencyRatioTonKilometer?: number;  // IVI1.9 & 1.10
		efficiencyRatioKilometer?: number;  // IVI1.9 & 1.10
		volumeOrientationInd?: string;  // IVI1.9 & 1.10
		cargoVolume?: number;  // IVI1.9 & 1.10
		vehicleGroup?: string;  // IVI1.9 & 1.10
		vehicleCertified156Indicator?: string;  // IVI1.9 & 1.10
		vehicleCertified155Indicator?: string;  // IVI1.9 & 1.10
		additionalMassDueToBatteries?: number;  // IVI1.9 & 1.10
    hybridApplicationGroup?: HybridApplicationGroup;
    brakingTable?: BrakingTable;
    fiscalPowerOrNatCodeNrsTable?: FiscalPowerOrNatCodeNrsTable;
    signingAuthorityTable?: SigningAuthorityTable;
    gearGroup?: GearGroup;
    finalDriveVehTable?: FinalDriveVehTable;
    regulationsTable?: RegulationsTable;
    makeTable?: MakeTable;
    commercialNameTable?: CommercialNameTable;
    stageNrOfManufacturingTable?: StageNrOfManufacturingTable;
    typeApprTranspDangerGoodsTable?: TypeApprTranspDangerGoodsTable;
    bodyworkTable?: BodyworkTable;
    tyreTable?: TyreTable;
    axleTable?: AxleTable;
    axleGroupTable?: AxleGroupTable;
    tyreAxleMassCombinationTable?: TyreAxleMassCombinationTable;
    wltpemissionTestParamGroup?: WLTPEmissionTestParamGroup;
    engineTable?: EngineTable;
    trailerBrakeTable?: TrailerBrakeTable;
    mechanicalCouplingTable?: MechanicalCouplingTable;
    ecoInnovationsTable?: EcoInnovationsTable;
    inServiceMaxMassNatTable?: InServiceMaxMassNatTable;
    inServiceMaxMassIntTable?: InServiceMaxMassIntTable;
    ballastSetTable?: BallastSetTable;
    driverSeatTypeCategoryTable?: DriverSeatTypeCategoryTable;
    loadPlatformTable?: LoadPlatformTable;
    optionalDevicesTable?: OptionalDevicesTable;
    protectHazardSubstCabineGroup?: ProtectHazardSubstCabineGroup;
    protectiveStructureTable?: ProtectiveStructureTable;
    ptoTable?: PtoTable;
    trackSetTable?: TrackSetTable;
    trackTrainGroup?: TrackTrainGroup;
    typeOfChassisGroup?: TypeOfChassisGroup;
    approvalTerritoryTable?: ApprovalTerritoryTable;
    testFamilyIdentifiersTable?: TestFamilyIdentifiersTable;
    allowedParamValMultistageGroup?: AllowedParamValMultistageGroup;  // IVI1.8
    advancedSafetySystemTable?: AdvancedSafetySystemTable;            // IVI1.8
}

export class TechnicalAdditionalDataGroup {
    dateOfProduction?: XMLGregorianCalendar;
    brakeAssistSystemIndicator?: BrakeAssistSystemIndicator;
    protectionPedestriansIndicator?: ProtectionPedestriansIndicator;
    daytimeRunningLightsIndicator?: DaytimeRunningLightsIndicator;
    electronicStabilityProgramInd?: ElectronicStabilityProgramInd;
    tyrePressureMonitoringSystInd?: TyrePressureMonitoringSystInd;
    laneDepartureWarningIndicator?: LaneDepartureWarningIndicator;
    advancEmergencyBrakingSystInd?: AdvancEmergencyBrakingSystInd;
    brakeRetarderIndicator?: BrakeRetarderIndicator;
    pressureChargerInd?: PressureChargerInd;
    interCoolerIndicator?: InterCoolerIndicator;
    catalyticConvertorInd?: CatalyticConvertorInd;
    oxygenSensorInd?: OxygenSensorInd;
    airInjectionInd?: AirInjectionInd;
    exhaustGasRecirculationInd?: ExhaustGasRecirculationInd;
    evaporativeEmisControlSysInd?: EvaporativeEmisControlSysInd;
    particulateTrapInd?: ParticulateTrapInd;
    onBoardDiagnosInd?: OnBoardDiagnosInd;
    antilockBrakeSysInd?: AntilockBrakeSysInd;
    frontAirbagInd?: FrontAirbagInd;
    sideAirbagInd?: SideAirbagInd;
    beltPreloadDeviceInd?: BeltPreloadDeviceInd;
    headAirbagInd?: HeadAirbagInd;
    lowerAirbagInd?: LowerAirbagInd;
    beltForceLimiterInd?: BeltForceLimiterInd;
    rearRegistrationPlateCode?: RearRegistrationPlateCode;
    codeEmissionCategory?: string;
    numberRegistrationCertifPart2?: string;
    remarksExceptions?: string;
    codeOfManufacturer?: number;
    codeOfType?: string;
    codeOfVariantVersion?: string;
    checkDigitCodeOfVariantVersion?: string;
    fuelCodePowerSource?: number;
    lastPossDateOfRegistration?: XMLGregorianCalendar;
    technAddDataGrAxleTable?: TechnAddDataGrAxleTable;
    technAddDataGrFuelTankTable?: TechnAddDataGrFuelTankTable;
}

export class BelgiumDataGroup {
    firstRegistrationDate?: Date;
    firstKnownUseDate?: Date;
    vehicleKind?: string;
    unifier?: number;
    nationalBuildUpCode?: string;
    nationalVehicleConfigurationCode?: string;
    handicapIndicator?: HandicapIndicator;
    counterType?: number;
    limiterIndicator?: LimiterIndicator;
    assistanceWithoutPedallingIndicator?: AssistanceWithoutPedallingIndicator;
    documentId?: string;
    registrationDate?: Date;
    endRegistrationDate?: Date;
    countryIso2?: string;
}

export class SignedInfoType {
    canonicalizationMethod?: CanonicalizationMethodType;
    signatureMethod?: SignatureMethodType;
    reference?: ReferenceType[];
    id?: string;
}

export class SignatureValueType {
    value?: any;
    id?: string;
}

export class KeyInfoType {
    content?: any[];
    id?: string;
}

export class ObjectType {
    content?: any[];
    id?: string;
    mimeType?: string;
    encoding?: string;
}

export class XMLGregorianCalendar implements Cloneable {
}

export class HybridApplicationGroup {
    manufacturerHybridApplication?: string;
    hybridApplicationCode?: string;
    electricVehicleConfigurCode?: ElectricVehicleConfigurCode;
    categoryOfHybridElectricVehInd?: CategoryOfHybridElectricVehInd;
}

export class BrakingTable {
    brakingGroup?: BrakingGroup[];
}

export class FiscalPowerOrNatCodeNrsTable {
    fiscalPowerOrNatCodeNrsGroup?: FiscalPowerOrNatCodeNrsGroup[];
}

export class SigningAuthorityTable {
    signingAuthorityGroup?: SigningAuthorityGroup[];
}

export class GearGroup {
    gearboxTypeCode?: GearboxTypeCode;
    numberOfRatiosFront?: number;
    numberOfRatiosRear?: number;
    gearRatioTable?: GearRatioTable;
}

export class FinalDriveVehTable {
    finalDriveVehGroup?: FinalDriveVehGroup[];
}

export class RegulationsTable {
    regulationsGroup?: RegulationsGroup[];
}

export class MakeTable {
    makeGroup?: MakeGroup[];
}

export class CommercialNameTable {
    commercialNameGroup?: CommercialNameGroup[];
}

export class StageNrOfManufacturingTable {
    stageNrOfManufacturingGroup?: StageNrOfManufacturingGroup[];
}

export class TypeApprTranspDangerGoodsTable {
    typeApprTranspDangerGoodsGroup?: TypeApprTranspDangerGoodsGroup[];
}

export class BodyworkTable {
    bodyworkGroup?: BodyworkGroup[];
}

export class TyreTable {
    tyreGroup?: TyreGroup[];
}

export class AxleTable {
    axleGroup?: AxleGroup[];
}

export class AxleGroupTable {
    axleGroupGroup?: AxleGroupGroup[];
}

export class TyreAxleMassCombinationTable {
    tyreAxleMassCombinationGroup?: TyreAxleMassCombinationGroup[];
}

export class EngineTable {
    engineGroup?: EngineGroup[];
}

export class TrailerBrakeTable {
    trailerBrakeGroup?: TrailerBrakeGroup[];
}

export class MechanicalCouplingTable {
    mechanicalCouplingGroup?: MechanicalCouplingGroup[];
}

export class EcoInnovationsTable {
    ecoInnovationsGroup?: EcoInnovationsGroup[];
}

export class InServiceMaxMassNatTable {
    inServiceMaxMassNatGroup?: InServiceMaxMassNatGroup[];
}

export class InServiceMaxMassIntTable {
    inServiceMaxMassIntGroup?: InServiceMaxMassIntGroup[];
}

export class BallastSetTable {
    ballastSetGroup?: BallastSetGroup[];
}

export class DriverSeatTypeCategoryTable {
    driverSeatTypeCategoryGroup?: DriverSeatTypeCategoryGroup[];
}

export class LoadPlatformTable {
    loadPlatformGroup?: LoadPlatformGroup[];
}

export class OptionalDevicesTable {
    optionalDevicesGroup?: OptionalDevicesGroup[];
}

export class ProtectHazardSubstCabineGroup {
    protectHazardSubstAerisolInd?: ProtectHazardSubstAerisolInd;
    protectHazardSubstDustFiltInd?: ProtectHazardSubstDustFiltInd;
    protectHazardSubstVapFilterInd?: ProtectHazardSubstVapFilterInd;
    protectHazardSubstCabCatTable?: ProtectHazardSubstCabCatTable;
}

export class ProtectiveStructureTable {
    protectiveStructureGroup?: ProtectiveStructureGroup[];
}

export class PtoTable {
    ptoGroup?: PtoGroup[];
}

export class TrackSetTable {
    trackSetGroup?: TrackSetGroup[];
}

export class TrackTrainGroup {
    crawlerUndercarriageConfigCode?: CrawlerUndercarriageConfigCode;
    numberOfBrakedSetOfTrackTrains?: number;
    numberOfPoweredSetOfTrackTrain?: number;
    steerByArtCentrAxisDirTrTrInd?: SteerByArtCentrAxisDirTrTrInd;
    steerByArtCentrAxisTrTrainInd?: SteerByArtCentrAxisTrTrainInd;
    steerByPivotingOpposTracTraInd?: SteerByPivotingOpposTracTraInd;
    steeringBySpeedSideTracTraInd?: SteeringBySpeedSideTracTraInd;
}

export class TypeOfChassisGroup {
    typeOfChassisArticulatedInd?: TypeOfChassisArticulatedInd;
    typeOfChassisBackboneInd?: TypeOfChassisBackboneInd;
    typeOfChassisCentralTubeInd?: TypeOfChassisCentralTubeInd;
    typeOfChassisChWithSideMembInd?: TypeOfChassisChWithSideMembInd;
    typeOfChassisLadderInd?: TypeOfChassisLadderInd;
    typeOfChassisOtherInd?: TypeOfChassisOtherInd;
    typeOfChassisOther?: string;
}

export class ApprovalTerritoryTable {
    approvalTerritoryGroup?: ApprovalTerritoryGroup[];
}

export class TestFamilyIdentifiersTable {
    testFamilyIdentifiersGroup?: TestFamilyIdentifiersGroup[];
}

export class WLTPEmissionTestParamGroup {
    emisTestMassWLTP?: number;
    frontalAreaWLTP?: number;
    roadLoadCoefF0WLTP?: number;
    roadLoadCoefF1WLTP?: number;
    roadLoadCoefF2WLTP?: number;
    projectFrontAreaAirFrontGrill?: number;
    drivingCycleClassCode?: string;
    downscalingFactor?: number;
    cappedSpeedIndicator?: CappedSpeedIndicator;
}

export class TechnAddDataGrAxleTable {
    technAddDataGrAxleGroup?: TechnAddDataGrAxleGroup[];
}

export class TechnAddDataGrFuelTankTable {
    technAddDataGrFuelTankGroup?: TechnAddDataGrFuelTankGroup[];
}

export class CanonicalizationMethodType {
    content?: any[];
    algorithm?: string;
}

export class SignatureMethodType {
    content?: any[];
    algorithm?: string;
}

export class ReferenceType {
    transforms?: TransformsType;
    digestMethod?: DigestMethodType;
    digestValue?: any;
    id?: string;
    uri?: string;
    type?: string;
}

export interface Cloneable {
}

export class BrakingGroup {
    brakingLockingRightLeftInd?: BrakingLockingRightLeftInd;
    brakingTransmissionTechnCode?: BrakingTransmissionTechnCode;
    electronicBrakingSystemCode?: ElectronicBrakingSystemCode;
    typeOfBrakingVehicleCode?: TypeOfBrakingVehicleCode;
    brakingDesc?: string;
    brakingTypeTable?: BrakingTypeTable;
}

export class FiscalPowerOrNatCodeNrsGroup {
    fiscPowOrNatCodeNrsCountryCode?: FiscPowOrNatCodeNrsCountryCode;
    fiscalPowerOrNatCodeNrs?: string;
}

export class SigningAuthorityGroup {
    nameOfSigner?: string;
    positionOfSigner?: string;
    placeOfSignature?: string;
    dateOfSignature?: XMLGregorianCalendar;
}

export class GearRatioTable {
    gearRatioGroup?: GearRatioGroup[];
}

export class FinalDriveVehGroup {
    finalDriveVehNumber?: number;
    overallGearRatioHighGearVeh?: number;
    finalDriveRatioVeh?: number;
}

export class RegulationsGroup {
    regulActInclLastAmendSubjNr?: string;
    regulationAct?: string;
    regulActInclLastAmend?: string;
    regulActInclLastAmendRemark?: string;
    regulActApprovalCode?: RegulActApprovalCode;
}

export class MakeGroup {
    make?: string;
}

export class CommercialNameGroup {
    commercialName?: string;
}

export class StageNrOfManufacturingGroup {
    stageManufacturerNumber?: number;
    stageManufacturerName?: string;
    stageEcTypeApprovalNumber?: string;
    stageDate?: XMLGregorianCalendar;
    stageEcType?: string;
    stageEcVariant?: string;
    stageEcVersion?: string;
    addressTable?: AddressTable;
}

export class TypeApprTranspDangerGoodsGroup {
    typeApprTranspDangerGoodsClass?: string;
}

export class BodyworkGroup {
    codeForBodywork?: CodeForBodywork;
    numberForBodywork?: number;
    codeForBodyworkSpecPurpVeh?: CodeForBodyworkSpecPurpVeh;
    otherBodywork?: string;
    vehicleClassTable?: VehicleClassTable;
}

export class TyreGroup {
    tyreSpecification?: string;
    technPermMaxLadenMassTyreSpec?: number;
}

export class AxleGroup {
    axleNumber?: number;
    twinWheelsAxleInd?: TwinWheelsAxleInd;
    steeredAxleInd?: SteeredAxleInd;
    trackOfEachSteeredAxle?: number;
    poweredAxleInd?: PoweredAxleInd;
    brakedAxleInd?: BrakedAxleInd;
    axleTrack?: number;
    axleTrackMinimum?: number;
    axleTrackMaximum?: number;
    trackOfAllOtherAxles?: number;
    liftAxleInd?: LiftAxleInd;
    loadableAxleInd?: LoadableAxleInd;
    retractableOrLoadableAxleInd?: RetractableOrLoadableAxleInd;
    driveAxleWithAirSuspOrEquivInd?: DriveAxleWithAirSuspOrEquivInd;
    axleWithAirSuspOrEquivInd?: AxleWithAirSuspOrEquivInd;
    axleSpacing?: number;
    axleSpacingMinimum?: number;
    axleSpacingMaximum?: number;
    distrOfMassRunningOrderAxle?: number;
    distribUnladenMassAxle?: number;
    distribMassIncompleteVehAxle?: number;
    distribMassCompletedVehAxleMin?: number;
    technicallyPermMassAxle?: number;
    partOfAxleGroupNumber?: number;
    groundClearanceBetweenAxles?: number;
    distrOfActualMassIncomplAxle?: number;
    distrBallastMassTotalOnAxle?: number;
    technicallyPermMassAxleMax?: number;
    technicallyPermMassAxleMin?: number;
    maxPermLadenMassAxleNatTable?: MaxPermLadenMassAxleNatTable;
    maxPermLadenMassAxleIntTable?: MaxPermLadenMassAxleIntTable;
    interconnWithPoweredAxleTable?: InterconnWithPoweredAxleTable;
    interconnWithBrakedAxleTable?: InterconnWithBrakedAxleTable;
    tyreAxleTable?: TyreAxleTable;
}

export class AxleGroupGroup {
    axleGroupNumber?: number;
    techPermMassAxleGroup?: number;
    maxPermLadenMassAxleGrNatTable?: MaxPermLadenMassAxleGrNatTable;
    maxPermLadenMassAxleGrIntTable?: MaxPermLadenMassAxleGrIntTable;
}

export class TyreAxleMassCombinationGroup {
    tyreAxleCombinationNumber?: number;
    techPermMaxMassTyreComb?: number;
    techPermMxStatVLoadCplPTyrComb?: number;  // IVI1.8
    tyreAxleCouplPtTyreCombTable?: TyreAxleCouplPtTyreCombTable;      // IVI1.8
    tyreAxleCombinationTable?: TyreAxleCombinationTable;
}

export class EngineGroup {
    manufacturerOfTheEngine?: string;
    engineCodeAsMarkedOnTheEngine?: string;
    engineEcTypeApprovalNumber?: string;
    engineNumber?: string;
    identEngineTypeLocation?: string;
    identEngineTypeMethodAffixing?: string;
    workingPrincipleCode?: WorkingPrincipleCode;
    directInjectionIndicator?: DirectInjectionIndicator;
    pureElectricIndicator?: PureElectricIndicator;
    hybridIndicator?: HybridIndicator;
    numberOfCylinders?: number;
    arrangementOfCylindersCode?: ArrangementOfCylindersCode;
    engineCapacity?: number;
    electricEngineIndicator?: ElectricEngineIndicator;
    offVehicleChargingIndicator?: OffVehicleChargingIndicator;
    lpgFuellingSystemIndicator?: LpgFuellingSystemIndicator;
    cngFuellingSystemIndicator?: CngFuellingSystemIndicator;
    engineCycleCode?: string;
    identEngineType?: string;
    locationOfEngineIdentification?: string;
    methodOfAffixEngineIdentNumber?: MethodOfAffixEngineIdentNumber;
    categorySubCatEngine?: CategorySubCatEngine;  // IVI1.7
    engineCapacityMin?: number;        // IVI1.8
    engineCapacityMax?: number;        // IVI1.8
    fuelTable?: FuelTable;
    additionalFuelCodeTable?: AdditionalFuelCodeTable;  // IVI1.7
}

export class TrailerBrakeGroup {
    trailerBrakeConnectionsCode?: string;
    pressFeedLineTwoLineBraking?: number;
    pressFeedLineSingleLineBraking?: number;
    trailerBrakeConnectTypeTable?: TrailerBrakeConnectTypeTable;
}

export class MechanicalCouplingGroup {
    mechanicalCouplingNumber?: number;
    mechnicalCouplingType?: string;
    mechnicalCouplingMake?: string;
    heightCouplingAboveGroundMax?: number;
    heightCouplingAboveGroundMin?: number;
    heightCouplingAboveGround?: number; // IVI1.8
    fifthWheelLead?: number;
    fifthWheelLeadMinimum?: number;
    fifthWheelLeadMaximum?: number;
    distFrontVehCentreCouplDev?: number;
    distFrontVehCentreCouplDevMin?: number;
    distFrontVehCentreCouplDevMax?: number;
    distCentreCouplDevRearVeh?: number;
    distCentreCouplDevRearVehMin?: number;
    distCentreCouplDevRearVehMax?: number;
    distAxisFifthWheelForemost?: number;
    distAxisFifthWheelForemostMin?: number;
    distAxisFifthWheelForemostMax?: number;
    techPermMaxTowMassBrakedTrail?: number;
    techPermMaxTowMassDrawbarTrail?: number;
    techPermMaxTowMassSemiTrailer?: number;
    techPermMaxTowMassCentAxTrail?: number;
    techPermMaxTowMassUnbrTrailer?: number;
    techPermMaxTowableMassTrailer?: number;
    techPermMaxStatVertMassCouplPt?: number;
    techPermMaxStatMassCouplPoint?: number;
    distanceCouplPointFirstAxle?: number;
    distanceCouplPointFirstAxleMin?: number;
    distanceCouplPointFirstAxleMax?: number;
    independBrakedTowableMass?: number;
    inertiaBrakedTowableMass?: number;
    continuousBrakedTowableMass?: number;
    approvalNrCouplingDevice?: string;
    couplCharTechnPermTrailerMass?: number;
    distanceCouplPointLastAxle?: number;
    distanceCouplPointLastAxleMin?: number;
    distanceCouplPointLastAxleMax?: number;
    distrOfMassRunningOrderCoupl?: number;
    distribMassIncompleteVehCoupl?: number;
    distrMassCompletedVehCouplMin?: number;
    distrOfActualMassIncomplCoupl?: number;
    couplingCharacteristicValueD?: number;
    couplingCharacteristicValueDC?: number;
    couplingCharacteristicValueV?: number;
    couplingCharacteristicValueS?: number;
    couplingCharacteristicValueU?: number;
    couplingCharacValueHorizLoad?: number;
    approvalMarkCouplingDevice?: string;
    locationOfTheCouplingCode?: LocationOfTheCouplingCode;
    mechanicalCouplingTypeEu?: string;
    techPermMaxTowMassRigidTrail?: number;  // IVI1.7
    couplingDevicesFittedTable?: CouplingDevicesFittedTable;
    brakedTypeTrailTable?: BrakedTypeTrailTable;
}

export class EcoInnovationsGroup {
    generalCodeOfTheEcoInnovations?: string;
}

export class InServiceMaxMassNatGroup {
    maxPermMassNatTraffCountryCode?: MaxPermMassNatTraffCountryCode;
    maxPermMassNatTraffMemStateNr?: MaxPermMassNatTraffMemStateNr; // IVI1.7
    maxPermLadenMassNational?: number;
    maxPermMassCombinationNational?: number;
}

export class InServiceMaxMassIntGroup {
    maxPermMassIntTrafficRegul?: string;
    maxPermLadenMassInternational?: number;
    maxPermMassCombinationInt?: number;
}

export class BallastSetGroup {
    ballastSetNumber?: number;
    ballastSetMaterial?: string;
    ballastSetMethodConstruction?: string;
    ballastSetNumberOfComponents?: number;
}

export class DriverSeatTypeCategoryGroup {
    driverSeatTypeCategoryCode?: DriverSeatTypeCategoryCode;
    driverSeatTypeCategClassTable?: DriverSeatTypeCategClassTable;
}

export class LoadPlatformGroup {
    loadPlatformHeightAboveGround?: number;
    loadPlatformLength?: number;
    loadPlatformWidth?: number;
    loadPlatformLoadCapacity?: number;
}

export class OptionalDevicesGroup {
    optionalDevice?: string;
}

export class ProtectHazardSubstCabCatTable {
    protectHazardSubstCabCatGroup?: ProtectHazardSubstCabCatGroup[];
}

export class ProtectiveStructureGroup {
    protectiveStructureApplicCode?: ProtectiveStructureApplicCode;
    protectiveStructureCode?: ProtectiveStructureCode;
    protectiveStructureEquipmCode?: ProtectiveStructureEquipmCode;
    protectiveStructureFoldablCode?: ProtectiveStructureFoldablCode;
    protectiveStructureFoldingCode?: ProtectiveStructureFoldingCode;
    protectStructLockingMechnCode?: ProtectStructLockingMechnCode;
    protectiveStructureMake?: string;
    protectStructurePositionCode?: ProtectStructurePositionCode;
    protectiveStructureRopsByCode?: ProtectiveStructureRopsByCode;
    protectStructTypeApprovalNumb?: string;
    protectiveStructFoldAssistCode?: ProtectiveStructFoldAssistCode;
}

export class PtoGroup {
    ptoLocationCode?: PtoLocationCode;
    ptoLocationOtherDescription?: string;
    ptoRevolutions?: number;
    ptoTypeCode?: PtoTypeCode;
    ptoPowerTable?: PtoPowerTable;
}

export class TrackSetGroup {
    trackSetNumber?: number;
    brakedTrackSetInd?: BrakedTrackSetInd;
    poweredTrackSetInd?: PoweredTrackSetInd;
    typeOfMaterialOnTrackShoesCode?: TypeOfMaterialOnTrackShoesCode;
    technicallyPermMassTrackSet?: number;
    technicallyPermMassTrackSetMin?: number;
    technicallyPermMassTrackSetMax?: number;
    trackTrainSetTable?: TrackTrainSetTable;
}

export class ApprovalTerritoryGroup {
    approvalTerritoryCountryCode?: ApprovalTerritoryCountryCode;
}

export class TestFamilyIdentifiersGroup {
    familyIdentifierCode?: FamilyIdentifierCode;
    familyIdentifierValue?: string;
}

export class TechnAddDataGrAxleGroup {
    technAddDataGrAxleNumber?: number;
    pendulumAxleIndicator?: PendulumAxleIndicator;
    selfTrackingAxleIndicator?: SelfTrackingAxleIndicator;
}

export class TechnAddDataGrFuelTankGroup {
    fuelTankSequentialNumber?: number;
    fuelTankFuelCode?: number;
    fuelTankCapacity?: number;
}

export class TransformsType {
    transform?: TransformType[];
}

export class DigestMethodType {
    content?: any[];
    algorithm?: string;
}

export class BrakingTypeTable {
    brakingTypeGroup?: BrakingTypeGroup[];
}

export class GearRatioGroup {
    drivingDirectionCode?: DrivingDirectionCode;
    gearNumber?: number;
    gearRatio?: number;
    finalDriveTable?: FinalDriveTable;
}

export class AddressTable {
    addressGroup?: AddressGroup[];
}

export class VehicleClassTable {
    vehicleClassGroup?: VehicleClassGroup[];
}

export class MaxPermLadenMassAxleNatTable {
    maxPermLadenMassAxleNatGroup?: MaxPermLadenMassAxleNatGroup[];
}

export class MaxPermLadenMassAxleIntTable {
    maxPermLadenMassAxleIntGroup?: MaxPermLadenMassAxleIntGroup[];
}

export class InterconnWithPoweredAxleTable {
    interconnWithPoweredAxleGroup?: InterconnWithPoweredAxleGroup[];
}

export class InterconnWithBrakedAxleTable {
    interconnWithBrakedAxleGroup?: InterconnWithBrakedAxleGroup[];
}

export class TyreAxleTable {
    tyreAxleGroup?: TyreAxleGroup[];
}

export class MaxPermLadenMassAxleGrNatTable {
    maxPermLadenMassAxleGrNatGroup?: MaxPermLadenMassAxleGrNatGroup[];
}

export class MaxPermLadenMassAxleGrIntTable {
    maxPermLadenMassAxleGrIntGroup?: MaxPermLadenMassAxleGrIntGroup[];
}

export class TyreAxleCombinationTable {
    tyreAxleCombinationGroup?: TyreAxleCombinationGroup[];
}

export class TyreAxleCouplPtTyreCombTable {
    tyreAxleCouplPtTyreCombGroup?: TyreAxleCouplPtTyreCombGroup[];
}

export class FuelTable {
    fuelGroup?: FuelGroup[];
}

export class TrailerBrakeConnectTypeTable {
    trailerBrakeConnectTypeGroup?: TrailerBrakeConnectTypeGroup[];
}

export class CouplingDevicesFittedTable {
    couplingDevicesFittedGroup?: CouplingDevicesFittedGroup[];
}

export class BrakedTypeTrailTable {
    brakedTypeTrailGroup?: BrakedTypeTrailGroup[];
}

export class DriverSeatTypeCategClassTable {
    driverSeatTypeCategClassGroup?: DriverSeatTypeCategClassGroup[];
}

export class ProtectHazardSubstCabCatGroup {
    protectHazardSubstCabineCateg?: string;
}

export class PtoPowerTable {
    ptoPowerGroup?: PtoPowerGroup[];
}

export class TrackTrainSetTable {
    trackTrainSetGroup?: TrackTrainSetGroup[];
}

export class TransformType {
    content?: any[];
    algorithm?: string;
}

export class BrakingTypeGroup {
    brakingTypePowerAssistCode?: BrakingTypePowerAssistCode;
    brakingTypePrincipalCode?: BrakingTypePrincipalCode;
}

export class FinalDriveTable {
    finalDriveGroup?: FinalDriveGroup[];
}

export class AddressGroup {
    addressTypeCode?: AddressTypeCode;
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    placeOfResidence?: string;
    countryOfResidence?: string;
    phoneNumber?: string;
    emailAddress?: string;
}

export class VehicleClassGroup {
    classOfVehicleCode?: ClassOfVehicleCode;
}

export class MaxPermLadenMassAxleNatGroup {
    maxPermLadenMassAxleCountrCode?: MaxPermLadenMassAxleCountrCode;
    maxPermLadenMassAxleMemStateNr?: MaxPermLadenMassAxleMemStateNr;  // IVI1.7
    maxPermLadenMassAxleNational?: number;
}

export class MaxPermLadenMassAxleIntGroup {
    maxPermLadenMassTrafficRegul?: string;
    maxPermLadenMassAxleInt?: number;
}

export class InterconnWithPoweredAxleGroup {
    interconnWithPoweredAxleNumber?: number;
    interconnOfPoweredAxles?: string;
}

export class InterconnWithBrakedAxleGroup {
    interconnWithBrakedAxleNumber?: number;
    interconnOfBrakedAxle?: string;
}

export class TyreAxleGroup {
    tyreNumber?: number;
    distrMaxLadenMassTyreAxleSpec?: number;
    technPermisMaxMassAxle?: number;
    distrTechnPermisMassAxle?: number;
    techPermMaxStatVertLoadCouplPt?: number;
    tyreSize?: string;
    loadCapacityIndexSingleWheel?: number;
    loadCapacityIndexTwinWheel?: number;
    speedCategorySymbol?: string;
    typeOfTyre?: TypeOfTyre;
    rimSizeIncludingOffSet?: string;
    tyreTubeTypeCode?: TyreTubeTypeCode;
    tyreMaxSpeedIndicator?: TyreMaxSpeedIndicator;
    tyrePlyRatingNumber?: number;
    tyreClassificationCode?: TyreClassificationCode;
    tyreRemarks?: string;
    rollingRadiusTyre?: number;
    technPermisMaxMassVehAxle?: number;
    tyreLoadRatingTyre?: number;
    tyreAxleTrackMinimum?: number;
    tyreAxleTrackMaximum?: number;
    tyreAxleTrack?: number;
    rollingResistanceClassCode?: RollingResistanceClassCode;
    productionTyreRimVehInd?: ProductionTyreRimVehInd;
    co2DeterminationTyreInd?: CO2DeterminationTyreInd;
    tyreCategoryCode?: TyreCategoryCode;
    tyreAxlePressureTable?: TyreAxlePressureTable;
    tyreAxleCouplPtTable?: TyreAxleCouplPtTable;
}

export class MaxPermLadenMassAxleGrNatGroup {
    maxPermLadenMassAxleGrCCode?: MaxPermLadenMassAxleGrCCode;
    maxPermLadenMassAxleGrMStateNr?: MaxPermLadenMassAxleGrMStateNr;  // IVI1.7
    maxPermLadenMassAxleGrNat?: number;
}

export class MaxPermLadenMassAxleGrIntGroup {
    maxPermLadenMassGrTrafficRegul?: string;
    maxPermLadenMassAxleGrInt?: number;
}

export class TyreAxleCombinationGroup {
    axleNumberCombination?: number;
    tyreNumberCombination?: number;
}

export class TyreAxleCouplPtTyreCombGroup {
    mechCouplNumTyreAxleTyreComb?: number;
    tecPerMxStVLdCplPtTyrAxTyrComb?: number;
}

export class FuelGroup {
    fuelCode?: number;
    maximumNetPower?: number;
    engineSpeedMaximumNetPower?: number;
    engineSpeedMaximumNetPowerMin?: number;
    engineSpeedMaximumNetPowerMax?: number;
    maximumContinuousRatedPower?: number;
    powerMassRatio?: number;
    powerPowerTakeOff?: number;
    engineSpeedPowerPowerTakeOff?: number;
    maxPercentBiofuelAcceptInFuel?: number;
    calculatedMaximumSpeed?: number;
    maximumSpeed?: number;
    extSoundLevelNrBaseRegulAct?: string;
    soundLevelStationary?: number;
    soundLevelStatEngineSpeed?: number;
    soundLevelDriveBy?: number;
    driverPercSoundLevNrBaseRegAct?: string;
    driverPerceivedSoundLevel?: number;
    exhaustEmissionLevelEuro?: string;
    otherEmissionLegislation?: string;
    nrBaseRegulActLastAmendMotVeh?: string;
    nrBaseRegulActLastAmendEngines?: string;
    smokeCorrectedAbsorptionCoeff?: number;
    urbanConditionsCO2?: number;
    urbanConditionsFuelConsumption?: number;
    extraUrbanConditionsCO2?: number;
    extraUrbanConditionsFuelCons?: number;
    combinedCO2?: number;
    combinedFuelConsumption?: number;
    weightedCombinedCO2?: number;
    weightedCombinedFuelCons?: number;
    combinedCO2ConditionA?: number;
    combinedCO2ConditionB?: number;
    combinedFuelConsConditionA?: number;
    combinedFuelConsConditionB?: number;
    electricEnergyConsConditionA?: number;
    electricEnergyConsConditionB?: number;
    electricEnergyConsPureElectric?: number;
    electricEnergyConsWeightedComb?: number;
    electricRange?: number;
    electricRangeExternChargeable?: number;
    totalCO2EmisSavDueEcoInnFuel?: number;
    maxHourlyPowerFuel?: number;
    maxNetPowerElectricEngFuel?: number;
    max15MinutesPowerFuel?: number;
    driverPercSoundLevTestMethod?: string;
    limitValueSoundLUrban?: number;
    engineSpeedRatedEngineNetPower?: number;
    maximumSpeedRearward?: number;
    ratedEngineNetPower?: number;
    declaredMaximumSpeed?: number;
    declaredMaximumSpeedRearward?: number;
    totalCO2WLTPEmSavDueEcoInnFuel?: number;
    specificCO2Emissions?: number;
    driverPercSoundLevelOpenNoCabe?: number;  // IVI1.7
    subFuelType?: SubFuelType; // IVI1.7
    specificCO2EmissionsPerson?: number;  // IVI1.8
    testprocedureType1Group?: TestprocedureType1Group;
    testprocedureType2Group?: TestprocedureType2Group;
    testprocedureType5Group?: TestprocedureType5Group;
    testprocTypeVIIGroup?: TestprocTypeVIIGroup;
    testprocedureEscGroup?: TestprocedureEscGroup;
    testprocedureNrscGroup?: TestprocedureNrscGroup;
    testprocedureWhscGroup?: TestprocedureWhscGroup;
    testprocedureElrGroup?: TestprocedureElrGroup;
    testprocedureEtcGroup?: TestprocedureEtcGroup;
    testprocedureNrtcGroup?: TestprocedureNrtcGroup;
    testprocedureWhtcGroup?: TestprocedureWhtcGroup;
    testprocedureRdeGroup?: TestprocedureRdeGroup;
    testprocedureWLTPType1Group?: TestprocedureWLTPType1Group;
    testprocedureWLTPCO2Group?: TestprocedureWLTPCO2Group;
    testprocedureWLTPFuelGroup?: TestprocedureWLTPFuelGroup;
    testprocedureWLTPEnergyGroup?: TestprocedureWLTPEnergyGroup;
    nedcdeviationFactor?: number;
    nedcverificationFactor?: number;
}

export class TrailerBrakeConnectTypeGroup {
    pressFeedLineBraking?: number;
    trailerBrakeConnectTechCode?: TrailerBrakeConnectTechCode;
    trailerBrakeConnectTypeCode?: string;
}

export class CouplingDevicesFittedGroup {
    typeOfCouplingDeviceFitted?: string;
}

export class BrakedTypeTrailGroup {
    brakedTypeTrailCode?: BrakedTypeTrailCode;
    drawbarTypeTable?: DrawbarTypeTable;
}

export class DriverSeatTypeCategClassGroup {
    driverSeatTypeCategClassCode?: DriverSeatTypeCategClassCode;
}

export class PtoPowerGroup {
    ptoPower?: number;
    ptoCorrespondingEngineSpeed?: number;
    ptoRatedSpeedRange?: string;
}

export class TrackTrainSetGroup {
    maximumPermissibleMassTrackSet?: number;
    technPermisMaxMassVehTrackSet?: number;
    trackContactGroundPressure?: number;
    trackLength?: number;
    trackWidth?: number;
    trackRollerTable?: TrackRollerTable;
    trackTrainCouplPtTable?: TrackTrainCouplPtTable;
}

export class FinalDriveGroup {
    finalDriveNumber?: number;
    finalDriveRatio?: number;
    overallGearRatioHighestGear?: number;
}

export class TyreAxlePressureTable {
    tyreAxlePressureGroup?: TyreAxlePressureGroup[];
}

export class TyreAxleCouplPtTable {
    tyreAxleCouplPtGroup?: TyreAxleCouplPtGroup[];
}

export class TestprocedureType1Group {
    testprocType1CO?: number;
    testprocType1HC?: number;
    testprocType1NOx?: number;
    testprocType1NMHC?: number;
    testprocType1HCNOx?: number;
    testprocType1Particulates?: number;
    testprocType1NrOfParticles?: number;
    testprocType1ExponentParticles?: number;
}

export class TestprocedureType2Group {
    testprocType2CO?: number;
    testprocType2HC?: number;
    testprocType2COAtNormIdleSp?: number;
    testprocType2EngSpNormalMin?: number;
    testprocType2EngSpNormalMax?: number;
    testprocType2COAtHighIdleSp?: number;
    testprocType2EngSpHighIdleMin?: number;
    testprocType2EngSpHighIdleMax?: number;
    testprocType2HCAtNormIdleSp?: number;
    testprocType2HCAtHighIdleSp?: number;
}

export class TestprocedureType5Group {
    testprocType5CO?: number;
    testprocType5THC?: number;
    testprocType5NMHC?: number;
    testprocType5NOx?: number;
    testprocType5HCNOx?: number;
    testprocType5Particulates?: number;
}

export class TestprocTypeVIIGroup {
    testprocTypeVIICO2?: number;
    testprocTypeVIIFuelConsumption?: number;
}

export class TestprocedureEscGroup {
    testprocEscCO?: number;
    testprocEscTHC?: number;
    testprocEscNOx?: number;
    testprocEscParticulates?: number;
    testprocEscNumberOfParticles?: number;
    testprocEscExponentParticles?: number;
    testprocEscCO2?: number;
    testprocEscStage?: string;
}

export class TestprocedureNrscGroup {
    testprocNrscCO?: number;
    testprocNrscHC?: number;
    testprocNrscNOx?: number;
    testprocNrscNMHCNOx?: number;
    testprocNrscParticulates?: number;
    testprocNrscNumberOfParticles?: number;
    testprocNrscExponentParticles?: number;
    testprocNrscCO2?: number;
    testprocNrscHCNOx?: number;
    testprocNrscStage?: string;
    testCycleNRSC?: TestCycleNRSC; // IVI1.7
}

export class TestprocedureWhscGroup {
    testprocWhscCO?: number;
    testprocWhscTHC?: number;
    testprocWhscNOx?: number;
    testprocWhscNMHC?: number;
    testprocWhscCH4?: number;
    testprocWhscNH3?: number;
    testprocWhscParticulates?: number;
    testprocWhscNumberOfParticles?: number;
    testprocWhscExponentParticles?: number;
    testprocWhscCO2?: number;
    testprocWhscHCNOx?: number;
    testprocWhscStage?: string;
}

export class TestprocedureElrGroup {
    testprocElrSmokeValue?: number;
}

export class TestprocedureEtcGroup {
    testprocEtcCO?: number;
    testprocEtcNOx?: number;
    testprocEtcNMHC?: number;
    testprocEtcTHC?: number;
    testprocEtcCH4?: number;
    testprocEtcParticulates?: number;
    testprocEtcNumberOfParticles?: number;
    testprocEtcExponentParticles?: number;
    testprocEtcCO2?: number;
    testprocEtcHCNOx?: number;
    testprocEtcStage?: string;
}

export class TestprocedureNrtcGroup {
    testprocNrtcCO?: number;
    testprocNrtcNOx?: number;
    testprocNrtcNMHC?: number;
    testprocNrtcNMHCNOx?: number;
    testprocNrtcTHC?: number;
    testprocNrtcCH4?: number;
    testprocNrtcParticulates?: number;
    testprocNrtcNumberOfParticles?: number;
    testprocNrtcExponentParticles?: number;
    testprocNrtcCO2?: number;
    testprocNrtcHCNOx?: number;
    testprocNrtcHotCycleCO2?: number;
    testprocNrtcHotCycleWork?: number;
    testprocNrtcHCWorkHotStNonReg?: number;
    testprocNrtcStage?: string;
    testCycleNRTC?: TestCycleNRTC; // IVI1.7
}

export class TestprocedureWhtcGroup {
    testprocWhtcCO?: number;
    testprocWhtcNOx?: number;
    testprocWhtcNMHC?: number;
    testprocWhtcTHC?: number;
    testprocWhtcCH4?: number;
    testprocWhtcNH3?: number;
    testprocWhtcParticulates?: number;
    testprocWhtcNumberOfParticles?: number;
    testprocWhtcExponentParticles?: number;
    testprocWhtcHotCycleCO2?: number;
    testprocWhtcCO2?: number;
    testprocWhtcHCNOx?: number;
    testprocWhtcHotCycleWork?: number;
    testprocWhtcHCWorkHostStNonReg?: number;
    testprocWhtcStage?: string;
}

export class TestprocedureRdeGroup {
    testprocRdeCompleteRdeNOx?: number;
    testprocRdeCompleteRdeNrOfPart?: number;
    testprocRdeCompleteRdeExpPart?: number;
    testprocRdeUrbanRdeNOx?: number;
    testprocRdeUrbanRdeNrOfPart?: number;
    testprocRdeUrbanRdeExpPart?: number;
}

export class TestprocedureWLTPType1Group {
    testprocWltpType1CO?: number;
    testprocWltpType1THC?: number;
    testprocWltpType1NMHC?: number;
    testprocWltpType1NOx?: number;
    testprocWltpType1THCNOx?: number;
    testprocWltpType1NH3?: number;
    testprocWltpType1Particulates?: number;
    testprocWltpType1NrOfPart?: number;
    testprocWltpType1ExPart?: number;
}

export class TestprocedureWLTPCO2Group {
    wltpweightedCombinedCO2?: number;
    wltpextraHighCO2?: number;
    wltphighCO2?: number;
    wltplowCO2?: number;
    wltpmediumCO2?: number;
    wltpcombinedCO2?: number;
    wltpLowCO2ChargeSust?: number;  // IVI1.8
    wltpMediumCO2ChargeSust?: number;  // IVI1.8
    wltpHighCO2ChargeSust?: number;  // IVI1.8
    wltpExtraHighCO2ChargeSust?: number;  // IVI1.8
    wltpCombinedCO2ChargeSust?: number;  // IVI1.8
    wltpCombinedCO2ChargeDepl?: number;  // IVI1.8
}

export class TestprocedureWLTPFuelGroup {
    wltplowFuelConsumption?: number;
    wltphighFuelConsumption?: number;
    wltpmediumFuelConsumption?: number;
    wltpextraHighFuelConsumption?: number;
    wltpcombinedFuelCons?: number;
    wltpweightedCombinedFuelCons?: number;
    wltpLowFuelConsChargeSust?: number;   // IVI1.8
    wltpMediumFuelConsChargeSust?: number;   // IVI1.8
    wltpHighFuelConsChargeSust?: number;   // IVI1.8
    wltpExtraHighFuelConsChargSust?: number;   // IVI1.8
    wltpCombinedFuelConsChargeSust?: number;   // IVI1.8
    wltpCombinedFuelConsChargeDepl?: number;   // IVI1.8
}

export class TestprocedureWLTPEnergyGroup {
    wltpelectricRangeCityExtCharg?: number;
    wltpelectEnergyConsExternCharg?: number;
    wltpelectricRangeExternCharg?: number;
    wltpelectricRangeCityPureElec?: number;
    wltpelecEnergyConsPureElectric?: number;
    wltpelectricRangePureElectric?: number;
    wltpLowElecEnergyConsPureElec?: number;   // IVI1.8
    wltpMediumElEnergyConsPureElec?: number;   // IVI1.8
    wltpHighElecEnergyConsPureElec?: number;   // IVI1.8
    wltpExtrHighElecEnConsPureElec?: number;   // IVI1.8
    wltpLowElecEnergyConsExtCharg?: number;   // IVI1.8
    wltpMediumElEnergyConsExtCharg?: number;   // IVI1.8
    wltpHighElEnergyConsExtCharg?: number;   // IVI1.8
    wltpExtrHighElecEnConsExtCharg?: number;   // IVI1.8
    wltpCityElecEnergyConsExtCharg?: number;   // IVI1.8
    wltpCombElEnergyConsExtCharg?: number;   // IVI1.8
    wltpAllElecRangeExternCharg?: number;   // IVI1.8
    wltpAllElecRangeCityExtCharg?: number;   // IVI1.8
}

export class AdditionalFuelCodeTable {  // IVI1.7
    additionalFuelCodeGroup?: AdditionalFuelCodeGroup[];
}

export class AdditionalFuelCodeGroup {  // IVI1.7
    additionalFuelCode?: number;
}

export class DrawbarTypeTable {
    drawbarTypeGroup?: DrawbarTypeGroup[];
}

export class TrackRollerTable {
    trackRollerGroup?: TrackRollerGroup[];
}

export class TrackTrainCouplPtTable {
    trackTrainCouplPtGroup?: TrackTrainCouplPtGroup[];
}

export class TyreAxlePressureGroup {
    recomTyrePressureLoadCode?: RecomTyrePressureLoadCode;
    recommendedTyrePressure?: number;
    recomTyrePressurePascal?: number;
    recomTyrePressurePascalMin?: number;
    recomTyrePressurePascalMax?: number;
    recomTyrePressureUseCode?: RecomTyrePressureUseCode;
}

export class TyreAxleCouplPtGroup {
    approvalNumberCouplPtTyreAxle?: string;
    tecPerMaxStVertLoadCoupPtTyrAx?: number;
}

export class DrawbarTypeGroup {
    typeTrailerCode?: TypeTrailerCode;
    technPermMaxMassCombinTrail?: number;
    technPermMaxMassCombinTrailMax?: number;
    technPermMaxMassCombinTrailMin?: number;
    techPermMaxTowMassTrailer?: number;
    techPermMaxTowMassTrailerMax?: number;
    techPermMaxTowMassTrailerMin?: number;
}

export class TrackRollerGroup {
    trackRollerNumber?: number;
    trackRollerMaximumload?: number;
}

export class TrackTrainCouplPtGroup {
    approvalNrCouplPtTrackTrainSet?: string;
    tpmstatVertLoadCouplPtTrTraSet?: number;
}

export class AllowedParamValMultistageGroup { // IVI1.8
    permActualMassOfTheVehicle?: number;
    minPermActualMassOfTheVehicle?: number;
    maxPermActualMassOfTheVehicle?: number;
    permTechnPermMaxLadenMass?: number;
    minPermTechnPermMaxLadenMass?: number;
    maxPermTechnPermMaxLadenMass?: number;
    permissibleFrontalArea?: number;
    minimumPermissibleFrontalArea?: number;
    maximumPermissibleFrontalArea?: number;
    permissibleRollingResistance?: number;
    minimumPermRollingResistance?: number;
    maximumPermRollingResistance?: number;
    permProjFrontAreaAirFrontGrill?: number;
    minPermProjFrntArAirFrntGrill?: number;
    maxPermProjFrntArAirFrntGrill?: number;
}

export class AdvancedSafetySystemTable {  // IVI1.8
    advancedSafetySystemGroup?: AdvancedSafetySystemGroup[];
}

export class AdvancedSafetySystemGroup {  // IVI1.8
    advancedSafetySystemCode?: AdvancedSafetySystemCode;
}

export enum AdvancedSafetySystemCode {  // IVI1.8
    AEBS = "AEBS",
    ELKS = "ELKS",
    ISA = "ISA",
    DDAW = "DDAW",
    EDR = "EDR",
    AIF = "AIF",
    ESS = "ESS",
    TPMS = "TPMS",
    ADDW = "ADDW",
    DAM = "DAM",
    ADS = "ADS",
    eCall = "eCall",
    BSIS = "BSIS",
    Platooning = "Platooning",
    CDCF = "CDCF",
    LDWS = "LDWS",
}

export enum StageOfCompletionCode {
    C = "C",
    I = "I",
    V = "V",
}

export enum ProvisionalApprovalIndicator {
    Y = "Y",
    N = "N",
}

export enum TypeApprovalTypeCode {
    NAT = "NAT",
    NKS = "NKS",
    KS = "KS",
    EC = "EC",
    IND = "IND",
}

export enum IndividualApprovalTypeCode {
    B = "B",
    H = "H",
    N = "N",
}

export enum NumberOfTheMemberState {
    E_1 = "E_1",
    E_2 = "E_2",
    E_3 = "E_3",
    E_4 = "E_4",
    E_5 = "E_5",
    E_6 = "E_6",
    E_7 = "E_7",
    E_8 = "E_8",
    E_9 = "E_9",
    E_11 = "E_11",
    E_12 = "E_12",
    E_13 = "E_13",
    E_17 = "E_17",
    E_18 = "E_18",
    E_19 = "E_19",
    E_20 = "E_20",
    E_21 = "E_21",
    E_23 = "E_23",
    E_24 = "E_24",
    E_25 = "E_25",
    E_26 = "E_26",
    E_27 = "E_27",
    E_29 = "E_29",
    E_32 = "E_32",
    E_34 = "E_34",
    E_36 = "E_36",
    E_49 = "E_49",
    E_50 = "E_50",
}

export enum VehicleCategoryCode {
    C_1 = <any>"C1",
    C_1_A = <any>"C1a",
    C_1_B = <any>"C1b",
    C_2 = <any>"C2",
    C_2_A = <any>"C2a",
    C_2_B = <any>"C2b",
    C_3 = <any>"C3",
    C_3_A = <any>"C3a",
    C_3_B = <any>"C3b",
    C_4_1 = <any>"C4.1",
    C_4_1_A = <any>"C4.1a",
    C_4_1_B = <any>"C4.1b",
    C_4_2 = <any>"C4.2",
    C_4_2_A = <any>"C4.2a",
    C_4_2_B = <any>"C4.2b",
    C_4_3 = <any>"C4.3",
    C_4_3_A = <any>"C4.3a",
    C_4_3_B = <any>"C4.3b",
    C_5 = <any>"C5",
    L_1_E = <any>"L1e",
    L_1_E_A = <any>"L1e-A",
    L_1_E_B = <any>"L1e-B",
    L_2_E = <any>"L2e",
    L_2_E_P = <any>"L2e-P",
    L_2_E_U = <any>"L2e-U",
    L_3_E = <any>"L3e",
    L_3_E_A_1 = <any>"L3e-A1",
    L_3_E_A_1_E = <any>"L3e-A1E",
    L_3_E_A_1_T = <any>"L3e-A1T",
    L_3_E_A_2 = <any>"L3e-A2",
    L_3_E_A_2_E = <any>"L3e-A2E",
    L_3_E_A_2_T = <any>"L3e-A2T",
    L_3_E_A_3 = <any>"L3e-A3",
    L_3_E_A_3_E = <any>"L3e-A3E",
    L_3_E_A_3_T = <any>"L3e-A3T",
    L_4_E = <any>"L4e",
    L_4_E_A_1 = <any>"L4e-A1",
    L_4_E_A_2 = <any>"L4e-A2",
    L_4_E_A_3 = <any>"L4e-A3",
    L_5_E = <any>"L5e",
    L_5_E_A = <any>"L5e-A",
    L_5_E_B = <any>"L5e-B",
    L_6_E = <any>"L6e",
    L_6_E_A = <any>"L6e-A",
    L_6_E_BP = <any>"L6e-BP",
    L_6_E_BU = <any>"L6e-BU",
    L_7_E = <any>"L7e",
    L_7_E_A_1 = <any>"L7e-A1",
    L_7_E_A_2 = <any>"L7e-A2",
    L_7_E_B_1 = <any>"L7e-B1",
    L_7_E_B_2 = <any>"L7e-B2",
    L_7_E_CP = <any>"L7e-CP",
    L_7_E_CU = <any>"L7e-CU",
    M_1 = <any>"M1",
    M_1_G = <any>"M1G",
    M_1_GS = <any>"M1GS",
    M_1_S = <any>"M1S",
    M_2 = <any>"M2",
    M_2_G = <any>"M2G",
    M_2_GS = <any>"M2GS",
    M_2_S = <any>"M2S",
    M_3 = <any>"M3",
    M_3_G = <any>"M3G",
    M_3_GS = <any>"M3GS",
    M_3_S = <any>"M3S",
    N_1 = <any>"N1",
    N_1_G = <any>"N1G",
    N_1_GS = <any>"N1GS",
    N_1_S = <any>"N1S",
    N_2 = <any>"N2",
    N_2_G = <any>"N2G",
    N_2_GS = <any>"N2GS",
    N_2_S = <any>"N2S",
    N_3 = <any>"N3",
    N_3_G = <any>"N3G",
    N_3_GS = <any>"N3GS",
    N_3_S = <any>"N3S",
    O_1 = <any>"O1",
    O_1_S = <any>"O1S",
    O_2 = <any>"O2",
    O_2_S = <any>"O2S",
    O_3 = <any>"O3",
    O_3_S = <any>"O3S",
    O_4 = <any>"O4",
    O_4_S = <any>"O4S",
    R_1_A = <any>"R1a",
    R_1_B = <any>"R1b",
    R_2_A = <any>"R2a",
    R_2_B = <any>"R2b",
    R_3_A = <any>"R3a",
    R_3_B = <any>"R3b",
    R_4_A = <any>"R4a",
    R_4_B = <any>"R4b",
    S_1_A = <any>"S1a",
    S_1_B = <any>"S1b",
    S_2_A = <any>"S2a",
    S_2_B = <any>"S2b",
    T_1 = <any>"T1",
    T_1_A = <any>"T1a",
    T_1_B = <any>"T1b",
    T_2 = <any>"T2",
    T_2_A = <any>"T2a",
    T_2_B = <any>"T2b",
    T_3 = <any>"T3",
    T_3_A = <any>"T3a",
    T_3_B = <any>"T3b",
    T_4_1 = <any>"T4.1",
    T_4_1_A = <any>"T4.1a",
    T_4_1_B = <any>"T4.1b",
    T_4_2 = <any>"T4.2",
    T_4_2_A = <any>"T4.2a",
    T_4_2_B = <any>"T4.2b",
    T_4_3 = <any>"T4.3",
    T_4_3_A = <any>"T4.3a",
    T_4_3_B = <any>"T4.3b",
    T_5 = <any>"T5",
}

export enum AdditionalVehCat23WheelCode {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
}

export enum LocOfTheStatutoryPlatesCode {
    A_0 = <any>"A0",
    A_1 = <any>"A1",
    A_2 = <any>"A2",
    A_3 = <any>"A3",
    A_4 = <any>"A4",
    A_5 = <any>"A5",
    A_6 = <any>"A6",
    A_7 = <any>"A7",
    A_8 = <any>"A8",
    A_9 = <any>"A9",
    B_0 = <any>"B0",
    B_1 = <any>"B1",
    B_2 = <any>"B2",
    B_3 = <any>"B3",
    B_4 = <any>"B4",
    B_5 = <any>"B5",
    B_6 = <any>"B6",
    B_7 = <any>"B7",
    B_8 = <any>"B8",
    B_9 = <any>"B9",
    C_0 = <any>"C0",
    C_1 = <any>"C1",
    D_4 = <any>"D4",
    D_5 = <any>"D5",
    D_7 = <any>"D7",
    D_8 = <any>"D8",
    E_1 = <any>"E1",
    E_2 = <any>"E2",
    E_3 = <any>"E3",
    E_4 = <any>"E4",
    E_5 = <any>"E5",
    E_6 = <any>"E6",
    E_7 = <any>"E7",
    E_8 = <any>"E8",
    E_9 = <any>"E9",
    F_0 = <any>"F0",
    F_1 = <any>"F1",
    F_2 = <any>"F2",
    F_3 = <any>"F3",
    F_4 = <any>"F4",
    F_5 = <any>"F5",
    F_6 = <any>"F6",
    F_7 = <any>"F7",
    F_8 = <any>"F8",
    F_9 = <any>"F9",
    G_1 = <any>"G1",
    G_2 = <any>"G2",
    G_3 = <any>"G3",
    G_4 = <any>"G4",
    G_5 = <any>"G5",
    G_6 = <any>"G6",
    G_7 = <any>"G7",
    G_8 = <any>"G8",
    G_9 = <any>"G9",
    H_0 = <any>"H0",
    H_1 = <any>"H1",
    H_2 = <any>"H2",
    H_3 = <any>"H3",
    H_4 = <any>"H4",
    H_5 = <any>"H5",
    H_6 = <any>"H6",
    H_7 = <any>"H7",
    H_8 = <any>"H8",
    H_9 = <any>"H9",
    I_0 = <any>"I0",
    I_1 = <any>"I1",
    I_2 = <any>"I2",
    I_3 = <any>"I3",
    I_4 = <any>"I4",
    I_5 = <any>"I5",
    I_6 = <any>"I6",
    I_7 = <any>"I7",
    I_8 = <any>"I8",
    I_9 = <any>"I9",
    J_0 = <any>"J0",
    J_1 = <any>"J1",
}

export enum MethodOfAttachmStatPlatesCode {
    A_1 = <any>"A1",
    A_2 = <any>"A2",
    A_3 = <any>"A3",
    A_4 = <any>"A4",
}

export enum LocationOfTheVinCode {
    A_0 = <any>"A0",
    A_1 = <any>"A1",
    A_2 = <any>"A2",
    A_3 = <any>"A3",
    A_4 = <any>"A4",
    A_5 = <any>"A5",
    A_6 = <any>"A6",
    A_7 = <any>"A7",
    A_8 = <any>"A8",
    A_9 = <any>"A9",
    B_0 = <any>"B0",
    B_1 = <any>"B1",
    B_2 = <any>"B2",
    B_3 = <any>"B3",
    B_4 = <any>"B4",
    B_5 = <any>"B5",
    B_6 = <any>"B6",
    B_7 = <any>"B7",
    B_8 = <any>"B8",
    B_9 = <any>"B9",
    C_0 = <any>"C0",
    C_1 = <any>"C1",
    C_2 = <any>"C2",
    C_3 = <any>"C3",
    C_4 = <any>"C4",
    C_5 = <any>"C5",
    C_6 = <any>"C6",
    C_7 = <any>"C7",
    C_8 = <any>"C8",
    C_9 = <any>"C9",
    D_0 = <any>"D0",
    D_1 = <any>"D1",
    D_2 = <any>"D2",
    D_3 = <any>"D3",
    D_4 = <any>"D4",
    D_5 = <any>"D5",
    D_6 = <any>"D6",
    D_7 = <any>"D7",
    D_8 = <any>"D8",
    D_9 = <any>"D9",
    E_1 = <any>"E1",
    E_2 = <any>"E2",
    E_3 = <any>"E3",
    E_4 = <any>"E4",
    E_5 = <any>"E5",
    E_6 = <any>"E6",
    E_7 = <any>"E7",
    E_8 = <any>"E8",
    E_9 = <any>"E9",
    F_1 = <any>"F1",
    F_2 = <any>"F2",
    F_3 = <any>"F3",
    F_4 = <any>"F4",
    F_5 = <any>"F5",
    F_6 = <any>"F6",
    F_7 = <any>"F7",
    F_8 = <any>"F8",
    F_9 = <any>"F9",
    G_1 = <any>"G1",
    G_2 = <any>"G2",
    G_3 = <any>"G3",
    G_4 = <any>"G4",
    G_5 = <any>"G5",
    G_6 = <any>"G6",
    G_7 = <any>"G7",
    G_8 = <any>"G8",
    G_9 = <any>"G9",
    H_0 = <any>"H0",
}

export enum CompletedAlteredCode {
    A = "A",
    B = "B",
    C = "C",
}

export enum RightLeftHandTrafficCode {
    R = "R",
    L = "L",
    B = "B",
}

export enum MetricImperialSpeedometerCode {
    M = "M",
    I = "I",
    B = "B",
}

export enum ReversibleDrivingPositionInd {
    Y = "Y",
    N = "N",
}

export enum SteeringCategoryCode {
    DIFF = "DIFF",
    ELEC = "ELEC",
    ELEC_HYD = "ELEC_HYD",
    HYDR = "HYDR",
    MAN = "MAN",
    POW = "POW",
    SERVO = "SERVO",
}

export enum BodyIndicator {
    Y = "Y",
    N = "N",
}

export enum PositionRollOverHoopCode {
    F = "F",
    R = "R",
    M = "M",
}

export enum TypeOfRollOverHoopCode {
    FD = "FD",
    FX = "FX",
}

export enum HydrLiftThreePointCouplingInd {
    Y = "Y",
    N = "N",
}

export enum TypeApprTranspDangerGoodsInd {
    Y = "Y",
    N = "N",
}

export enum ExceedingDimensionsIndicator {
    Y = "Y",
    N = "N",
}

export enum OdometerReadingUnitCode {
    K = "K",
    M = "M",
}

export enum OdometerUnitCode {
    K = "K",
    M = "M",
    B = "B",
}

export enum IntendedCountryOfRegistrCode {
    A = "A",
    AL = "AL",
    AM = "AM",
    AND = "AND",
    AX = "AX",
    AZ = "AZ",
    B = "B",
    BG = "BG",
    BIH = "BIH",
    BY = "BY",
    CH = "CH",
    CY = "CY",
    CZ = "CZ",
    D = "D",
    DK = "DK",
    E = "E",
    EST = "EST",
    F = "F",
    FIN = "FIN",
    FL = "FL",
    FO = "FO",
    GB = "GB",
    GBA = "GBA",
    GBG = "GBG",
    GBJ = "GBJ",
    GBM = "GBM",
    GBZ = "GBZ",
    GE = "GE",
    GR = "GR",
    H = "H",
    HR = "HR",
    I = "I",
    IRL = "IRL",
    IS = "IS",
    L = "L",
    LT = "LT",
    LV = "LV",
    M = "M",
    MC = "MC",
    MD = "MD",
    MK = "MK",
    MNE = "MNE",
    N = "N",
    NL = "NL",
    P = "P",
    PL = "PL",
    RO = "RO",
    RSM = "RSM",
    RUS = "RUS",
    S = "S",
    SK = "SK",
    SLO = "SLO",
    SRB = "SRB",
    TR = "TR",
    UA = "UA",
    V = "V",
}

export enum VehicleFittedWithEcoInnovInd {
    Y = "Y",
    N = "N",
}

export enum FuelTypeCode {
    M = <any>"M",
    B = <any>"B",
    F = <any>"F",
    T = <any>"T",
    D = <any>"D",
}

export enum AllowedEuropeanMarketIndicator {
    Y = "Y",
    N = "N",
}

export enum DualFuelType {
    TYPE_1_A = <any>"Type 1A",
    TYPE_1_B = <any>"Type 1B",
    TYPE_2_A = <any>"Type 2A",
    TYPE_2_B = <any>"Type 2B",
    TYPE_3_B = <any>"Type 3B",
}

export enum AdvancedBrakingSystemCode {
    ABS = "ABS",
    CBS = "CBS",
    NON = "NON",
    ACB = "ACB",
}

export enum ConvertingPerformanceIndic {
    Y = "Y",
    N = "N",
}

export enum TwentyFourGHzRadarInd {
    Y = "Y",
    N = "N",
}

export enum OffVehicleChargingVehInd {
    Y = "Y",
    N = "N",
}

export enum IndivisibleLoadInd {
    Y = "Y",
    N = "N",
}

export enum AdditionalCouplingPointsCode {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum SeatingPositionConfigCode {
    SAD = "SAD",
    SEA = "SEA",
}

export enum ThreePointLiftingMechanismCode {
    FRM = "FRM",
    REM = "REM",
    BFR = "BFR",
    INX = "INX",
}

export enum VehicleWithMachineryMountedInd {
    Y = "Y",
    N = "N",
}

export enum PureElectricVehIndicator {
    Y = "Y",
    N = "N",
}

export enum HybridVehIndicator {
    Y = "Y",
    N = "N",
}

export enum Iso7638ConnectorInd {
    Y = "Y",
    N = "N",
}

export enum ClassOfHybridVehicleCode {
    OVC_HEV = "OVC_HEV",
    NOVC_HEV = "NOVC_HEV",
    OVC_FCHV = "OVC_FCHV",
    NOVC_FCHV = "NOVC_FCHV",
}

export enum ZeroEmisVehInd {
    Y = "Y",
    N = "N",
}

export enum VocationalVehicleInd {
    Y = "Y",
    N = "N",
}

export enum BrakeAssistSystemIndicator {
    Y = "Y",
    N = "N",
}

export enum ProtectionPedestriansIndicator {
    Y = "Y",
    N = "N",
}

export enum DaytimeRunningLightsIndicator {
    Y = "Y",
    N = "N",
}

export enum ElectronicStabilityProgramInd {
    Y = "Y",
    N = "N",
}

export enum TyrePressureMonitoringSystInd {
    Y = "Y",
    N = "N",
}

export enum LaneDepartureWarningIndicator {
    Y = "Y",
    N = "N",
}

export enum AdvancEmergencyBrakingSystInd {
    Y = "Y",
    N = "N",
}

export enum BrakeRetarderIndicator {
    Y = "Y",
    N = "N",
}

export enum PressureChargerInd {
    Y = "Y",
    N = "N",
}

export enum InterCoolerIndicator {
    Y = "Y",
    N = "N",
}

export enum CatalyticConvertorInd {
    Y = "Y",
    N = "N",
}

export enum OxygenSensorInd {
    Y = "Y",
    N = "N",
}

export enum AirInjectionInd {
    Y = "Y",
    N = "N",
}

export enum ExhaustGasRecirculationInd {
    Y = "Y",
    N = "N",
}

export enum EvaporativeEmisControlSysInd {
    Y = "Y",
    N = "N",
}

export enum ParticulateTrapInd {
    Y = "Y",
    N = "N",
}

export enum OnBoardDiagnosInd {
    Y = "Y",
    N = "N",
}

export enum AntilockBrakeSysInd {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum FrontAirbagInd {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum SideAirbagInd {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum BeltPreloadDeviceInd {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum HeadAirbagInd {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum LowerAirbagInd {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum BeltForceLimiterInd {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum RearRegistrationPlateCode {
    H = "H",
    L = "L",
}

export enum HandicapIndicator {
    Y = "Y",
    N = "N",
}

export enum LimiterIndicator {
    Y = "Y",
    N = "N",
}

export enum AssistanceWithoutPedallingIndicator {
    Y = "Y",
    N = "N",
}

export enum ElectricVehicleConfigurCode {
    P = "P",
    H = "H",
    M = "M",
}

export enum CategoryOfHybridElectricVehInd {
    Y = "Y",
    N = "N",
}

export enum GearboxTypeCode {
    A = "A",
    C = "C",
    D = "D",
    F = "F",
    G = "G",
    H = "H",
    M = "M",
    O = "O",
    S = "S",
    W = "W",
}

export enum ProtectHazardSubstAerisolInd {
    Y = "Y",
    N = "N",
}

export enum ProtectHazardSubstDustFiltInd {
    Y = "Y",
    N = "N",
}

export enum ProtectHazardSubstVapFilterInd {
    Y = "Y",
    N = "N",
}

export enum CrawlerUndercarriageConfigCode {
    STF = "STF",
    STR = "STR",
    TFR = "TFR",
    CTT = "CTT",
}

export enum SteerByArtCentrAxisDirTrTrInd {
    Y = "Y",
    N = "N",
}

export enum SteerByArtCentrAxisTrTrainInd {
    Y = "Y",
    N = "N",
}

export enum SteerByPivotingOpposTracTraInd {
    Y = "Y",
    N = "N",
}

export enum SteeringBySpeedSideTracTraInd {
    Y = "Y",
    N = "N",
}

export enum TypeOfChassisArticulatedInd {
    Y = "Y",
    N = "N",
}

export enum TypeOfChassisBackboneInd {
    Y = "Y",
    N = "N",
}

export enum TypeOfChassisCentralTubeInd {
    Y = "Y",
    N = "N",
}

export enum TypeOfChassisChWithSideMembInd {
    Y = "Y",
    N = "N",
}

export enum TypeOfChassisLadderInd {
    Y = "Y",
    N = "N",
}

export enum TypeOfChassisOtherInd {
    Y = "Y",
    N = "N",
}

export enum CappedSpeedIndicator {
    Y = "Y",
    N = "N",
}

export enum BrakingLockingRightLeftInd {
    Y = "Y",
    N = "N",
}

export enum BrakingTransmissionTechnCode {
    PNE = "PNE",
    HYD = "HYD",
    BOT = "BOT",
}

export enum ElectronicBrakingSystemCode {
    Y = "Y",
    N = "N",
    O = "O",
}

export enum TypeOfBrakingVehicleCode {
    UNB = "UNB",
    INB = "INB",
    CNB = "CNB",
    SCB = "SCB",
    HDB = "HDB",
    PNB = "PNB",
}

export enum FiscPowOrNatCodeNrsCountryCode {
    A = "A",
    AL = "AL",
    AM = "AM",
    AND = "AND",
    AX = "AX",
    AZ = "AZ",
    B = "B",
    BG = "BG",
    BIH = "BIH",
    BY = "BY",
    CH = "CH",
    CY = "CY",
    CZ = "CZ",
    D = "D",
    DK = "DK",
    E = "E",
    EST = "EST",
    F = "F",
    FIN = "FIN",
    FL = "FL",
    FO = "FO",
    GB = "GB",
    GBA = "GBA",
    GBG = "GBG",
    GBJ = "GBJ",
    GBM = "GBM",
    GBZ = "GBZ",
    GE = "GE",
    GR = "GR",
    H = "H",
    HR = "HR",
    I = "I",
    IRL = "IRL",
    IS = "IS",
    L = "L",
    LT = "LT",
    LV = "LV",
    M = "M",
    MC = "MC",
    MD = "MD",
    MK = "MK",
    MNE = "MNE",
    N = "N",
    NL = "NL",
    P = "P",
    PL = "PL",
    RO = "RO",
    RSM = "RSM",
    RUS = "RUS",
    S = "S",
    SK = "SK",
    SLO = "SLO",
    SRB = "SRB",
    TR = "TR",
    UA = "UA",
    V = "V",
}

export enum RegulActApprovalCode {
    E = "E",
    F = "F",
    I = "I",
    N = "N",
    T = "T",
}

export enum CodeForBodywork {
    AA = "AA",
    AB = "AB",
    AC = "AC",
    AD = "AD",
    AE = "AE",
    AF = "AF",
    AG = "AG",
    BA = "BA",
    BB = "BB",
    BC = "BC",
    BD = "BD",
    BE = "BE",
    BX = "BX",
    CA = "CA",
    CB = "CB",
    CC = "CC",
    CD = "CD",
    CE = "CE",
    CF = "CF",
    CG = "CG",
    CH = "CH",
    CI = "CI",
    CJ = "CJ",
    CK = "CK",
    CL = "CL",
    CM = "CM",
    CO = "CO",
    CP = "CP",
    CQ = "CQ",
    CR = "CR",
    CS = "CS",
    CT = "CT",
    CU = "CU",
    CV = "CV",
    CW = "CW",
    CX = "CX",
    DA = "DA",
    DB = "DB",
    DC = "DC",
    DE = "DE",
    SA = "SA",
    SB = "SB",
    SC = "SC",
    SD = "SD",
    SE = "SE",
    SF = "SF",
    SG = "SG",
    SH = "SH",
    SJ = "SJ",
    SK = "SK",
}

export enum CodeForBodyworkSpecPurpVeh {
    SA = "SA",
    SB = "SB",
    SC = "SC",
    SD = "SD",
    SE = "SE",
    SF = "SF",
    SG = "SG",
    SH = "SH",
    SJ = "SJ",
    SK = "SK",
    SL = "SL",
    SM = "SM",
}

export enum TwinWheelsAxleInd {
    Y = "Y",
    N = "N",
}

export enum SteeredAxleInd {
    Y = "Y",
    N = "N",
}

export enum PoweredAxleInd {
    Y = "Y",
    N = "N",
}

export enum BrakedAxleInd {
    Y = "Y",
    N = "N",
}

export enum LiftAxleInd {
    Y = "Y",
    N = "N",
}

export enum LoadableAxleInd {
    Y = "Y",
    N = "N",
}

export enum RetractableOrLoadableAxleInd {
    Y = "Y",
    N = "N",
}

export enum DriveAxleWithAirSuspOrEquivInd {
    Y = "Y",
    N = "N",
}

export enum AxleWithAirSuspOrEquivInd {
    Y = "Y",
    N = "N",
}

export enum WorkingPrincipleCode {
    C_2 = <any>"C2",
    C_4 = <any>"C4",
    CA = <any>"CA",
    CI = <any>"CI",
    DF = <any>"DF",
    E_2 = <any>"E2",
    E_4 = <any>"E4",
    EC = <any>"EC",
    EE = <any>"EE",
    EF = <any>"EF",
    EP = <any>"EP",
    ER = <any>"ER",
    IC = <any>"IC",
    IE = <any>"IE",
    PI = <any>"PI",
    TB = <any>"TB",
}

export enum DirectInjectionIndicator {
    Y = "Y",
    N = "N",
}

export enum PureElectricIndicator {
    Y = "Y",
    N = "N",
}

export enum HybridIndicator {
    Y = "Y",
    N = "N",
}

export enum ArrangementOfCylindersCode {
    LI = "LI",
    O = "O",
    R = "R",
    S = "S",
    V = "V",
    W = "W",
}

export enum ElectricEngineIndicator {
    Y = "Y",
    N = "N",
}

export enum OffVehicleChargingIndicator {
    Y = "Y",
    N = "N",
}

export enum LpgFuellingSystemIndicator {
    Y = "Y",
    N = "N",
}

export enum CngFuellingSystemIndicator {
    Y = "Y",
    N = "N",
}

export enum MethodOfAffixEngineIdentNumber {
    A_1 = "A_1",
    A_2 = "A_2",
    A_3 = "A_3",
    A_4 = "A_4",
}

export enum LocationOfTheCouplingCode {
    F = "F",
    R = "R",
}

export enum MaxPermMassNatTraffCountryCode {
    AT = "AT",
    BE = "BE",
    BG = "BG",
    CY = "CY",
    CZ = "CZ",
    DE = "DE",
    DK = "DK",
    EE = "EE",
    ES = "ES",
    FI = "FI",
    FR = "FR",
    GB = "GB",
    GR = "GR",
    HR = "HR",
    HU = "HU",
    IE = "IE",
    IT = "IT",
    LT = "LT",
    LU = "LU",
    LV = "LV",
    MT = "MT",
    NL = "NL",
    PL = "PL",
    PT = "PT",
    RO = "RO",
    SE = "SE",
    SI = "SI",
    SK = "SK",
}

export enum DriverSeatTypeCategoryCode {
    A = "A",
    B = "B",
}

export enum ProtectiveStructureApplicCode {
    FOR = "FOR",
    NOF = "NOF",
    NFF = "NFF",
}

export enum ProtectiveStructureCode {
    ROPS = "ROPS",
    FOPS = "FOPS",
    OPS = "OPS",
}

export enum ProtectiveStructureEquipmCode {
    CMP = "CMP",
    OPT = "OPT",
    STD = "STD",
}

export enum ProtectiveStructureFoldablCode {
    FD = "FD",
    FX = "FX",
}

export enum ProtectiveStructureFoldingCode {
    WIT = "WIT",
    EXT = "EXT",
}

export enum ProtectStructLockingMechnCode {
    MAN = "MAN",
    AUT = "AUT",
}

export enum ProtectStructurePositionCode {
    F = "F",
    R = "R",
    FR = "FR",
}

export enum ProtectiveStructureRopsByCode {
    CAB = "CAB",
    FRA = "FRA",
    ROL = "ROL",
}

export enum ProtectiveStructFoldAssistCode {
    NA = "NA",
    PA = "PA",
    FA = "FA",
}

export enum PtoLocationCode {
    F = "F",
    R = "R",
    O = "O",
}

export enum PtoTypeCode {
    MPT = "MPT",
    SPT = "SPT",
}

export enum BrakedTrackSetInd {
    Y = "Y",
    N = "N",
}

export enum PoweredTrackSetInd {
    Y = "Y",
    N = "N",
}

export enum TypeOfMaterialOnTrackShoesCode {
    RTC = "RTC",
    STC = "STC",
    RPC = "RPC",
}

export enum ApprovalTerritoryCountryCode {
    A = "A",
    AL = "AL",
    AM = "AM",
    AND = "AND",
    AX = "AX",
    AZ = "AZ",
    B = "B",
    BG = "BG",
    BIH = "BIH",
    BY = "BY",
    CH = "CH",
    CY = "CY",
    CZ = "CZ",
    D = "D",
    DK = "DK",
    E = "E",
    EST = "EST",
    F = "F",
    FIN = "FIN",
    FL = "FL",
    FO = "FO",
    GB = "GB",
    GBA = "GBA",
    GBG = "GBG",
    GBJ = "GBJ",
    GBM = "GBM",
    GBZ = "GBZ",
    GE = "GE",
    GR = "GR",
    H = "H",
    HR = "HR",
    I = "I",
    IRL = "IRL",
    IS = "IS",
    L = "L",
    LT = "LT",
    LV = "LV",
    M = "M",
    MC = "MC",
    MD = "MD",
    MK = "MK",
    MNE = "MNE",
    N = "N",
    NL = "NL",
    P = "P",
    PL = "PL",
    RO = "RO",
    RSM = "RSM",
    RUS = "RUS",
    S = "S",
    SK = "SK",
    SLO = "SLO",
    SRB = "SRB",
    TR = "TR",
    UA = "UA",
    V = "V",
}

export enum FamilyIdentifierCode {
    IP = "IP",
    RL = "RL",
    RM = "RM",
    PR = "PR",
    ATCT = "ATCT",
    EV = "EV",
    PEMS = "PEMS",
}

export enum PendulumAxleIndicator {
    Y = "Y",
    N = "N",
}

export enum SelfTrackingAxleIndicator {
    Y = "Y",
    N = "N",
}

export enum DrivingDirectionCode {
    F = "F",
    R = "R",
}

export enum BrakingTypePowerAssistCode {
    WPT = "WPT",
    PAT = "PAT",
    FPT = "FPT",
}

export enum BrakingTypePrincipalCode {
    MEC = "MEC",
    HYD = "HYD",
    BOT = "BOT",
}

export enum AddressTypeCode {
    APP = "APP",
    BVM = "BVM",
    CUS = "CUS",
    IAA = "IAA",
    MRP = "MRP",
    PSM = "PSM",
    SSM = "SSM",
}

export enum ClassOfVehicleCode {
    I = "I",
    II = "II",
    III = "III",
    A = "A",
    B = "B",
}

export enum MaxPermLadenMassAxleCountrCode {
    AT = "AT",
    BE = "BE",
    BG = "BG",
    CY = "CY",
    CZ = "CZ",
    DE = "DE",
    DK = "DK",
    EE = "EE",
    ES = "ES",
    FI = "FI",
    FR = "FR",
    GB = "GB",
    GR = "GR",
    HR = "HR",
    HU = "HU",
    IE = "IE",
    IT = "IT",
    LT = "LT",
    LU = "LU",
    LV = "LV",
    MT = "MT",
    NL = "NL",
    PL = "PL",
    PT = "PT",
    RO = "RO",
    SE = "SE",
    SI = "SI",
    SK = "SK",
}

export enum TypeOfTyre {
    AL = "AL",
    AS = "AS",
    MC = "MC",
    MS = "MS",
    MSE = "MSE",
    MSS = "MSS",
    MST = "MST",
    PAX = "PAX",    // IVI 1.7
    RF = "RF",
    RFMS = "RFMS",
    S = "S",
    XL = "XL",
    XLMS = "XLMS",
}

export enum TyreTubeTypeCode {
    TL = "TL",
    TT = "TT",
}

export enum TyreMaxSpeedIndicator {
    Y = "Y",
    N = "N",
}

export enum TyreClassificationCode {
    F_1 = "F_1",
    F_2 = "F_2",
    F_3 = "F_3",
    G_1 = "G_1",
    G_2 = "G_2",
    G_3 = "G_3",
    I_1 = "I_1",
    I_2 = "I_2",
    I_3 = "I_3",
    I_4 = "I_4",
    I_5 = "I_5",
    I_6 = "I_6",
    LS_1 = "LS_1",
    LS_2 = "LS_2",
    LS_3 = "LS_3",
    LS_4 = "LS_4",
    R_1 = "R_1",
    R_2 = "R_2",
    R_3 = "R_3",
    R_4 = "R_4",
}

export enum RollingResistanceClassCode {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
}

export enum ProductionTyreRimVehInd {
    Y = "Y",
    N = "N",
}

export enum CO2DeterminationTyreInd {
    Y = "Y",
    N = "N",
}

export enum TyreCategoryCode {
    C_1 = "C_1",
    C_2 = "C_2",
    C_3 = "C_3",
}

export enum MaxPermLadenMassAxleGrCCode {
    AT = "AT",
    BE = "BE",
    BG = "BG",
    CY = "CY",
    CZ = "CZ",
    DE = "DE",
    DK = "DK",
    EE = "EE",
    ES = "ES",
    FI = "FI",
    FR = "FR",
    GB = "GB",
    GR = "GR",
    HR = "HR",
    HU = "HU",
    IE = "IE",
    IT = "IT",
    LT = "LT",
    LU = "LU",
    LV = "LV",
    MT = "MT",
    NL = "NL",
    PL = "PL",
    PT = "PT",
    RO = "RO",
    SE = "SE",
    SI = "SI",
    SK = "SK",
}

export enum TrailerBrakeConnectTechCode {
    ELEC = "ELEC",
    HYD = "HYD",
    PNEU = "PNEU",
    CPE = "CPE",
}

export enum BrakedTypeTrailCode {
    BRK = "BRK",
    CSB = "CSB",
    HDB = "HDB",
    INB = "INB",
    IPB = "IPB",
    PNB = "PNB",
    PNH = "PNH",
    UNB = "UNB",
    UND = "UND",
}

export enum DriverSeatTypeCategClassCode {
    I = "I",
    II = "II",
    III = "III",
}

export enum RecomTyrePressureLoadCode {
    E = "E",
    H = "H",
    F = "F",
}

export enum RecomTyrePressureUseCode {
    OFR = "OFR",
    ONR = "ONR",
}

export enum TypeTrailerCode {
    DA = "DA",
    DB = "DB",
    DC = "DC",
    DE = "DE",
    UD = "UD",
}

export enum FuelingArrangementCode {
    D1A = "D1A",
    D1B = "D1B",
    D2A = "D2A",
    D2B = "D2B",
    D3B = "D3B",
    G = "G",
    L = "L"
}

export enum MaxPermLadenMassAxleGrMStateNr {
    E_1 = "e1",
    E_2 = "e2",
    E_3 = "e3",
    E_4 = "e4",
    E_5 = "e5",
    E_6 = "e6",
    E_7 = "e7",
    E_8 = "e8",
    E_9 = "e9",
    E_11 = "e11",
    E_12 = "e12",
    E_13 = "e13",
    E_17 = "e17",
    E_18 = "e18",
    E_19 = "e19",
    E_20 = "e20",
    E_21 = "e21",
    E_23 = "e23",
    E_24 = "e24",
    E_25 = "e25",
    E_26 = "e26",
    E_27 = "e27",
    E_29 = "e29",
    E_32 = "e32",
    E_34 = "e34",
    E_36 = "e36",
    E_49 = "e49",
    E_50 = "e50",
}

export enum MaxPermLadenMassAxleMemStateNr {
    E_1 = "e1",
    E_2 = "e2",
    E_3 = "e3",
    E_4 = "e4",
    E_5 = "e5",
    E_6 = "e6",
    E_7 = "e7",
    E_8 = "e8",
    E_9 = "e9",
    E_11 = "e11",
    E_12 = "e12",
    E_13 = "e13",
    E_17 = "e17",
    E_18 = "e18",
    E_19 = "e19",
    E_20 = "e20",
    E_21 = "e21",
    E_23 = "e23",
    E_24 = "e24",
    E_25 = "e25",
    E_26 = "e26",
    E_27 = "e27",
    E_29 = "e29",
    E_32 = "e32",
    E_34 = "e34",
    E_36 = "e36",
    E_49 = "e49",
    E_50 = "e50",
}

export enum MaxPermMassNatTraffMemStateNr {
    E_1 = "e1",
    E_2 = "e2",
    E_3 = "e3",
    E_4 = "e4",
    E_5 = "e5",
    E_6 = "e6",
    E_7 = "e7",
    E_8 = "e8",
    E_9 = "e9",
    E_11 = "e11",
    E_12 = "e12",
    E_13 = "e13",
    E_17 = "e17",
    E_18 = "e18",
    E_19 = "e19",
    E_20 = "e20",
    E_21 = "e21",
    E_23 = "e23",
    E_24 = "e24",
    E_25 = "e25",
    E_26 = "e26",
    E_27 = "e27",
    E_29 = "e29",
    E_32 = "e32",
    E_34 = "e34",
    E_36 = "e36",
    E_49 = "e49",
    E_50 = "e50",
}

export enum SubFuelType {
    U = "U",
    RH = "RH",
    RL = "RL",
    LNG = "LNG",
}

export enum TestCycleNRSC {
    C1 = "C1",
    C2 = "C2",
    D2 = "D2",
    E2 = "E2",
    E3 = "E3",
    F = "F",
    G1 = "G1",
    G2 = "G2",
    G3 = "G3",
    H = "H"
}

export enum TestCycleNRTC {
    NRTC = "NRTC",
    LSI_NRTC = "LSI-NRTC",
}

export enum AerodynamicVehCode {
    F = "F",
    R = "R",
    B = "B",
    N = "N",
}

export enum ElongatedCabInd {
    Y = "Y",
    N = "N",
}

export enum AutomatedVehCode {
    NON = "NON",
    AUT = "AUT",
    FUL = "FUL",
}

export enum ElectricalControlLineInd {
    Y = "Y",
    N = "N",
}

export enum CategorySubCatEngine {
    NRE_V_1 = "NRE-v-1",
		NRE_V_2 = "NRE-v-2",
		NRE_V_3 = "NRE-v-3",
		NRE_V_4 = "NRE-v-4",
		NRE_V_5 = "NRE-v-5",
		NRE_V_6 = "NRE-v-6",
		NRE_V_7 = "NRE-v-7",
		NRE_C_1 = "NRE-c-1",
		NRE_C_2 = "NRE-c-2",
		NRE_C_3 = "NRE-c-3",
		NRE_C_4 = "NRE-c-4",
		NRE_C_5 = "NRE-c-5",
		NRE_C_6 = "NRE-c-6",
		NRG_V_1 = "NRG-v-1",
		NRG_C_1 = "NRG-c-1",
		NRS_VR_1A = "NRS-vr-1a",
		NRS_VR_1B = "NRS-vr-1b",
		NRS_VI_1A = "NRS-vi-1a",
		NRS_VI_1B = "NRS-vi-1b",
		NRS_V_2A = "NRS-v-2a",
		NRS_V_2B = "NRS-v-2b",
		NRS_V_3 = "NRS-v-3",
		ATS_V_1 = "ATS-v-1",
}
