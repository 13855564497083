<p-panel header="{{'UI871.list.commentaire' | translate}}">
  <!-- critères de filtre pour constructeur -->
  <div *ngIf="constructeur != null && constructeur.id != null" class="pt-4">
    <p-dropdown id="standard" [options]="getReferenceTable('typeCommentaire')"
                [(ngModel)]="typeCommentaire"
                optionValue="code"
                optionLabel="libelle" [showClear]="true"
                [autoDisplayFirst]="false"
    ></p-dropdown>
    <div class="pt-5">
      <span class="p-buttonset">
        <button pButton type="button" label="{{'UI000.bouton.filtrer' | translate}}" icon="pi pi-filter"
                (click)="filterCommentaireConstrByType()"></button>
        <button pButton type="button" label="{{'common.afficher' | translate}} {{'common.tout' | translate}}" icon="pi pi-filter"
                (click)="filterCommentaireConstrAll()"></button>
        <button pButton type="button" label="{{'UI000.bouton.annuler' | translate}}" icon="pi pi-times"
                (click)="effacerFiltreCommentaireConstr()"></button>
      </span>
    </div>
  </div>

  <!-- critères de filtre pour service technique -->
  <div *ngIf="servicetechnique != null && servicetechnique.id != null" class="pt-4">
    <p-dropdown id="standard" [options]="getReferenceTable('typeCommentaire')"
                [(ngModel)]="typeCommentaire"
                optionValue="code"
                optionLabel="libelle" [showClear]="true"
                [autoDisplayFirst]="false"
    ></p-dropdown>
    <div class="pt-5">
      <span class="p-buttonset">
        <button pButton type="button" label="{{'UI000.bouton.filtrer' | translate}}" icon="pi pi-filter"
                (click)="filterCommentaireServTechByType()"></button>
        <button pButton type="button" label="{{'common.afficher' | translate}} {{'common.tout' | translate}}" icon="pi pi-filter"
                (click)="filterCommentaireServTechAll()"></button>
        <button pButton type="button" label="{{'UI000.bouton.annuler' | translate}}" icon="pi pi-times"
                (click)="effacerFiltreCommentaireServTech()"></button>
      </span>
    </div>
  </div>

  <!-- liste des commentaires -->
  <div class="pb-2 pt-2">
    <p-table #dt [columns]="cols" [value]="commentaires" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           responsiveLayout="scroll"
           currentPageReportTemplate="{first} à {last} de {totalRecords} resultats" [rowsPerPageOptions]="[10,25,50]">
      <ng-template pTemplate="caption">
        <div class="p-d-flex" *ngIf="user.agentDO215 == true" >
          <button type="button" pButton pRipple label="Export" icon="pi pi-file" (click)="dt.exportCSV()" class="mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="dateCommentaire">{{'UI803.date' | translate}}
            <p-sortIcon field="dateCommentaire"></p-sortIcon>
          </th>
          <th  *ngIf="user.agentDO215 == true" pSortableColumn="auteur">{{'UI803.auteur' | translate}}
            <p-sortIcon field="auteur"></p-sortIcon>
          </th>
          <th pSortableColumn="type">{{'UI803.commentairetype' | translate}}
            <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th pSortableColumn="surCertificat">{{'UI803.commentaire.surcert' | translate}}
            <p-sortIcon field="surCertificat"></p-sortIcon>
          </th>
          <th pSortableColumn="prive">{{'UI803.docPrive' | translate}}
            <p-sortIcon field="prive"></p-sortIcon>
          </th>
          <th pSortableColumn="commentaire">{{'UI803.commentaire' | translate}}
            <p-sortIcon field="commentaire"></p-sortIcon>
          </th>
          <th pSortableColumn="numeroDossier">{{'UI802.numeroDossier' | translate}}
            <p-sortIcon field="numeroDossier"></p-sortIcon>
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-commentaires let-rowIndex="rowIndex">
        <tr [pSelectableRow]="commentaires">
          <td>{{commentaires.dateCommentaire | date:'short'}}</td>
          <td *ngIf="user.agentDO215 == true">{{commentaires.auteur}}</td>
          <td>{{getLabelForString("typeCommentaire", commentaires.type)}}</td>
          <td><p-checkbox [binary]="true" [(ngModel)]="commentaires.surCertificat" [readonly]="true"></p-checkbox></td>
          <td><p-checkbox [binary]="true" [(ngModel)]="commentaires.prive" [readonly]="true"></p-checkbox></td>
          <td>{{commentaires.commentaire}}</td>
          <td>{{commentaires.numeroDossier}}</td>
          <td>
            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-success p-button-text"
                                (click)="selectCommentaire(commentaires)" ></button>
            <p-confirmPopup></p-confirmPopup>
            <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-success p-button-text"
                    (click)="deleteCommentaire($event, commentaires, rowIndex)" *ngIf="user.superUser == true">
            </button>
          </td>
          <td>
            <button *ngIf="commentaires.demandeId != null && demande == null" pButton pRipple type="button"
                    icon="pi pi-external-link" class="p-button-rounded p-button-success p-button-text"
                    (click)="redirectToDemande(commentaires, rowIndex)" ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="pt-2">
    <button pButton type="button" label="{{'UI000.bouton.ajouter' | translate}}" icon="pi pi-file"
            (click)="addCommentaire()"
            *ngIf="user.agentDO215 == true || demande.statutCod == 10 || demande.statutCod == 15 || demande.statutCod == 35 || demande.statutCod == 50">
    </button>
  </div>

  <!-- détail d'un commentaire -->
  <p-card *ngIf="displayDetailCommentaire == true">
    <div class="p-fluid grid">
      <div class="field col">
        <div class="field">
          <label for="standard">{{'UI803.commentaire.standard' | translate}}</label>
        </div>
        <div class="field">
          <label for="comment">{{'UI803.commentaire'| translate}}</label>
        </div>
      </div>
      <div class="field col">
        <div class="field">
          <p-dropdown id="standard" [options]="getReferenceTable('commentaireStandard')"
            [(ngModel)]="commentaireBO.commentaireStandardCode"
            optionValue="code" optionLabel="libelle" [showClear]="true"
            [autoDisplayFirst]="false"
          ></p-dropdown>
        </div>
        <div class="field">
          <textarea pInputTextarea id="comment" [(ngModel)]="commentaireBO.commentaire" [rows]="5" [cols]="60"></textarea>
        </div>
      </div>

      <div class="field col">
        <div class="field-checkbox">
          <p-checkbox [(ngModel)]="commentaireBO.surCertificat" [binary]="true" inputId="binary1"></p-checkbox>
          <label for="binary1">{{'UI803.commentaire.surcertificat' | translate}}</label>
        </div>
      </div>
      <div class="field col">
        <div class="field-checkbox">
          <p-checkbox [(ngModel)]="commentaireBO.prive" [binary]="true" inputId="binary2"></p-checkbox>
          <label for="binary2">{{'UI803.commentaire.prive' | translate}}</label>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="pt-2">
      <button pButton type="button" label="{{'UI000.bouton.sauver' | translate}}" icon="pi pi-check" (click)="sauverCommentaire()"
              *ngIf="constructeur != null ||
                     ((notificationBO == null || notificationBO.type != 'SIGN') &&
                      (user.agentDO215 == true || demande.statutCod == 10 ||  demande.statutCod == 15 || demande.statutCod == 35))" >
      </button>
      <button pButton type="button" label="{{'UI000.bouton.sauver' | translate}}" icon="pi pi-file-o" (click)="sauverCommentaireRefus()"
              *ngIf="notificationBO != null && notificationBO.type == 'SIGN'">
      </button>
      <button pButton type="button" label="{{'UI000.bouton.annuler' | translate}}" icon="pi pi-times" (click)="cancelCommentaire()">
      </button>
    </div>

  </p-card>
</p-panel>

