export class AppConfig {
  env: string;
  version: string;
  backendUrl: string;
  checkAllUrl: string;
  sessionExtensionMin: number;
  disableSecurity?: boolean;
  acrValues: string;
  messagesPageSize: number;
  mockBackend?: boolean;
}
