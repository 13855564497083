<footer>
  <div class="container">
    <div class="row" style="width:100%">
      <div class="footer-logo col-lg-4">
        <img src="./assets/images/logo/footer_logo.png">
        <span>{{'common.footer-label' | translate}}</span>
      </div>

      <div class="links col-lg-8">
        <ul>
          <li><a href="https://www.wallonie.be/fr/">Site Wallonie.be</a></li>
          <li><a href="https://www.wallonie.be/fr/mentions-legales">{{'common.footer-link-1'|translate}}</a></li>
          <li><a href="https://www.wallonie.be/fr/vie-privee">{{'common.footer-link-2'|translate}}</a></li>
          <li><a href="https://www.wallonie.be/fr/mediateur">{{'common.footer-link-3'|translate}}</a></li>
          <li><a href="https://www.wallonie.be/fr/accessibilite">{{'common.footer-link-4'|translate}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
