import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiModel} from '../../model/api.model';
import {CheckAllService} from '../../services/check-all/check-all.service';
import {forkJoin, Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';


@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {

  downCountEnv = 0;
  downCountRest = -1;

  services: ApiModel[];

  statusServices: string;

  // envServices: ApiModel[];


  constructor(public checkAllService: CheckAllService,
              private translate: TranslateService) {
    translate.use('fr');
    translate.setDefaultLang('fr');
  }

  ngOnInit(): void {
    this.checkAllService.getServices().then(value => {
      let services: Array<Observable<HttpResponse<any>>> = [];
      value.forEach(
        (api: ApiModel) => {
          if (api.useForCheck) {
            services.push(this.checkAllService.getRestApiHealth());
          }
        }
      );
      forkJoin(services).subscribe(
        res => {
          let downCountRest = 0;
          res.forEach(resp => {
            if (resp.status !== 200) {
              downCountRest = downCountRest + 1
            }
          })
          this.downCountRest = downCountRest;
        },
        error => {
          this.downCountRest = 1;
        }
        )
    })
  }

  get status(): string {
    if ((this.downCountEnv === -1 || this.downCountRest === -1)) {
      // Not finished yet, don't display anything
      document.body.innerHTML = '';
      return '';
    }
    if ((this.downCountEnv + this.downCountRest) === 0) {
      document.body.innerHTML = 'OK';
      this.statusServices = 'OK';
      return 'OK';
    } else {
      document.body.innerHTML = 'KO';
      this.statusServices = 'KO';
      return 'KO';
    }
  }
}
