import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';

export abstract class AbstractReferenceService<T> {

  abstract resourceUrl: string;
  abstract cachedReference: T[];
  public requesterName: string;

  private _http: HttpClient;

  get http(): HttpClient {
    return this._http;
  }

  set http(ht: HttpClient) {
    this._http = ht;
  }

  protected constructor(http: HttpClient) {
    this.http = http;
  }

  load(): Observable<T[]> {
    if (this.cachedReference) {
      return of(this.cachedReference);
    } else {
      return this.http.get<T[]>(`${this.resourceUrl}`
        , {
          // headers: this.getHeaders(screenName, action),
          observe: 'response'
        })
        .pipe(switchMap(resp => {
          this.cachedReference = resp.body;
          return of(resp.body);
        }));
    }
  }

  // getHeaders(screenName: Ecran, action: UserAction, freeInfo?: string): HttpHeaders {
  //   if (screenName != null) {
  //     let headers = new HttpHeaders(
  //       {
  //         'screen-name': screenName,
  //         'user-action': action
  //       });
  //
  //     if (freeInfo != null) {
  //       headers = new HttpHeaders(
  //         {
  //           'screen-name': screenName,
  //           'user-action': action,
  //           'free-info': freeInfo,
  //         });
  //
  //     }
  //     console.log(headers.get('screen-name'));
  //     return headers;
  //   } else {
  //     return null;
  //   }
  // }

}
