import { Injectable } from '@angular/core';
import { TableReferenceBO } from "../models/table_reference.model";
import {HttpClient, HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {CommonService} from "./common.service";

import {VehicleCategoryCode, LocOfTheStatutoryPlatesCode, MethodOfAttachmStatPlatesCode, LocationOfTheVinCode, DualFuelType, WorkingPrincipleCode, FuelTypeCode, ElectricVehicleConfigurCode} from "../../modules/demande/model/initial_vehicle_information.model";

type CacheResult = any;

export class ApiCache {
  private static instance: ApiCache;
  private cache: { tablename: string; result: CacheResult }[] = [];

  //private searchDemandeRequest: any;

  constructor(public httpClient:HttpClient) {
    if (!!ApiCache.instance) {
      return ApiCache.instance;
    }
    ApiCache.instance = this;
    return this;
  }

  public set = (tablename: string, result: any): void => {
    console.log("into api-cache with tablename=["+tablename+"]");
    if (!this.recordExists(tablename)) {
      // Si la table correspond à un Enum, je complète le TableReferenceBO avec l'enumfield correspondant
      switch (tablename) {
        case "vehicleCategoryCode":
          console.log("into api-cache with tablename=VehicleCategoryCode");
          for (var i:number = 0; i<result.length; i++) {
            result[i].enumfield = VehicleCategoryCode[result[i].code as keyof typeof VehicleCategoryCode];
          }
          break;
        case "locOfTheStatutoryPlatesCode":
          console.log("into api-cache with tablename=locOfTheStatutoryPlatesCode");
          for (var i:number = 0; i<result.length; i++) {
            result[i].enumfield = LocOfTheStatutoryPlatesCode[result[i].code as keyof typeof LocOfTheStatutoryPlatesCode];
          }
          break;
        case "methodOfAttachmStatPlatesCode":
          for (var i:number = 0; i<result.length; i++) {
            result[i].enumfield = MethodOfAttachmStatPlatesCode[result[i].code as keyof typeof MethodOfAttachmStatPlatesCode];
          }
          break;
        case "locationOfTheVinCode":
          for (var i:number = 0; i<result.length; i++) {
            result[i].enumfield = LocationOfTheVinCode[result[i].code as keyof typeof LocationOfTheVinCode];
          }
          break;
        case "dualFuelType":
          for (var i:number = 0; i<result.length; i++) {
            result[i].enumfield = DualFuelType[result[i].code as keyof typeof DualFuelType];
          }
          break;
        case "workingPrincipleCode":
          for (var i:number = 0; i<result.length; i++) {
            result[i].enumfield = WorkingPrincipleCode[result[i].code as keyof typeof WorkingPrincipleCode];
          }
          break;
        case "fuelTypeCode":
          for (var i:number = 0; i < result.length; i++) {
            result[i].enumfield = FuelTypeCode[result[i].code as keyof typeof FuelTypeCode];
          }
          break;
        case "electricVehicleConfigurCode":
          for (var i:number = 0; i < result.length; i++) {
            result[i].enumfield = ElectricVehicleConfigurCode[result[i].code as keyof typeof ElectricVehicleConfigurCode];
          }
          break;
      }
      // sessionStorage.setItem(tablename,JSON.stringify(result));
      this.cache.push({tablename, result});
    }
  }

  public get = (tablename: string): CacheResult | null => {
    const cacheRecord = this.cache.find(x => {
      return x.tablename === tablename;
    });
    if (cacheRecord) {
      return cacheRecord.result;
    }

    // return JSON.parse(sessionStorage.getItem(tablename));

    return null;
  }
  public recordExists = (tablename: string): boolean => {
    return !!this.get(tablename);
  }

  public getLabelForNumber(tablename: string, code: number) {
    if (code == null)
      return "";
    if (!this.recordExists(tablename))
      return "reference table "+tablename+" not loaded";
    var tableReferenceBOs:TableReferenceBO[];
    tableReferenceBOs = this.get(tablename);
    var i: number;
    for (i = 0; i < tableReferenceBOs.length; i++) {
      if (tableReferenceBOs[i].code == (code+""))
        return tableReferenceBOs[i].libelle;
    }
    return "unknown label for code "+code;
  }
  public getLabelForString(tablename: string, code: string) {
    if (code == null)
      return "";
    if (!this.recordExists(tablename))
      return "reference table "+tablename+" not loaded";
    var tableReferenceBOs:TableReferenceBO[];
    tableReferenceBOs = this.get(tablename);
    var i: number;
    for (i = 0; i < tableReferenceBOs.length; i++) {
      if (tableReferenceBOs[i].code == code)
        return tableReferenceBOs[i].libelle;
    }
    return "unknown label for code "+code;
  }

  public getSearchDemandeRequest() {
    return JSON.parse(sessionStorage.getItem('searchDemande'))
  }
  public putSearchDemandeRequest(searchDemandeRequest: any) {
    sessionStorage.setItem('searchDemande',JSON.stringify(searchDemandeRequest));
  }
}
