import {Pipe, PipeTransform} from '@angular/core';

/**
 * return true if the vehCatHomologation starts with "L4"
*/
@Pipe({name: 'vehCatHomologationIsL4'})
export class VehCatHomologationIsL4Pipe implements PipeTransform {

  transform(value: string): boolean {
    if (value != null && value.length >= "L4".length && "L4" == value.substring(0, "L4".length))
      return true;
    return false;
  }
}
