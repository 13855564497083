<p-table [value]="servicesList">
  <ng-template pTemplate="header">
    <tr>
      <th>{{'check.composantApi' | translate}}</th>
<!--      <th>{{'check.instance' | translate}}</th>-->
      <th>{{'common.message' | translate}}</th>
      <th>{{'common.statut' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-service>
    <tr>
      <td>{{service.name}}</td>
<!--      <td>{{service.instance}}</td>-->
      <td>{{service.message}}</td>
      <td><span [class]="service.up ? 'pi pi-check' : 'pi pi-ban'"></span></td>
    </tr>
  </ng-template>
</p-table>
