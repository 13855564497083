import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {interval, Observable, Subject, throwError} from 'rxjs';
import {catchError, finalize, takeUntil} from 'rxjs/operators';
import {CommonService, LoaderService} from '..';
import {OAuthService} from 'angular-oauth2-oidc';

/**
 * Http interceptor
 */
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(public loaderService: LoaderService,
              private oAuthService: OAuthService) {
  }

  /**
   * Show loader for http request which takes more than 300ms
   * @param req http request
   * @param next http handler observable
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const showLoader = (req.url.indexOf(CommonService.appConfig.backendUrl) !== -1);
    const progressObservable = interval(300);
    const stopPolling = new Subject();
    let loaderActivated = false;
    if (showLoader) {
      progressObservable.pipe(takeUntil(stopPolling))
        .subscribe(() => {
          if (!loaderActivated) {
            this.loaderService.show();
            loaderActivated = true;
          }
        });
    }
    return next.handle(req).pipe(
      catchError(err => {
        this.loaderService.forceHide();
        return throwError(err);
      }),
      finalize(() => {
        // if ((this.oAuthService.getAccessTokenExpiration() - new Date().getTime()) / 1000
        //   < (CommonService.appConfig.sessionExtensionMin + 100)) {
        //   this.oAuthService.refreshToken().then();
        // }
        if (showLoader) {
          this.loaderService.hide();
        }
        stopPolling.next(true);
      }),
    );
  }

}
