import {AfterViewInit, Component, OnChanges, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {LoaderService} from '../../../core';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})


export class TemplateComponent implements OnInit, OnChanges, AfterViewInit {
  currentModule: string;
  navLinks: any[];
  loading = false;
  authenticated = this.oAuthService.hasValidAccessToken();
  inApp = this.checkInApp();

  constructor(private router: Router,
              private loaderService: LoaderService,
              private oAuthService: OAuthService
  ) {
    // this.loading = true;
  }

  ngAfterViewInit(): void {
    // This hack is used to clean the URL after coming back from the Auth because there's a bug in the JS library
    // See https://github.com/manfredsteyer/angular-oauth2-oidc/issues/959 & https://github.com/manfredsteyer/angular-oauth2-oidc/pull/963/files
    // console.log('href before: ' + location.origin + location.pathname + location.search + location.hash);
    const href = location.origin + location.pathname.replace(/&[^&\$]*/gi,'') + location.search + location.hash;
    history.replaceState(null, window.name, href);
    // console.log('href after: ' + location.origin + location.pathname + location.search + location.hash);
    // End of hack
    this.loading = false;
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
    //       console.log('Navigation start: ' + event.url);
          this.loading = true;
          this.loaderService.forceHide();
    //       if (sessionStorage.getItem('redirectAfterLogin')
    //         && sessionStorage.getItem('redirectUri')
    //         && (event.url === sessionStorage.getItem('redirectUri') || event.url === '/')) {
    //         if (sessionStorage.getItem('redirectAfterLogin') !== event.url) {
    //           console.log('Way 1: ' + event.url + ' -> ' + sessionStorage.getItem('redirectAfterLogin'));
    //           this.router.navigateByUrl(sessionStorage.getItem('redirectAfterLogin'));
    //         } else {
    //           sessionStorage.removeItem('redirectAfterLogin');
    //         }
    //       } else {
    //         console.log('Way 2: ' + event.url);
    //         if (!(event.url === sessionStorage.getItem('redirectUri') || event.url === '/' || event.url.indexOf('http') !== -1)) {
    //           console.log('Way 3');
    //           // console.log('Set item: ' + event.url);
    //           sessionStorage.setItem('redirectAfterLogin', event.url);
    //         }
    //       }
        } else if (
          event instanceof NavigationEnd
        ) {
    //       console.log('Way 4: ' + event.url);
    //       if (event.urlAfterRedirects === sessionStorage.getItem('redirectAfterLogin')) {
    //         console.log('Way 5');
    //         // console.log('Remove item: ' + event.url);
    //         sessionStorage.removeItem('redirectAfterLogin');
    //       }
          this.loading = false;
          this.inApp = this.checkInApp();
        } else if (
          event instanceof NavigationCancel
        ) {
          this.loading = false;
        }
      });
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

  checkInApp(): boolean {
    if (window.location.pathname === '/home'
      || window.location.pathname === '/choix-entreprise') {
      return false
    }
    return true;
  }

  getCurrentModule(event: any): string {
    this.loadModuleNavLinks(event);
    return event;
  }

  loadModuleNavLinks(event: any): any {
    this.currentModule = event;
    switch (this.currentModule) {
      case CONSULTATION_FISCAL:
        this.navLinks = consultatuionFiscal;
        break;
      case CONTENTIEUX:
        this.navLinks = contentieuxLinks;
        break;
      case ADVANCED:
        this.navLinks = avancerLinks;
        break;
      case ANOMALIE:
        this.navLinks = anomalieLinks;
        break;
    }
  }

}


const consultatuionFiscal: any[] = [];

const contentieuxLinks = [
  {
    label: 'Classement',
    link: '/contentieux/classement',
    index: 0
  }, {
    label: 'Traitement fiscal',
    link: '/contentieux/traitement-fiscal',
    index: 2
  }, {
    label: 'Document sortant',
    link: '/contentieux/document-sortant',
    index: 3
  }, {
    label: 'Exonération office',
    link: '/contentieux/calculExceptions-office',
    index: 4
  }, {
    label: 'rattachement',
    link: '/contentieux/rattachement',
    index: 5
  },
];
const avancerLinks = [
  {
    label: 'administration batch',
    link: '/advanced/admin-batch',
    index: 0
  }, {
    label: 'simulation unique',
    link: '/advanced/simulation-unique',
    index: 1
  }, {
    label: 'simulation multiple',
    link: '/advanced/simulation-multiple',
    index: 0
  }, {
    label: 'projection fiscale',
    link: '/advanced/projection-fiscal',
    index: 0
  },
];
const anomalieLinks = [{
  label: 'Anomalie batch',
  link: '/anomalie/batch',
  index: 0
}, {
  label: ' Anomalie etl',
  link: '/anomalie/etl',
  index: 1
}, {
  label: 'Anomalie load',
  link: '/anomalie/load',
  index: 2
}, {
  label: 'Anomalie sf-ext',
  link: '/anomalie/sf-ext',
  index: 3
}, {
  label: 'Anomalie sf-int',
  link: '/anomalie/sf-int',
  index: 3
}];


const CONSULTATION_FISCAL = '/consultation-fiscal';
const CONTENTIEUX = '/contentieux';
const ADVANCED = '/advanced';
const ANOMALIE = '/anomalie';
