import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {Subscription} from 'rxjs';
import {UserService} from "../../../core/services/user.service";
import {UserBO} from "../../../core/models/userbo.model";
import {TableReferenceService} from "../../../core/services/table-reference.service";
import {NewsService} from "../../../core/services/news.service";
import {EnumNotificationType} from "../../../core/enums/enum-notification-type";
import {DemandeService} from "../../../modules/demande/services/demande.service";
import {UserLocalStorageService} from "../../../core/services/UserLocalStorageService";

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {

  @Input() expandable: boolean;

  @Output() expand: EventEmitter<boolean> = new EventEmitter();
  expanded: boolean;
  hidden: boolean;
  currentScreen: string;
  routeSubscription: Subscription;

  user: UserBO = new UserBO();

  displayNewsAlert: boolean = false;
  displayNewsDialog: boolean = false;


  constructor(private router: Router, private userService: UserService,
              private oAuthService: OAuthService,
              private tableReferenceService: TableReferenceService,
              private newsService: NewsService, private demandeService: DemandeService,
              private userLocalStorageService: UserLocalStorageService) {
    this.currentScreen = '';
  }

  ngOnInit(): void {
    // Monitor location change
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentScreen = this.router.url;
      }
    });
    this.userService.getUserBO().subscribe(response =>{
      this.user=response.body;

      /**
       * PRE CHARGEMENT USERS
       */

      var roles: string[];
      roles = ["AH001","AH002","AH003","AH004","AH011"];
      if(this.user.agentDO215 && this.userLocalStorageService.getData('localListAgents') == ""){
        this.demandeService.getListAgents(roles).subscribe(response => {
          let selectOneMenuAgentDO215 = response.body;
          selectOneMenuAgentDO215.sort((a,b) => a.fullName.localeCompare(b.fullName));
          this.userLocalStorageService.saveData('localListAgents',JSON.stringify(selectOneMenuAgentDO215));
        });
      }

      if(this.user.agentDO215 && this.userLocalStorageService.getData('localAllAgentDO215') == ""){
        this.userService.getAllAgentDO215().subscribe(response => {
          let selectOneMenuAgentDO215=response.body;
          selectOneMenuAgentDO215.sort((a,b) => a.fullName.localeCompare(b.fullName));
          this.userLocalStorageService.saveData('localAllAgentDO215',JSON.stringify(selectOneMenuAgentDO215));
        });
      }

      var roles2: string[];
      roles2 = ["AH001","AH002","AH003","AH004","AH011", "AH013"];

      if(this.user.agentDO215 && this.userLocalStorageService.getData('localListAgentsH') == ""){
        this.demandeService.getListAgents(roles2).subscribe(response => {
          let filteredListeAgent=response.body;
          filteredListeAgent.sort((a,b) => a.fullName.localeCompare(b.fullName));
          this.userLocalStorageService.saveData('localListAgentsH',JSON.stringify(filteredListeAgent));
        });
      }



    });

    this.newsService.checkNews().subscribe((res: any) => {
      this.displayNewsAlert = res.body; // pour afficher en rouge si true et en normal si pas de news non lu
    });

    //TEST stockage des liste des user dans la session




  }

  ngOnDestroy(): void {
    // unsubscribe to avoid memory leaks
    if (this.routeSubscription && !this.routeSubscription.closed) {
      this.routeSubscription.unsubscribe();
    }
  }

  onExpand(): void {
    this.expanded = true;
    this.expand.emit(true);
  }

  onExpandExit(): void {
    this.expanded = false;
    this.expand.emit(false);
  }

  gotoAccueil(): void {
    //this.oAuthService.logOut();
    this.router.navigateByUrl('/notification/list');
  }

  gotoChoixEntreprise(): void {
    this.router.navigateByUrl('/choix-entreprise');
  }

  gotoNotifications(): void {
    this.router.navigateByUrl('/notification/list').then(() => {
      window.location.reload();
    });
  }

  gotoSearchConstructeur(): void{
    this.router.navigateByUrl('/constructeur/search');
  }

  gotoNewConstructeur(): void {
    this.router.navigate(['/constructeur/detail','newConstructeur']).then(() => {
      window.location.reload();
    });
  }

  gotoNewServiceTechnique(): void {
    this.router.navigate(['/constructeur/detail','newServiceTechnique']).then(() => {
      window.location.reload();
    });
  }

  gotoListClassenames(): void {
    this.router.navigateByUrl('/constructeur/listclassenames');
  }

  gotoListMaterialnames(): void {
    this.router.navigateByUrl('/constructeur/listmaterialnames');
  }

  gotoMesDossiers(): void {
    this.router.navigateByUrl('/demande/mes-dossiers');
  }

  gotoRechercherDemande(): void {
    this.router.navigateByUrl('/demande/rechercher-demande');
  }

  gotoNouvelleDemande(): void {
    this.router.navigateByUrl('/demande/nouvelle-demande');
  }


  gotoListesValeurs(): void {
    this.router.navigateByUrl('/administration/listesvaleurs').then(() => {
      window.location.reload();
    });;
  }

  gotoModelesdocuments(): void {
    this.router.navigateByUrl('/administration/modelesdocuments').then(() => {
      window.location.reload();
    });
  }

  gotoParametresSysteme(): void {
    this.router.navigateByUrl('/administration/parametressysteme').then(() => {
      window.location.reload();
    });
  }

  gotoGestionNumeroReception(): void {
    this.router.navigateByUrl('/administration/gestionnumeroreception').then(() => {
      window.location.reload();
    });
  }

  gotoDirective(): void {
    this.router.navigateByUrl('/administration/directive').then(() => {
      window.location.reload();
    });
  }

  gotoTraceFinancier(): void {
    this.router.navigateByUrl('/administration/tracefinancier').then(() => {
      window.location.reload();
    });
  }

  gotoTypePrestation(): void {
    this.router.navigateByUrl('/administration/typeprestation').then(() => {
      window.location.reload();
    });
  }

  gotoTypeFormulaire(): void {
    this.router.navigateByUrl('/administration/typeformulaire').then(() => {
      window.location.reload();
    });
  }

  gotoTypeNotification(): void {
    this.router.navigateByUrl('/administration/typenotification').then(() => {
      window.location.reload();
    });
  }

  gotoGestionPaiements(): void {
    this.router.navigateByUrl('/administration/gestionpaiements').then(() => {
      window.location.reload();
    });
  }


  gotoGestionCompteur(): void {
    this.router.navigateByUrl('/administration/gestioncompteur').then(() => {
      window.location.reload();
    });
  }

  gotoBlackListVIN(): void {
    this.router.navigateByUrl('/administration/blacklistvin').then(() => {
      window.location.reload();
    });
  }

  gotoEtaesStatus(): void {
    this.router.navigateByUrl('/administration/etaesstatus').then(() => {
      window.location.reload();
    });
  }

  gotoUtilisateurs(): void {
    this.router.navigateByUrl('/administration/utilisateurs').then(() => {
      window.location.reload();
    });
  }

  gotoNews(): void {
    this.router.navigateByUrl('/administration/gestionnews').then(() => {
      window.location.reload();
    });
  }

  gotoAnnonces(): void {
    this.router.navigateByUrl('/administration/gestionannonces').then(() => {
      window.location.reload();
    });
  }

  gotoGestionWarningStatut(): void {
    this.router.navigateByUrl('/administration/gestionwarningstatut').then(() => {
      window.location.reload();
    });
  }

  gotoNumeroReceptionNouveau(): void {
    this.router.navigateByUrl('/numerotation/nouveau').then(() => {
      window.location.reload();
    });;
  }

  gotoNumeroReceptionAttribue(): void {
    this.router.navigateByUrl('/numerotation/attribue').then(() => {
      window.location.reload();
    });;
  }

  gotoDocuments(): void {
    this.router.navigateByUrl('/documents/list').then(() => {
      window.location.reload();
    });;
  }

  gotoNumeroReceptionEnregistrerAncien(): void {
    this.router.navigateByUrl('/numerotation/enregistrerancien').then(() => {
      // window.location.reload();
    });;
  }

  showEtatAvacement(): boolean {
    if (this.currentScreen.indexOf('/lot/view') !== -1) {
      return true;
    }
    return false;
  }

  gotoTableaudebord(): void {
    this.router.navigateByUrl('/tableaudebord/tableaudebord').then(() => {
      window.location.reload();
    });
  }

  gotoRapports(): void {
    this.router.navigateByUrl('/tableaudebord/rapports').then(() => {
      // window.location.reload();
    });
  }

  gotoMonprofil(): void {
    this.router.navigateByUrl('/monprofil').then(() => {
      // window.location.reload();
    });
  }

  showNews(): void{
    this.displayNewsDialog = true;
  }




}
