import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private isLoading = new BehaviorSubject<boolean>(false);
  public loading$ = this.isLoading.asObservable();
  public loaderCounts = 0;

  show(): void {
    this.isLoading.next(true);
    this.loaderCounts = this.loaderCounts + 1;
  }

  hide(): void {
    this.loaderCounts = this.loaderCounts - 1;
    if (this.loaderCounts <= 0) {
      this.isLoading.next(false);
      this.loaderCounts = 0;
    }
  }

  forceHide(): void {
    this.isLoading.next(false);
    this.loaderCounts = 0;
  }
}
