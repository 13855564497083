// import {Injectable, NgModule, Pipe, PipeTransform} from '@angular/core';
// import {TranslateLoader, TranslateModule, TranslatePipe, TranslateService} from '@ngx-translate/core';
// import {Observable, of} from 'rxjs';
// import {Subscription} from "rxjs";
//
// const translations: any = {};
//
// class FakeLoader implements TranslateLoader {
//   getTranslation(lang: string): Observable<any> {
//     return of(translations);
//   }
// }
//
// @Pipe({
//   name: 'translate'
// })
// export class TranslatePipeMock implements PipeTransform {
//   public name = 'translate';
//
//   onTranslationChange: Subscription;
//   onLangChange: Subscription;
//   onDefaultLangChange: Subscription;
//
//   public transform(query: string, ...args: any[]): any {
//     return query;
//   }
// }
//
// @Injectable()
// export class TranslateServiceStub {
//   public get<T>(key: T): Observable<T> {
//     return of(key);
//   }
//
//   public use(lang: string): void {}
//   public setDefaultLang(lang: string): void {
//
//   }
// }
//
// @NgModule({
//   declarations: [
//     TranslatePipeMock
//   ],
//   providers: [
//     { provide: TranslateService, useClass: TranslateServiceStub },
//     { provide: TranslatePipe, useClass: TranslatePipeMock },
//   ],
//   imports: [
//     TranslateModule.forRoot({
//       loader: { provide: TranslateLoader, useClass: FakeLoader },
//     })
//   ],
//   exports: [
//     TranslatePipeMock,
//     TranslateModule
//   ]
// })
// export class TranslateTestingModule {
//
// }


/* Much simpler */
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    TranslateModule.forRoot()
  ],
  exports: [
    TranslateModule
  ]
})
export class TranslateTestingModule {

}
